import { useContext } from 'react';
import { PropTypes } from 'prop-types';
import ConfirmationDialog from './ConfirmationDialog';
// Context
import { ScheduleContext, SCHEDULE_CONTEXT_ACTION_TYPE } from '../context/ScheduleContext';
import { useTranslation } from 'react-i18next';
// mui


const DiscardScheduleChangesDialog = (props) => {

  const { scheduleDispatch } = useContext(ScheduleContext);
  const { open, fullReset, performDiscard, cancelAction, desc } = props;
  const { t } = useTranslation();
  
  const onDiscardClick = () => {

    if (fullReset) {
      scheduleDispatch({ type: SCHEDULE_CONTEXT_ACTION_TYPE.CLEAR_TRIGGER_CTX });
    } else {
      scheduleDispatch({ hasChanges: false });
    }
    performDiscard && performDiscard();
  }

  const onCancelClick = () => cancelAction && cancelAction();
  


  return <ConfirmationDialog
      open={open}
      dialogOption={{
        // title: title ?? t("schedulePage.alert.leaveB4SubmitTitle"),
        desc: desc ?? t("schedulePage.alert.warnB4Leave"),
        maxWidth: 'sm',
        okColor: "error",
        okText: t("schedulePage.yesDiscardBtn"),
        cancelText: t('global.NO'),

        okAction: () => onDiscardClick(),
        closeAction: () => onCancelClick(),
      }}
    />  

};

DiscardScheduleChangesDialog.propTypes = {
  performDiscard: PropTypes.func.isRequired,
  cancelAction  : PropTypes.func.isRequired,

  title: PropTypes.string,
  desc: PropTypes.string,

  open: PropTypes.bool.isRequired,
  fullReset: PropTypes.bool.isRequired,
};

DiscardScheduleChangesDialog.defaultProps = {
  open: true,
  fullReset: false,
};

export default DiscardScheduleChangesDialog;