import { useContext, useEffect } from 'react';
import { RESET_PASSWORD_MODE, SITE_MAP_URL } from '../constant';
import { ScheduleContext, SCHEDULE_CONTEXT_ACTION_TYPE } from '../context/ScheduleContext';
import { performLogout } from '../services/user.service';
import { currentTokenStatus, forceRefreshToken, TOKEN_STATUS } from '../services/token.service';
import { Copyright, SiteIdentity } from '../site-template/SiteFrame';
import { LoginFormPanel } from './member/LoginFormPanel';
import { ResetPasswordPanel } from './member/ResetPwdPanel';
// Library
import { navigate, useLocation, useMatch } from '@reach/router';
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
// mui
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


const Login = (props) => {

  const { scheduleDispatch } = useContext(ScheduleContext);
  const location = useLocation();
  const searchParams = queryString.parse(location.search);
  const pwdMode = useMatch(SITE_MAP_URL.RESET_PASSWORD);
  const { t } = useTranslation();  

  const handleLoginSuccess = () => navigate(SITE_MAP_URL.PRELOAD);


  // Reset everything if token is invalid
  useEffect(() => {
    const cts = currentTokenStatus();

    const resetEverything = () => {
      // Reset Schedule Context
      scheduleDispatch({ type: SCHEDULE_CONTEXT_ACTION_TYPE.RESET_ALL });
      // Reset Local & Session storage
      performLogout();
    }

    if (cts === TOKEN_STATUS.REFRESH_TOKEN_EXPIRED) {
      resetEverything();
    } else if (cts === TOKEN_STATUS.ACCESS_TOKEN_EXPIRED) {
      forceRefreshToken()
        .then(
          r => handleLoginSuccess(),
          e => resetEverything()
        )
    }

  }, [scheduleDispatch]);


  return <>
    <Helmet>
      <title>{t("global.productName")}</title>
    </Helmet>
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(/images/wallpaper.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center right 35%',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box 
            sx={{ 
              width: 60, 
              height: 60, 
              borderRadius: '4px',
              mb: 1,
              background: 'url(/logo192.png) no-repeat center',
              backgroundSize: 'contain',
            }} 
          />

          {pwdMode && <>
            <Typography component="h1" variant="h5" sx={{ mb: 1 }}>{t('loginPage.resetPwdTitle')}</Typography>
            <ResetPasswordPanel usageMode={RESET_PASSWORD_MODE.RESET} params={{ "email": decodeURIComponent(searchParams.email), "token": searchParams.token }} />
          </>}

          {!pwdMode && <>
            <Typography component="h1" variant="h5" sx={{ mb: 1 }}>{t('loginPage.Login')}</Typography>
            <LoginFormPanel onLoginSuccess={handleLoginSuccess} />
          </>}

          <Copyright sx={{ mt: 5 }} color="text.secondary" align="center" />
          <SiteIdentity />
        </Box>
      </Grid>
    </Grid>
  </>
  
}
export default Login;