import { Fragment, useState, useEffect, useContext } from 'react';
import { ScheduleContext, SCHEDULE_CONTEXT_ACTION_TYPE } from '../context/ScheduleContext';
import APPLICATION_CONSTANT, { SITE_MAP_URL } from '../constant';
import { Header, Footer } from '../site-template/SiteFrame';
import { getRAWUserProfile, getUserAccount, setRAWUserProfile } from '../services/user.service';
import { fetchCoreApplicationData, setAccountID, setClientBusinessName } from '../services/application.service';
import ConfirmationDialog from '../components/ConfirmationDialog';
import LoadingSkeleton from '../components/LoadingSkeleton';
import { SomethingWrong } from '../components/SomethingWrong';
// mui
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
// Library
import { navigate } from '@reach/router';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { /*getStoredValue,*/ getCookiesInObject, logging, setStoreValue } from '../common/utils';
import { Helmet } from 'react-helmet';
import { isLoggedin } from '../services/token.service';


const Preload = (props) => {
  
  // Context
  const { scheduleDispatch } = useContext(ScheduleContext);

  const { t } = useTranslation();

  // Flow
  const [startFetchProcess, setStartFetchProcess] = useState(true);

  // Error Catched
  const [errorHappened, setErrorHappened] = useState(null);

  // Step 1
  const [decidedAccountID, setDecidedAccountID] = useState(null);
  const [applicationSelection, setApplicationSelection] = useState({});
  const [confirmApplicationName, setConfirmApplicationName] = useState(null);

  
  // handling 1. multiple User available 'product'; 2. single available; 3. No available product
  if (startFetchProcess && decidedAccountID === null) {

    const uac = async () => {
      // console.log("preload async uac()")
      getUserAccount()
        .then(
          acc => {
            // console.log("preload async uac().then()")
            const rawApp = _.castArray(acc);

            // Data Format
            const app = _.filter(rawApp, { 'accessAllowed': true/* , "type": "F1 Racing Team" */ })  // TODO: don't rely on Text "F1 Racing Team"
            // console.log("Preload acc", app);

            if (app.length === 1) {
              
              // Fetch Application data
              const Id = app[0].id;
              setDecidedAccountID( Id );
              setAccountID( Id );
              scheduleDispatch({
                type: SCHEDULE_CONTEXT_ACTION_TYPE.UPDATE_ACC_ID,
                payload: Id,
              });
              setClientBusinessName( app[0].businessName );
              performFetchApplication( Id );

            } else if (app.length > 1) {
              const groupedApp = _.groupBy(app, 'type');
              // console.log("Grouped", groupedApp);

              // Than prompt user to pick one and continue 
              setApplicationSelection(groupedApp);
              setStartFetchProcess(false);

            } else {
              setStartFetchProcess(false);
              setErrorHappened(["UM-1043"]);
            }
          },
          e => setErrorHappened([e])
        );
    }
    if (isLoggedin) {
      // console.info("==========\nDocument Cookies:", document.cookie, "==========");
      uac();
    }
    
  }


  useEffect(() => {

    if (errorHappened !== null) {
      setStartFetchProcess(false);
    }

    // return () => {
    //   console.log("Preload useEffect Dismiss 'setStartFetchProcess(false)'.")
    // }
  }, [errorHappened])


  // Interaction
  const handleAppClick = async (appData) => {
    setDecidedAccountID(appData.id);
    setAccountID(appData.id);
    scheduleDispatch({
      type: SCHEDULE_CONTEXT_ACTION_TYPE.UPDATE_ACC_ID,
      payload: appData.id,
    });
    setClientBusinessName(appData.businessName);
    // setConfirmApplicationName(appData.businessName);
    await performFetchApplication(appData.id);
  }

  const closeConfirmation = () => {
    setConfirmApplicationName(null);

    try {
      
      // Backup
      const coreStorage = {
        userID: getCookiesInObject('ui'), // getUserID(),
        userProfile: getRAWUserProfile(),
      }

      // Reset
      window.localStorage.clear();
      window.sessionStorage.clear();

      // Restore
      setStoreValue(APPLICATION_CONSTANT.USER_ID,        coreStorage.userID);
      setRAWUserProfile( JSON.stringify( coreStorage.userProfile ) );

      // Done

    } catch (e) {}


    scheduleDispatch({
      type: SCHEDULE_CONTEXT_ACTION_TYPE.RESET_ALL
    });

  }
  
  const performFetchApplication = async (accountID) => {
    setStartFetchProcess(true);
    setConfirmApplicationName(null);

    logging(`get app data: ${decidedAccountID} or ${accountID}`);

    fetchCoreApplicationData(accountID ?? decidedAccountID)
      .then(
        r => {
          process.env.NODE_ENV === "development" && console.log("Fetch Core data: ", r);
          scheduleDispatch({
            type: SCHEDULE_CONTEXT_ACTION_TYPE.UDPATE_APP_ID,
            payload: r.appID,
          })

          navigate(SITE_MAP_URL.EVENT);
        },
        e => {
          console.error(e);
          navigate(SITE_MAP_URL.LOGIN);  // TODO: go back with Error alert
        }
      )

  }

  // Reset before use it
  useEffect(() => { 
    closeConfirmation();

    // console.info("==========\nDocument Cookies:", document.cookie, "==========");

    // return () => {
    //   console.log("Preload useEffect closeConfirmation()")
    // }
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps


  return <>
    <Helmet>
      <title>{t("sitemap.preload")} | {t("global.productNameShort")}</title>
    </Helmet>
    <Header preparing={true} />
    <Container maxWidth="sm" sx={{ mt: 3, mb: 5, minHeight: "calc(100vh - 180px)" }}>

      {(_.size(applicationSelection) > 0) && <>

      <Typography sx={{ mt: 4, mb: 2 }} variant="h6">
        {t("preloadPage.pageHeader")}
      </Typography>
      
      <Box sx={{ boxShadow: 3, borderRadius: 2, mb: "50px" }}> 
        <List>
          {Object.keys(applicationSelection).sort().map((g,i) => <Fragment key={`app-${i}`}>
              
              <ListSubheader>{g}</ListSubheader>

              {applicationSelection[g]
                .sort((lhs, rhs) => (lhs.tradingName < rhs.tradingName) ? 1 : -1)
                .map((a,j) => <Fragment key={a.id}>
                {j > 0 && <Divider variant="middle" />}
                <ListItemButton onClick={async () => await handleAppClick(a)} sx={{ justifyContent: 'space-between !important', flexDirection: 'row-reverse' }}>
                  <ListItemIcon sx={{ minWidth: 'auto' }}>
                    <Box component="img" src={`/images/${a.businessName.split(' ')[0]}/logo.svg`} sx={{ height: '32px' }} />
                  </ListItemIcon>
                  <ListItemText primary={a.tradingName} sx={{ pl: 2 }} />
                </ListItemButton>
              </Fragment>
              )}

            </Fragment> /* Application */
          )}  {/* Group */}          
        </List>
      </Box>

      </>}  {/* App Selection */}

      <LoadingSkeleton enable={startFetchProcess} center />

      {errorHappened !== null && 
        <SomethingWrong
          title={t("commonError.UnknownErrorTitle")}
          subtitle={t("commonError.UnknowErrorDesc")}
          showLogout
        />
      }

      <ConfirmationDialog 
        open={(confirmApplicationName !== null)}
        dialogOption={{
          title: t("global.confirmation"),
          desc: `${t("alert.confirmMan")} ${confirmApplicationName}?`,
          okText: t("global.OK"),
          okAction: performFetchApplication,
          closeAction: closeConfirmation,
          maxWidth: 'sm',
        }}
      />
    </Container>
    <Footer />
  </> 

}
export default Preload;