import PropTypes from 'prop-types';
import DialogComponent, { DialogOptionType } from './DialogComponent';
import CloneEventSelectionForm from '../pages/event/components/CloneEventSelectionForm';
import { useTranslation } from 'react-i18next';


const CloneFulfilmentDialog = (props) => {

  const { open, dialogOption, cloneMode, destinationEventID, shouldReloadData } = props;

  const { t } = useTranslation();

  const closeFn = () => {
    dialogOption.closeAction && dialogOption.closeAction()
  }

  return <DialogComponent
    open={open}
    dialogOption={{
      ...dialogOption,
      title: t("cloneFulfilment.dialogTitle"),
      maxWidth: "sm",
      // Action
      closeAction: closeFn,
      hideCloseBtn: true,
      disableActionBar: true,
    }}
  >
    
    <CloneEventSelectionForm 
      displayDirection="column" 
      cloneMode={cloneMode}
      destinationEventID={destinationEventID} 
      exitFormFn={closeFn}
      shouldReloadData={shouldReloadData}
      sx={{ mt: 2 }}
    />

  </DialogComponent>
}

CloneFulfilmentDialog.propTypes = {
  open: PropTypes.bool,
  
  // Business Logic
  cloneMode            : PropTypes.string,  // "eventClone", "resourceClone"
  sourceEventID        : PropTypes.string,
  sourceResourceID     : PropTypes.string,  // Optional
  destinationEventID   : PropTypes.string,
  destinationResourceID: PropTypes.string,  // Optional

  dialogOption: DialogOptionType,

  // Delegate
  shouldReloadData: PropTypes.func,
}

export default CloneFulfilmentDialog;