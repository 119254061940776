import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const FixedRange_Summary = (list, maxItems) => {

  let result = new Array(maxItems).fill(0);
  // result[0] = undefined; // ignore first item

  list.forEach(m => {
    m.slots.forEach(o => {
      for (let j=o.start-1; j<=o.end-1; j++) {
        result[j] += 1;
      }
    })
  });

  return result;
}

export const FixedRange_EmptySlot      = (list, maxItems) => FixedRange_Summary(list, maxItems).map(v => v === 0);

export const FixedRange_EmptySlotCount = (list, maxItems) => FixedRange_EmptySlot(list, maxItems).filter(i => i).length;

export const FixedRange_hasEmptySlot   = (list, maxItems) => FixedRange_EmptySlot(list, maxItems).some(i => i);

export const FixedRange_OverlapSlot    = (list, maxItems) => FixedRange_Summary(list, maxItems).map(v => v > 1);

export const FixedRange_hasOverlapSlot = (list, maxItems) => FixedRange_OverlapSlot(list, maxItems).some(i => i);

export const FixedRange_allValueValid = (list, condition) => {

  const minItem = condition.instances.minimum, 
        maxItem = condition.instances.maximum;
        
  return list.every(m => 
    m.slots.every(o => 
      _.inRange(o.start, minItem, maxItem + 1) 
      && _.inRange(o.end, minItem, maxItem + 1) 
    ) 
  );
}

/**
 * ==============================================
 * Common Reuseable
 * ==============================================
 */ 

/**
 * Breakdown Multimedia Slots into single slots per record
 * @param {Array<Trigger>} triggers - ScheduleContext.screenTriggersCtx...multimedia.slots[n -> 1]
 * @param {Boolean} assignUUID - Assign UUID per media for React Reuse
 * @returns Flattened Array<Trigger>
 * @see groupingFulfilment(triggers)
 */
// Flatten Triggers -> multimedia -> slots
export const flattenFulfilment = (triggers, assignUUID = false) => {

  const flattenMM = (target) => {

    let mmList = _.cloneDeep( target.multimedia ?? [] );
    if (mmList === null || mmList.length === 0) return [];

    let newList = [];
    mmList.forEach(l => {
      const { slots, ...p } = l;
      slots.forEach(s => {
        const nObj = {...p, slots: [ s ] };

        assignUUID && _.assign(nObj, { "uuid": uuidv4() });

        newList.push(nObj);
      })
    });

    newList = _.sortBy(newList, s => s.slots[0].start);
    // console.log("flattenFulfilment", newList);

    return newList;
  }

  return triggers.map(t => {
    t.multimedia = flattenMM(t);
    return t;
  });

}

// Grouping Triggers -> multimedia -> slots
/**
 * Undo Flatten Multimedia slots, necessary for submit fulfilment
 * @param {Array<Trigger>} triggers - ScheduleContext.screenTriggersCtx...multimedia.slots[1 -> n]
 * @returns Unflatten Array<Trigger>
 * @see flattenFulfilment(triggers)
 */
export const groupingFulfilment = (triggers) => {

  const groupingMM = (target) => {

    let mmList = _.cloneDeep( target ?? []);
    if (mmList === null || mmList.length === 0) return [];

    // Prepare: Sorting
    mmList = _.sortBy(mmList, ["id"]);

    let newList = [], lastID = "";
    mmList.forEach(l => {

      ("uuid" in l) && delete l.uuid;
    
      if (l.id === lastID) {
        // Same MM
        newList[newList.length-1].slots = [ ...newList[newList.length-1].slots, ...l.slots ];
        
      } else {
        // New MM
        newList.push(l);

        lastID = l.id;
      }

    });

    // console.log("groupingFulfilment", newList);

    return newList;
  }

  return triggers.map(t => {
    t.multimedia = groupingMM(t.multimedia);
    return t;
  })

}