import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

// enum for Skeleton ------------------
export const SKELETON_THEME = Object.freeze({
  DEFAULT   : 0,
  IMAGE_CARD: 1,
});

// Common Prop Types for Skeleton Theme(s)
const SKELETON_PROPTYPES = {
  repeatSkeleton: PropTypes.number,
  skeletonWidth : PropTypes.number,
  skeletonHeight: PropTypes.number,
}

// Master list of Skeleton Objects, for clean code on define and render
const skeletonEle = {}


// ------------------------------------
// Default Skeleton with PropTypes & default value
const DefaultSkeleton = ({ skeletonWidth, skeletonHeight }) => (
  <Stack spacing={1}>
    <Skeleton variant="rectangular" animation="wave" width={skeletonWidth} height={skeletonHeight} />
    <Skeleton animation="wave" sx={{ maxWidth: '360px' }} />
    <Skeleton animation="wave" sx={{ maxWidth: '440px' }} />
  </Stack>
);
DefaultSkeleton.propTypes = SKELETON_PROPTYPES;
DefaultSkeleton.defaultProps = {
  skeletonWidth : 440,
  skeletonHeight: 120,
}
skeletonEle[SKELETON_THEME.DEFAULT] = <DefaultSkeleton />;


// ------------------------------------
// Image Library Skeleton with PropTypes & default value
const ImageLibrarySkeleton = ({ skeletonWidth, repeatSkeleton }) => {

  return <Stack direction="row" spacing={1.5}>
    {[...Array(repeatSkeleton)].map((o,i) => <Stack spacing={0.5} elevation={1} key={`skeleton_${i}`}>
        <Skeleton variant='rectangular' animation="wave" width={skeletonWidth} height={skeletonWidth * 0.6} />
        <Skeleton animation="wave" width={skeletonWidth * 0.7} />
        <Stack direction="row" spacing={0.5}>
          <Skeleton animation="wave" width={40} />
          <Skeleton animation="wave" width={40} />
          <Skeleton animation="wave" width={40} />
        </Stack>
      </Stack>
    )}
  </Stack>
}
ImageLibrarySkeleton.propTypes = SKELETON_PROPTYPES;
ImageLibrarySkeleton.defaultProps = {
  skeletonWidth: 190,
  repeatSkeleton: 3
}
skeletonEle[SKELETON_THEME.IMAGE_CARD] = <ImageLibrarySkeleton />;


// ------------------------------------
// Actual Skeleton Rendering base on Props
const LSPropTypes = {
  enable: PropTypes.bool.isRequired,
  center: PropTypes.bool,
  sx    : PropTypes.object,

  skeletonTheme: PropTypes.oneOf([
    SKELETON_THEME.DEFAULT, 
    SKELETON_THEME.IMAGE_CARD
  ]),
  children: PropTypes.node,
};
const LoadingSkeleton = (props) => (!props.enable) ? props.children :
  (
    <Box 
      sx={{ 
        width: 'min-content', 
        ...(props.center ? { mx: 'auto' } : {}),
        ...props.sx
      }}
    >
      {skeletonEle[props.skeletonTheme] ?? <DefaultSkeleton />}
    </Box>
  );

// Prop Types
LoadingSkeleton.propTypes    = LSPropTypes;
LoadingSkeleton.defaultProps = {
  enable: true,
  center: false,

  skeletonTheme: SKELETON_THEME.DEFAULT,
}
export default LoadingSkeleton;