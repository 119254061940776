import { useState, useRef, useEffect, useContext, useMemo } from 'react';
import { SCREEN_TRIGGER_TYPE } from '../../../../constant';
// Services 
import { getHardwareScreensConfig, getScreenConditions, isPastEventByID } from '../../../../services/application.service';
import { fulfilmentDataMassage, getFulfilment } from '../../../../services/schedule.service';
// Context & Hook
import useRefResize from '../../../../hooks/useRefSize';
import { EventDelegatorContext } from '../../../../context/EventDelegatorContext';
import { ScheduleContext, SCHEDULE_CONTEXT_ACTION_TYPE } from '../../../../context/ScheduleContext';
// Components
import FixedRangeScenario from './ScenarioGroup/ScenarioFixRange';
import SlideShowScenario from './ScenarioGroup/ScenarioSlideshow';
import ScenarioContainerFrame from './ScenarioContainerFrame';
// MUI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// Library
import _ from 'lodash';
import { useTranslation } from 'react-i18next';


const ScenarioContainer = (props) => {
  
  // Schedule Context
  const { scheduleState: { eventIDCtx, resourceIDCtx, screenIDCtx, assetIDCtx, screenTriggersCtx }, scheduleDispatch } = useContext(ScheduleContext);

  // Invalid params monitoring
  const { eventDelegatorState: { issueListCtx} } = useContext(EventDelegatorContext);
  const goodToProcess                            = useMemo(() => (issueListCtx.length === 0), [issueListCtx]);

  // Read-Only mode
  const isReadOnly = useMemo(() => {
    return (eventIDCtx != null && eventIDCtx.length > 0) ? isPastEventByID(eventIDCtx) : true
  }, [eventIDCtx])

  // UI Flow related
  const [loadingFlag, setLoadingFlag]             = useState(true);
  const [errorMsg, setErrorMsg]                   = useState();
  // Working on Scenario Data
  const [schemaTypeDic, setSchemaTypeDic]         = useState({});
  const [schemaLimitation, setSchemaLimitation]   = useState({});

  // Browser Width
  const containerRef = useRef();
  const { refWidth, updateRefSize } = useRefResize(containerRef);
  
  // Page Data
  const workingScenario     = useMemo(() => screenTriggersCtx, [screenTriggersCtx]);
  const getSelectedScenario = useMemo(() => Object.keys(screenTriggersCtx) ?? [], [screenTriggersCtx]);

  // Rendering Switches
  const ScenarioComponent = {}
  ScenarioComponent[SCREEN_TRIGGER_TYPE.FIXED_RANGE] = FixedRangeScenario;
  ScenarioComponent[SCREEN_TRIGGER_TYPE.SLIDESHOW]   = SlideShowScenario;

  // Hooks
  const { t }    = useTranslation();

  // let triggerDic = extractScreenDictionary(props.screenID);
  // let screenTriggers = [];

  const performAddScenario = (list) => { 
    // const keyedList = _.keyBy(list, 'id');
    let newList = {...list, ...workingScenario};
    Object.keys(newList).forEach(lID => {
      Object.assign(newList[lID], getLimitationObject(lID));
    });
    // newList = _.keyBy( _.sortBy(newList, "seq"), "id");

    // console.log("Received new selecton", workingScenario, newList );

    // TODO: [31-Oct] Please reconfirm this logic,
    // scheduleDispatch({ type: SCHEDULE_CONTEXT_ACTION_TYPE.UPDATE_TRIGGER, payload: newList });
    scheduleDispatch({ "screenTriggersCtx": { ...screenTriggersCtx, ...newList } })
  }

  const performDelScenario = (dic) => {
    // console.log("performDelScenario", dic);
    let newList = _.reject(screenTriggersCtx, { id: dic.triggerId });
    // let newList = _.filter(screenTriggersCtx, { id: dic.triggerId });

    if (_.size(newList)) {
      newList = _.keyBy(newList, "id");
    }

    // console.log("after del", newList);
    
    // TODO: [31-Oct] Please reconfirm this logic,
    // Avoid changing 'hasChanges' when deleting empty scenario
    scheduleDispatch({ "screenTriggersCtx": newList, hasChanges: true });
  }


  // Helper
  const getLimitationObject = (id) => ({ condition: schemaLimitation[id] });

  // Data Parser
  useEffect(() => {

    if (assetIDCtx !== "") {

      setLoadingFlag(true);
      setErrorMsg(null);
  
      // Schema type
      let triggerSchemaDic = {};
      const triggerDictionary = getHardwareScreensConfig(assetIDCtx); 
      triggerDictionary[0]?.triggerGroup.forEach(t => {
        triggerSchemaDic[t.id] = { 
          "schemaType": t.schemaType,
          "element"   : t.element,
          "seq"       : t.seq,
        }
      });
      setSchemaTypeDic(triggerSchemaDic)
  
      // Scenario Limitation
      const screenConditions = getScreenConditions( eventIDCtx );
      setSchemaLimitation( screenConditions );
  
      // console.log('ScreenConditions', triggerSchemaDic, screenConditions);
      // console.log("ScenarioContainer useEffect [assetIDCtx, eventIDCtx]");

    }

  }, [assetIDCtx, eventIDCtx]);


  useEffect(() => {
    // console.log(props.screenID, assetIdToScreenId(props.screenID), props.driverID);

    const statusChange = (isWorking) => {
      scheduleDispatch({ 
        type: isWorking ? SCHEDULE_CONTEXT_ACTION_TYPE.SET_DOWNLOADING 
                        : SCHEDULE_CONTEXT_ACTION_TYPE.SET_DOWNLOADED 
      });
      setLoadingFlag(isWorking);
      isWorking && setErrorMsg(null); // Reset error only on begining of work
    }

    // Begin the work
    statusChange(true);

    const contentParser = () => {

      // console.log('ScreenConditions', triggerDictionary, screenConditions);

      getFulfilment(eventIDCtx, resourceIDCtx, screenIDCtx)
        .then(
          r => {
            let scheduleData = fulfilmentDataMassage(eventIDCtx, resourceIDCtx, screenIDCtx);
            // console.log('Fetch and Massage', scheduleData);

            // Avoid Screen Flashing
            setTimeout(() => {
              scheduleDispatch({ screenTriggersCtx: scheduleData, hasChanges: false });
              statusChange(false);
            }, 300);

          },
          e => {
            // API will return 404 for blank Fulfilment
            if (e.response?.status === 404) {

              // Do nothing but avoid screen flashing
              setTimeout(() => {
                scheduleDispatch({ type: SCHEDULE_CONTEXT_ACTION_TYPE.CLEAR_TRIGGER_CTX });
                statusChange(false);
              }, 300);

            } else {
              // console.error(e);
              setErrorMsg(t("commonError.downloadScheduleErrorMsg"));
              statusChange(false);
            }
          }
        );

      // console.log("ScenarioContainer useEffect [getFulfilment]");
    }
    (screenIDCtx !== "") && contentParser();

  }, [eventIDCtx, resourceIDCtx, screenIDCtx, t, scheduleDispatch]);
  

  // Update container Size for once
  useEffect(() => {
    (refWidth === 0) && updateRefSize()
  }, [refWidth, updateRefSize]);


  if (!goodToProcess) return <></>;


  return <ScenarioContainerFrame 
    loadingFlag={loadingFlag} 
    isReadOnly={isReadOnly}
    errorMsg={errorMsg} 
    currentSelection={getSelectedScenario}
    performAddScenario={performAddScenario}
  >

    {!loadingFlag && !errorMsg && <>
      <Box ref={containerRef}>
        <Stack direction="column" alignItems="start" spacing={2}>
      
          {_.sortBy(Object.values(workingScenario), "seq").map(w => {

            const schemaTD = schemaTypeDic[w.id];
            const dic = {...schemaLimitation[w.id], ...schemaTypeDic[w.id]};
            // console.log("combined Dic", dic);

            const SC = ScenarioComponent[schemaTD.schemaType] ?? <></>;

            return <SC 
              key={w.id}
              isReadOnly={isReadOnly}
              triggerDic={dic}
              containerWidth={refWidth}
              performDelScenario={performDelScenario}
            />

          })}

        </Stack>
      </Box>
    </>}

  </ScenarioContainerFrame>;
};
export default ScenarioContainer;