import { useEffect, useState, useContext, useMemo } from 'react';
import { getClientBusinessName, getResourceScreenList } from '../../../services/application.service';
import { ScreenIdToAssetId, getScreenDefinitionByField, assetIdToScreenId } from '../../../services/schedule.service';
import { getSuggestedImageOrientationRotationForScreen } from '../../../services/media.service';
// Context & hook
import { ScheduleContext, SCHEDULE_CONTEXT_ACTION_TYPE } from '../../../context/ScheduleContext';
import { EventDelegatorContext, EVENT_MANAGEMENT_DELEGATOR_NOTIFICATION_TYPE, EVENT_MANAGEMENT_ISSUE } from '../../../context/EventDelegatorContext';
// Library
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
// MUI
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Typography from '@mui/material/Typography';
// Style
import { blue, grey } from '@mui/material/colors';


const PreviewScreen = ({ 
  // Val
  showTips, 
  assetId, 
  // imgObj,
  w, h, r, transform, pos, 

  // Interaction
  handleScreenClicked,

}) => {

  const { 
    scheduleState: { isLoading, assetIDCtx, logoPreviewCtx }, 
  } = useContext(ScheduleContext);

  // Preview Image
  const [imgObj, setImgObj] = useState(null);
  // Preview image orientation, if needed
  // const suggestedRotation = _.head( getHardwareScreensConfig(assetId) )?.orientation ?? 0;  // Default orientation value for this preview screen
  const [imgRotateNewPos, setImgRotateNewPos] = useState({});

  // Tooltips
  const [hover, setHover] = useState(false);

  // Auto detect image orientation and rotate images
  useEffect(() => {

    // update latest preview image
    const imgObj = logoPreviewCtx[assetId] ?? null;
    setImgObj(imgObj);


    const getRotationCSS = async () => {

      // Image landscape/portrait handling
      let imgRD = 0;
      try {
        imgRD = await getSuggestedImageOrientationRotationForScreen(imgObj.id, assetId, imgObj.url)
      } catch (e) {}


      let imgRotateNewPos = {}
      switch (imgRD) {
        case 90:
          imgRotateNewPos = { 
            // Swap
            position: 'absolute',
            width: h, height: w,
            transform: `rotate(90deg) 
              translate(
                ${Math.abs(parseFloat(w) - parseFloat(h)) * -0.5}px, 
                ${Math.abs(parseFloat(w) - parseFloat(h)) * -0.5}px
              )`
          };
          break;
        
        case 270:
          imgRotateNewPos = {
            // Swap
            position: 'absolute',
            width: h, height: w,
            transform: `rotate(270deg) 
              translate(
                ${Math.abs(parseFloat(w) - parseFloat(h)) * 0.5}px, 
                ${Math.abs(parseFloat(w) - parseFloat(h)) * 0.5}px
              )`
          };
          break;

        case 0:
        default:
          imgRotateNewPos = { width: '100%', height: '100%' } // Original
          break;

      } // Switch

      setImgRotateNewPos( imgRotateNewPos );
    }

    if (assetIDCtx === assetId) {
      getRotationCSS()
    }

  }, [ logoPreviewCtx ])  // eslint-disable-line react-hooks/exhaustive-deps


  return <Tooltip 
    open={showTips || hover}
    title={getScreenDefinitionByField("assetId", assetId)?.title ?? ""} 
    placement="top" 
    key={"tips"+assetId} 
    TransitionComponent={Zoom}
    arrow
    componentsProps={{
      popper: {
        sx: {
          zIndex: 'modal'
        }
      },
      tooltip: {
        sx: {
          fontSize: "0.8rem",
          boxShadow: 3,
          backgroundColor: grey[200],
          color: "common.black",
          '& .MuiTooltip-arrow': {
            color: grey[200]
          }
        }
      }
    }}
  >
    <Box 
      sx={{
        transition: 'all 500 ease-in-out',
        position: 'absolute',
        background: `url(/images/RoadBand-Red.jpg) repeat top left`,
        ...(r !== undefined) ? { "transform": "rotate("+r+")"} : {},
        ...(transform !== undefined) ? { "transform": transform } : {},
        borderRadius: 0,
        boxShadow: (assetIDCtx === assetId) ? "0 0 0 6px #00FA9A" : 0,
        width:  `${w}`, minWidth:  `${w}`,
        height: `${h}`, minHeight: `${h}`,
        cursor: isLoading ? 'wait' : 'pointer',
        ...pos,

        ":after": {
          content: `''`,
          display: 'block',
          backgroundImage: `url(${imgObj?.url ?? ''})`,
          backgroundSize: '100% 100%',
          ...imgRotateNewPos
        }
      }} 
      onClick={() => !isLoading && handleScreenClicked(assetId)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    />
  </Tooltip>

}

const PreviewBox = (props) => {

  const { 
    scheduleState: { assetIDCtx }, 
    scheduleDispatch 
  } = useContext(ScheduleContext);
  const { eventDelegatorState: { issueListCtx} } = useContext(EventDelegatorContext);

  const [showTips, setShowTips] = useState(false);

  const validScreenID = useMemo(() => !_.includes(issueListCtx, EVENT_MANAGEMENT_ISSUE.INVALID_SCREEN_ID), [issueListCtx]);
  const zoomCSS       = useMemo(() => (validScreenID && assetIDCtx !== ""), [assetIDCtx, validScreenID])

  // Props
  const { previewSize, bgImage, assets } = props.config;

  // Handler
  const handleScreenClicked = (assetID) => props.onScreenChange && props.onScreenChange(assetID);
  

  // Force delay tips show
  useEffect(() => {
    if (validScreenID && assetIDCtx.length > 0) {
      setShowTips(false);
    } else {
      setTimeout(() => setShowTips(true), 800);
    }
  }, [validScreenID, assetIDCtx]);

  useEffect(() => {
    (assetIDCtx === null || assetIDCtx === "") 
      && scheduleDispatch({ type: SCHEDULE_CONTEXT_ACTION_TYPE.DEL_PREVIEW_LOGO })
  }, [assetIDCtx, scheduleDispatch]);
 

  return <Paper elevation={2} 
      sx={{ 
        position: 'relative',
        background: `url(${bgImage}) no-repeat center`, 
        backgroundSize: 'cover',
        width:  `${previewSize.w}px`, 
        height: `${previewSize.h}px`,
        flexShrink: 0,
        transition: 'all 500ms',
        transform: `scale(${(zoomCSS ? 0.7 : 1.0)})`,
        ...(zoomCSS 
            ? { 
              m: `${previewSize.h * -0.15}px ${previewSize.w * -0.15 + 8}px !important`,
            }
            : {} ),
      }}
    >
      
      {assets.map(k => {
        const { w, h, r, transform, top, left, right, bottom, assetId, ...p } = k;  // Sizing

        // console.log("debug", r, r !== null, transform, transform !== null);

        // Preview Screen Position
        const pos = {}; 
        if (top)    pos['top']    = `${top}`;
        if (left)   pos['left']   = `${left}`;
        if (right)  pos['right']  = `${right}`;
        if (bottom) pos['bottom'] = `${bottom}`;


        return <PreviewScreen
          key={assetId}
          assetId={assetId} 
          showTips={showTips}
          w={w}
          h={h}
          r={r}
          transform={transform}
          pos={pos}

          sx={{
            ...pos,
            ...p
          }}

          handleScreenClicked={handleScreenClicked}
        />
      })}
    </Paper>
}


const CurrentScreenMeta = ({ screenMeta }) => {

  const { t } = useTranslation();

  return (_.isEmpty(screenMeta))
    ? <></>
    : <Stack spacing={1.5}>
        <Stack>
          <Typography component="h6" sx={{ fontWeight: 'bold', fontSize: '0.85rem' }}>{t("global.screenName")}: </Typography>
          <Typography>{screenMeta.title}</Typography>
        </Stack>
        <Stack>
          <Typography component="h6" sx={{ fontWeight: 'bold', fontSize: '0.85rem' }}>{t("global.acceptableImgSize")}:</Typography>
          <Typography>
            {screenMeta.dimensions.total.width} x {screenMeta.dimensions.total.height}{t('global.unit.'+screenMeta.dimensions.total.unit.id) ?? ""} <br/>
            {screenMeta.dimensions.total.height} x {screenMeta.dimensions.total.width}{t('global.unit.'+screenMeta.dimensions.total.unit.id) ?? ""}
          </Typography>
          <Typography variant='body2' sx={{ color: grey[600], mt: 1 }}>
            {t("imageLib.portraitOrLandscapeOK")}
          </Typography>
        </Stack>
      </Stack>
}

const PickAScreenTips = () => {
  const [showTips, setShowTips] = useState(false);
  const { t } = useTranslation();

  // Since <Tooltip entryDelay doesn't work with animation of other components, 
  // Focus it to show with delay
  useEffect(() => {
    setTimeout(() => setShowTips(true), 800);
  })

  return <Tooltip
      open={showTips}
      title={t("schedulePage.guideTips.pickAScreenPls")}
      placement="bottom"
      arrow
      sx={{ width: "320px" }}
      componentsProps={{
        popper: {
          sx: {
            zIndex: 'modal'
          }
        },
        tooltip: {
          sx: {
            fontSize: "1.4rem",
            boxShadow: 3,
            color: 'common.white',
            backgroundColor: blue[700],
            '& .MuiTooltip-arrow': {
              color: blue[700],
            }
          }
        }
      }}
    >
      <Box />
    </Tooltip>
}

const ScreenPreview = ({ onPageMetaChange, ...props }) => {

  const { scheduleState: { resourceIDCtx, assetIDCtx }, scheduleDispatch } = useContext(ScheduleContext);
  const { eventDelegatorState: { issueListCtx}, eventDelegatorDispatch }   = useContext(EventDelegatorContext);

  const { t } = useTranslation();
  const [previewConfig, setPreviewConfig]   = useState([]);
  const validDriverID = useMemo(() => !_.includes(issueListCtx, EVENT_MANAGEMENT_ISSUE.INVALID_RESOURCE_ID), [issueListCtx]);
  // const validScreenID = useMemo(() => !_.includes(issueListCtx, EVENT_MANAGEMENT_ISSUE.INVALID_SCREEN_ID), [issueListCtx]);
  const screenMeta = useMemo(() => getScreenDefinitionByField("assetId", assetIDCtx), [assetIDCtx]);


  // User Interaction
  const handleScreenChange = (assetID) => {
    false && scheduleDispatch({ 
      type: SCHEDULE_CONTEXT_ACTION_TYPE.UPDATE_SCREEN_ID,
      payload: {
        assetIDCtx: assetID,
        screenIDCtx: assetIdToScreenId(assetID),
      }
    });

    // Redirect if needed
    props.onScreenChange && props.onScreenChange(assetID)
    // console.log("<ScreenPreview> handle screen change", screenID);
  }


  // Prepare (load) client screen preview config
  useEffect(() => {

    try {
      const cn = getClientBusinessName(true)[0];

      if (cn.length) {
        
        let masterConf = require(`../../../clientPackage/${cn}/previewConfig.json`);
        masterConf = _.reject(masterConf, { enable: false });
        masterConf.forEach(c => { c.assets = _.reject(c.assets, { enable: false }) });
        // setMasterPreviewConfig(conf);
  
        // Config loaded
        if (masterConf && validDriverID) {
          // Prepare screen config and keep it
          const driverScreens = getResourceScreenList(resourceIDCtx);
          const driverScreenByAssetID = driverScreens.map(s => ScreenIdToAssetId(s));
    
          const prevC = _.cloneDeep(masterConf).map(c => {
            c.assets = _.filter(c.assets, o => _.includes(driverScreenByAssetID, o.assetId)); // Filter non-exist screen
            return c;
          });
    
          setPreviewConfig(prevC);
  
        }
      }

    } catch (e) { console.error(e); }

  }, [resourceIDCtx, validDriverID, assetIDCtx, eventDelegatorDispatch]);


  // Verify Asset ID
  useEffect(() => {

    if (assetIDCtx !== "" && resourceIDCtx !== "") {
      
      const driverScreens = getResourceScreenList(resourceIDCtx);
      const driverScreenByAssetID = driverScreens.map(s => ScreenIdToAssetId(s));
  
      const validAID = driverScreenByAssetID.includes(assetIDCtx);
      eventDelegatorDispatch({ 
        payload: EVENT_MANAGEMENT_ISSUE.INVALID_SCREEN_ID,
        type: validAID 
                ? EVENT_MANAGEMENT_DELEGATOR_NOTIFICATION_TYPE.ERROR_GONE 
                : EVENT_MANAGEMENT_DELEGATOR_NOTIFICATION_TYPE.ERROR_FOUND,
      });

    }

  }, [assetIDCtx, eventDelegatorDispatch, resourceIDCtx]);

  // Page title cleanup before screen selected
  useEffect(() => 
    onPageMetaChange && onPageMetaChange({ screenDisplayName: screenMeta?.title ?? '' })
  , [screenMeta, onPageMetaChange]);


  // --------------------------------------------
  // - Render -----------------------------------
  if ( ! validDriverID ) return <></>;
  
  return (<>
      <Stack spacing={2} pt={2} flexWrap={1}>
          
        {previewConfig.length === 0 && <Alert severity='error'>
            <AlertTitle>PREVCONF-0404</AlertTitle>
            {t('commonError.criticalComponentMissing')}
          </Alert>
        }

        {previewConfig.length > 0 && 
          <Stack direction="row" spacing={1} flexGrow={0} flexShrink={1}>
            {previewConfig.map((conf,i) => 
              <PreviewBox key={`previewPanel-${i}`}
                config={conf} 
                onScreenChange={handleScreenChange} 
              />
            )}
            {assetIDCtx !== "" && <CurrentScreenMeta flexGrow={1} screenMeta={screenMeta} />}
          </Stack>
        }

      </Stack>

      {/* Guideline of next step - "Select a screen" */}
      {assetIDCtx === "" && <PickAScreenTips flexGrow={1} />}

      {props.children}
    </>
  )

};
export default ScreenPreview;