import { useState, useEffect, useContext } from 'react';
import { getEventFromCacheByID } from '../../../services/application.service';
import { formatEventDateRange } from '../../../common/utils';
import { ScheduleContext } from '../../../context/ScheduleContext';
// library
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import moment from 'moment-timezone';
// mui
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
// [F1WEB-202] 
import IconButton from "@mui/material/IconButton";
// Icon
// [F1WEB-202] 
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SITE_MAP_URL } from '../../../constant';
import CloneFulfilmentDialog from '../../../components/CloneFulfilmentDialog';





const SelectedEvent = ({eventID, onLeave, onPageMetaChange}) => {

  const { scheduleState: { assetIDCtx } } = useContext(ScheduleContext);

  // const [openSettingPanel, setOpenSettingPanel] = useState(false);
  const [openClonePanel, setOpenClonePanel] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});

  const { t } = useTranslation();

  // [F1WEB-202] 
  // const openSetting  = () => setOpenSettingPanel(true);
  // const closeSetting = () => setOpenSettingPanel(false);

  const eventDateTxt = () => (currentEvent?.startBaseDate === undefined) ? "" : formatEventDateRange(currentEvent.startBaseDate.utc, currentEvent.endBaseDate.utc);
  const eventCountry = () => currentEvent?.location?.country ?? "";
  const eventFlag    = () => currentEvent?.location?.flag ?? "";

  const today = moment.utc();
  moment.tz.guess(true);
  const isNotPast = today.isBefore(currentEvent?.endBaseDate?.utc);

  useEffect(() => {
    const e = getEventFromCacheByID(eventID);
    setCurrentEvent( e );

  }, [eventID]);
  
  // Update page title meta
  useEffect(() => {
    onPageMetaChange && onPageMetaChange({ eventDisplayName: currentEvent?.title ?? "" });
  }, [currentEvent, onPageMetaChange]);

  return (<>
    <Box sx={{ backgroundColor: '#dedede', padding: 2, borderBottomRightRadius: '25px' }}>
      <Grid container spacing={0} justifyContent="flex-start" alignItems="flex-start">

        <Grid item xs>
          <Button 
            variant="outlined"
            onClick={() => onLeave && onLeave(() => { navigate(SITE_MAP_URL.EVENT) }) }
            size="small"
            startIcon={<ArrowBackIosIcon />}
            sx={{ mb: 2 }}
          >
            {t("schedulePage.changeEventBtn")}
          </Button>
        </Grid>

        <Grid item xs={12} container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={1} >
          <Grid item>
            <Box 
              component="img"
              alt={eventCountry()} 
              src={eventFlag()}
              sx={{ boxShadow: 3, mt: "4px", objectFit: "contain", width: '60px' }} 
            />
          </Grid>

          <Grid item xs container direction="column" alignItems="stretch" justifyContent="flex-start">
            <Typography sx={{ fontWeight: 'bold' }}>{currentEvent?.title}</Typography>
            {currentEvent?.location 
              && <Typography variant='body2' sx={{ pt: "3px"  }}>{currentEvent?.location?.country}</Typography>}
            <Typography variant="body2">{eventDateTxt()}</Typography>
          </Grid>

          
          {isNotPast && <IconButton 
            onClick={() => setOpenClonePanel(true)}
            aria-label="Schedule Control"
          >
            <ContentCopyIcon fontSize="2rem" />
          </IconButton>}

        </Grid>
      </Grid>
    
      {openClonePanel &&
        <CloneFulfilmentDialog
          open={true}
          cloneMode="resourceClone"
          destinationEventID={eventID}
          shouldReloadData={() => (assetIDCtx) && window.location.reload()}
          dialogOption={{
            disableActionBar: true,
            closeAction: () => setOpenClonePanel(false)
          }}
        />
      }

    </Box>
  </>
  )
};
export default SelectedEvent;