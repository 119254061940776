import api, { API_URL } from './api';
import APPLICATION_CONSTANT, { SCHEDULE_STATUS } from '../constant';
import { getStoredValue, setStoreValue } from '../common/utils';
import { getApplicationID, getApplicationData, getScreenConditions, getTriggerGroupByAssetID, getAppType } from './application.service';
import _ from 'lodash';
import { flattenFulfilment, groupingFulfilment } from '../parser/ScenarioHelper';
import { masterScenarioStatus } from '../parser/ScenarioParser';
import moment from 'moment';
import * as Sentry from '@sentry/react';

// - Screens --------------------------
const getScreenList = () => {
  const appRawData = getApplicationData();

  if ("screens" in appRawData) {
    return appRawData.screens;
  }
  return [];
}

export const getScreenDefinitionByField = (key, val) => {

  if (key === null || key === "" || val === null || val === "") return {};

  const sList = getScreenList();
  const targetScreen = sList.filter(s => s[key] === val);

  return (targetScreen.length >= 1) ? targetScreen[0] : {};
}

export const assetIdToScreenId = (assetId) => {
  // Guard
  if (_.size(assetId) === 0) return "";
  
  const sd = getScreenDefinitionByField("assetId", assetId);
  return sd?.id;
}

export const ScreenIdToAssetId = (sID) => {
  const sd = getScreenDefinitionByField("id", sID);
  return sd?.assetId;
}


// Schedule -----------------
export const getFulfilment = async (
  /* appID = getApplicationID(), */ 
  //eventID = getCurrentEventID(),
  eventID,
  resourceID,
  screenID
) => {

  // Guard
  if (eventID === null) return Promise.reject("Missing Event ID");
  // if (appID === null) return Promise.reject("Missing Application ID");
  
  const urlStr = API_URL.GET_FULFILMENT + eventID + "/" + resourceID + "/" + screenID;

  return api.get(urlStr)
    .then(
      r => {
        // eslint-disable-next-line no-unused-vars
        const { succeeded, data, errors } = r.data;

        if (succeeded) {
          //console.log("Fulfilment", data.fulfilment);

          setStoreValue(APPLICATION_CONSTANT.FULFILMENT_RAW_DATA, JSON.stringify(data.fulfilment), false);

          return Promise.resolve(data.fulfilment);
        }

        // TODO: Errors info submit to monitor system, DON'T stop the following Cache handling
        // if (errors) {
          // return Promise.reject(errors.map(e => e.code));
        // }

        let storedObj = getStoredValue(APPLICATION_CONSTANT.FULFILMENT_RAW_DATA, {}, false, false);
        storedObj.cached = true;

        return Promise.resolve(storedObj);
      },
      e => { return Promise.reject(e) }
    );
}

export const getFulfilmentForScreen = (resourceID, screenID) => {

  if (resourceID === null || resourceID === "") return [];
  if (screenID === null || screenID === "") return [];


  const fullFulfil = getStoredValue(APPLICATION_CONSTANT.FULFILMENT_RAW_DATA, {}, false, false);


  // TODO: High Risk Code, improve it
  if ( _.size(fullFulfil?.resource) > 0) {
    const resourceData = _.castArray(fullFulfil.resource).filter(resource => resource.id === resourceID);
    if (resourceData.length > 0) {
      const s = _.castArray(resourceData[0].screen).filter(s => s.id === screenID);
      if (s.length > 0) {
        return s[0].triggers;
      }
    }
  }

  return [];

}


/**
 * Ask for a Flattened, conditions & trigger dictionary injected Fulfilment Data object
 * @param {string} eventID 
 * @param {string} resourceID 
 * @param {string} screenID 
 * @returns Fulfilment data with Trigger ID as key.
 */
export const fulfilmentDataMassage = (eventID, resourceID, screenID) => {

  let scheduleData = getFulfilmentForScreen(resourceID, screenID);
  const screenConditions = getScreenConditions( eventID );
  
  scheduleData.forEach(s => {

    let triggerDic = getTriggerGroupByAssetID( ScreenIdToAssetId(screenID), s.id);
    triggerDic = _.omit(triggerDic, ['id']);

    // Pack Trigger Conditions into fulfilment for validation logic
    Object.assign(s, 
      { 
        condition: screenConditions[s.id], 
        ...triggerDic,
      }
    );
  });
  // console.log("fulfilmentDataMassage", scheduleData);

  //Flatten all Multimedia Slots
  scheduleData = flattenFulfilment(scheduleData, true);
  scheduleData = _.keyBy(scheduleData, "id");

  return scheduleData;
}

export const downloadScheduleZip = (eventId, applicationId = getApplicationID()) => {

  // Guard
  if (eventId === null || eventId === "") return Promise.reject("Event ID can't be missing");
  if (applicationId === null || applicationId === "") return Promise.reject("Application ID can't be missing");

  return api.post(API_URL.DOWNLOAD_ZIP[getAppType()], { applicationId, eventId })
    .then(
      r => {
        const { succeeded, data, errors } = r.data;
        
        if (succeeded 
            && ("version" in data) 
            && ("url" in data.version)
            && data.version.url.length > 0) {
          process.env.NODE_ENV === "development" && console.log("ZIP", data.version.url);
          return Promise.resolve(data.version.url);
        }

        return Promise.reject(errors);
      },
      e => Promise.reject(e)
    )

}

export const getZIPQCStatus = (eventId, applicationId = getApplicationID()) => {

  // Guard
  if (eventId === null || eventId === "") return Promise.reject("Event ID can't be missing");
  if (applicationId === null || applicationId === "") return Promise.reject("Application ID can't be missing");

  return api.get(API_URL.GET_EVENT_VERSION + applicationId + "/" + eventId)
    .then(
      r => {
        const { succeeded, data } = r.data;

        if (succeeded && data?.versions?.length > 0) {

          // Sort it or get the latest record
          const latestVer = _.maxBy(data.versions, v => v.version);

          // console.log(latestVer);
          return Promise.resolve({
            status: latestVer.validationStatus.id,
            version: moment.utc(latestVer.version, "YYYYMMDDHHmmss"),
            zip: latestVer.url
          });         

        }

        // All other cases
        return Promise.resolve({ status: 0 });
        
      },
      e => Promise.reject(e)
    )

}

export const requestZIPQC = (eventId, applicationId = getApplicationID()) => {
  // Guard
  if (eventId === null || eventId === "") return Promise.reject("Event ID can't be missing");
  if (applicationId === null || applicationId === "") return Promise.reject("Application ID can't be missing");

  return api.post(API_URL.QC_ZIP[getAppType()], { applicationId, eventId })
    .then(
      r => {
        const { succeeded } = r.data;
        
        return Promise.resolve(succeeded ?? false);
      },
      e => {
        Sentry.captureException(e);
        return Promise.reject(e);
      }
    )
}

/**
 * Submit Schedule data to API, no matter it is INCOMPLETE/COMPLETE
 * @param {string} eventID 
 * @param {string} resourceID 
 * @param {string} screenID 
 * @param {Object<TriggerID:Trigger>} triggers - ScheduleContext.screenTriggersCtx
 */
export const prepareFulfilmentForSubmit = (eventID, resourceID, screenID, triggers) => {

  // Guard
  if (eventID && resourceID && screenID && triggers) { /* Continue */ } else 
    return new Error("Parameter(s) value is not expected value/type");

  const summarizeStatus = (sList) => {
    if (_.includes(sList, SCHEDULE_STATUS.INCOMPLETE)) {
      return SCHEDULE_STATUS.INCOMPLETE;
    }

    if (_.includes(sList, SCHEDULE_STATUS.COMPLETE)) {
      return SCHEDULE_STATUS.COMPLETE;
    }

    return SCHEDULE_STATUS.BLANK;
  }

  let tList = _.isArray(triggers) ? _.cloneDeep(triggers) : _.cloneDeep(Object.values(triggers));

  // Check each Status
  const tStatuss = masterScenarioStatus(tList);

  // Take out BLANK scenario
  tList = _.filter(tList, (t, i) => tStatuss[i] !== SCHEDULE_STATUS.BLANK);
  // Undo Flatten Slots (group slots)
  tList = groupingFulfilment(tList);

  // Data Clean up
  // - Remove 'condition'
  // - Remove 'element', 'seq', schemaType, title
  tList = tList.map(t => ({ 
    "id": t.id,
    "multimedia": t.multimedia,
    "element": t.element,
  }));

  process.env.NODE_ENV === "development" && console.log("Submit Fulfilment", tList);

  return [tList, summarizeStatus(tStatuss)];
}


export const submitFulfilment = async (eventId, resourceId, screenId, triggers) => {

  let postBody = {};

  // Guard
  try {
    const [tList, fStatus] = prepareFulfilmentForSubmit(eventId, resourceId, screenId, triggers);

    // API Submit
    postBody = {
      "eventId": eventId,
      "applicationId": getApplicationID(),
      "completed": (fStatus !== SCHEDULE_STATUS.INCOMPLETE),
      "resource": {
        "id": resourceId,
        "screen": {
          "id": screenId,
          "triggers": _.castArray(tList)
        }
      }
    };

    (process.env.NODE_ENV === "development") && console.log("PUT/POST Fulfilment", postBody);

    return api.put(API_URL.POST_FULFILMENT, postBody)
      .then(
        r => {

          const { succeeded, /* errors, */ data } = r.data;
  
          if (succeeded) {        
            return Promise.resolve(data);
          } else {
            
            // POST again, because we don't know if the fulfilment is empty or existing. Please have Lawrence do he PUT -> POST forward
            // Do the exactly same thing again
            return api.post(API_URL.POST_FULFILMENT, postBody)
              .then(
                r => {
                  const { succeeded, errors, data } = r.data;
  
                  if (succeeded) {
                    return Promise.resolve(data);
                  } else {
                    process.env.NODE_ENV === "development" && console.error("xxx")
                  }

                  Sentry.captureException("Save Fulfilment Fail", { "fulfilmentPayload": postBody });
                  return Promise.reject(errors.map(e => e.code)); 
                },
                e => Promise.reject(e)
              )
          }

        },
        e => {
          // TODO: duplicated code, but since API returning 400 on Empty-Put, no way but try again
          return api.post(API_URL.POST_FULFILMENT, postBody)
              .then(
                r => {
                  const { succeeded, errors, data } = r.data;
  
                  if (succeeded) {        
                    return Promise.resolve(data);
                  } else {
                    process.env.NODE_ENV === "development" && console.error("xxx")
                  }

                  Sentry.captureException("Save Fulfilment Fail", { "fulfilmentPayload": postBody });
                  return Promise.reject(errors.map(e => e.code)); 
                },
                e => Promise.reject(e)
              )
        }
      );

  } catch (e) {
    Sentry.captureException("Save Fulfilment Try/Catch", { "fulfilmentPayload": postBody });
    // console.error("submitFulfilment try catch", e);
    return Promise.reject(e);
  }

}


export const cloneFulfilment = async (sourceEventId, destinationEventId, sourceResourceId = null, destinationResourceId = null) => {

  let postBody = {
    "applicationId": getApplicationID(),
    sourceEventId,
    destinationEventId,
  }
  let apiUrl = API_URL.CLONE_EVENT

  try {

    if (sourceResourceId != null && destinationResourceId != null) {
      postBody["sourceResourceId"] = sourceResourceId
      postBody["destinationResourceId"] = destinationResourceId
      apiUrl = API_URL.CLONE_RESOURCE
    }

    (process.env.NODE_ENV === "development") && console.log("cloneFulfilment API", postBody);
  

    return api.post(apiUrl, postBody)
      .then(
        r => {
          const { succeeded, errors, data } = r.data;

          if (succeeded && data.status.id === 1) {
            return Promise.resolve(data);
          }

          Sentry.captureException("Clone fulfilment failed", {
            "api": apiUrl,
            "postBody": postBody,
            "errors": errors
          })
          return Promise.reject( errors )
        },
        e => {
          Sentry.captureException(e)
          return Promise.reject(e)
        }
      )

  } catch (error) {
    Sentry.captureException("Clone Event Try/Catch", { 
      "api": apiUrl,
      "postBody": postBody,
    })
    return Promise.reject(error)
  }

}