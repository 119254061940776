import { useState, useEffect } from 'react';
import ConfirmationDialog from '../../../../../components/ConfirmationDialog';
// Library
import { useTranslation } from 'react-i18next';
// mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// Icon & style
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { red, grey } from '@mui/material/colors'

export const EnumScenarioStatus = Object.freeze({
  Error: 1,
  Neutral: 2,
});

const ScenarioFrame = (props) => {

  const { triggerDic, isReadOnly } = props;
  const { t } = useTranslation();

  const [currentScenarioStatus, setCurrentScenarioStatus] = useState(EnumScenarioStatus.Neutral);  // 0: Error, 1: Normal
  const [statusMessage, setStatusMessage]                 = useState("");
  const [confirmDelDialog, setConfirmDialog]              = useState(false);

  const closeDialog = () => setConfirmDialog(false);
  const onDel = () => setConfirmDialog(true);

  const performDel = () => {
    props.performDelScenario && props.performDelScenario(triggerDic);
    closeDialog();
  }

  const updateScenarioStatus = (sStatus) => {
    switch (sStatus) {
      case EnumScenarioStatus.Error:
      case EnumScenarioStatus.Neutral:
        setCurrentScenarioStatus(sStatus);
        break;
      default:
        console.warn(`${t('commonError.unkownScenarioStatus')} ${sStatus}`);
        break;
    }
  }

  // Status message generation
  const generateSlideshowStatusMsg = (dic) => {

      let sMsg = "";

      if (props.numberOfImages === 0) {
        sMsg = t('scenario.pleaseAddImg');
      } else {

        if (dic.instances.maximum) {
          sMsg = `${props.numberOfImages ?? 0} / ${dic.instances.maximum} ${t('global.logos')}`;
        }
  
        if (props.totalNumberOfSecond) {
          //console.log("totalNumberOfSecond", props.totalNumberOfSecond);
          sMsg = `${sMsg}${sMsg.length?" | ":""}${t('scenario.repeatEvery')}: ${props.totalNumberOfSecond} ${t('global.seconds')}`;
        }

      }


      sMsg.length && setStatusMessage(sMsg);
    
  }

  const generateFixedRangeStatusMsg = (dic) => {

  }

  useEffect(() => {
    props.scenarioStatus && updateScenarioStatus(props.scenarioStatus);
  
    if (props.statusMessage) {
      setStatusMessage(props.statusMessage);
    } else {
      // Slideshow
      (triggerDic.schemaType === 2) && generateSlideshowStatusMsg(triggerDic);

      // Fixed Range
      (triggerDic.schemaType === 1) && generateFixedRangeStatusMsg(triggerDic);
    }
  }, 
  [props.scenarioStatus, props.statusMessage, props.totalNumberOfSecond, props.numberOfImages, triggerDic]);  // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Box sx={{
      // position: 'relative',
      width: 'calc(100% - 30px)',
      border: 1, borderRadius: 2, overflow: 'hidden',
    }}>
      {/* Header */}
      <Box sx={{
        // position: 'absolute', top: 0, left: 0, right: 0,
        backgroundColor: '#dedede',
        paddingLeft: 2, paddingRight: 1,
        py: 0.5,
      }}>
        <Stack direction="row" alignItems='center' justifyContent='flex-start' spacing={1}>
          <Typography component="h5" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
            {t(`scenario.${triggerDic.element}-title`)}
          </Typography>
          
          {(currentScenarioStatus === EnumScenarioStatus.Error) && 
            <WarningAmberRoundedIcon color="warning" />
          }

          <Typography variant='body2' 
            sx={{ color: (currentScenarioStatus === EnumScenarioStatus.Error) ? red[400] : grey[600] }}
          >
            {statusMessage}
          </Typography>

          {!isReadOnly && 
            <IconButton 
              color="default" 
              aria-label={`${t('global.del')} ${t(`scenario.${triggerDic.element}-title`)}`}
              size="small" 
              onClick={onDel}
              sx={{ backgroundColor: '#999' }}
            >
              <CloseIcon />
            </IconButton>
          }

          <ConfirmationDialog
            open={confirmDelDialog}
            dialogOption={{
              title: t(`global.confirmation`),
              desc: `${t("alert.confirmDel")} ${t(`scenario.${triggerDic.element}-title`)}?`,
              okText: t('global.del'),
              okColor: "error",
              okAction: performDel,
              closeAction: closeDialog,
              maxWidth: 'sm'
            }}
          />

        </Stack>
      </Box>

      {/* Body */}
      <Box sx={{ p: 2, maxHeight: (176.5 * 2.7)+'px', overflowY: "scroll", overflowX: 'hidden' }}>
        {props.children}
      </Box>
    </Box>
  )

}
export default ScenarioFrame;