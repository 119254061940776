import { grey } from "@mui/material/colors";

const coreTheme = {

  typography: {
    button: {
      textTransform: 'none'
    }
  },

  components: {
    
    MuiAppBar: {
      defaultProps: {
        color: 'transparent'
      },
      styleOverrides: {
        root: {
          borderBottomWidth: '4px',
          borderBottomStyle: 'solid',
        }
      }
    },  // MuiAppBar ------------------

    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff"
        }
      }
    },  // MuiAvatar ------------------

    MuiListItem: {
      styleOverrides: {
        root: {
          "&.isUnselectable, &.isUnselectable:hover": {
            backgroundColor: "initial"
          },
          "&.isPast, &.isPast:hover": {
            backgroundColor: grey[200]
          },
          "&.isUnselectable, &.isPast": {
            cursor: "not-allowed",
          },
          
        }
      }
    },  // MuiListItem ----------------

    MuiChip: {
      styleOverrides: {
        root: {
          "&.logoTag": {  // Custom spacing for Logo Card's tags
            marginLeft: "0 !important",
            "&:not(:last-of-type)": {
              marginRight: "4px !important",
            }
          }
        }
      }
    },  // Custom MuiChip on LogoCard -
    

  },  // components

};
export default coreTheme;