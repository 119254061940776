import SDTheme from "./Seamless/theme";
import alpineTheme from "./Alpine/theme";
import mclarenTheme from "./McLaren/theme";
import ferrariTheme from "./Ferrari/theme";
import astonTheme from "./Aston/theme";
import majesticksTheme from "./Majesticks/theme";
import RBTheme from "./RB/theme";
import SDSports from "./SDSports/theme";
import JuncosTheme from "./Juncos/theme";

const masterClientTheme = {
  "Seamless"  : SDTheme,
  "Alpine"    : alpineTheme,
  "McLaren"   : mclarenTheme,
  "Ferrari"   : ferrariTheme,
  "Aston"     : astonTheme,
  "RB"        : RBTheme,
  "Majesticks": majesticksTheme,
  "SDSports"  : SDSports,
  "Juncos"    : JuncosTheme
};
export default masterClientTheme;

export const DEFAULT_THEME = "Seamless";