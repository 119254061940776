import coreTheme from "../masterTheme";
import { createTheme } from "@mui/material/styles";
import deepmerge from "deepmerge";

const branding = {
  highlight   : "#cbd3fa", // "#E0E2E3", //"#f1f3f4",
  light       : "#1434cb70",
  main        : "#1434cb",
  dark        : "#102aa2",
  contrastText: "#fff",
};

const RBTheme = createTheme( deepmerge(coreTheme, {
  palette: { branding },
  
  components: {
  
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderBottomColor: branding.main,
        }
      }
    },  // MuiAppBar

    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: branding.main,
            color: branding.contrastText,
          },
          "&:hover, &.Mui-selected:hover": {
            backgroundColor: branding.light,
            // color: branding.contrastText,
          }
        }
      }
    },  // MuiListItemButton

    MuiListItem: {
      styleOverrides: {
        root: {
          "&:hover:not(.isPast):not(.isUnselectable)": {
            backgroundColor: branding.highlight,
          },
          "&.isSelected": {
            backgroundColor: branding.highlight,
          }
        }
      }
    },  // MuiListItem

  },  // components

}));
export default RBTheme;