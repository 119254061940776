import { useEffect, useState, useMemo, useContext } from 'react';
import { SITE_MAP_URL } from './constant.js';
import PromptLoginDialog from './components/PromptLoginDialog.js';
import { logging } from './common/utils.js';
// Services & Context
import { currentTokenStatus, forceRefreshToken, startAutoJWTRefresh, stopAutoJWTRefresh, TOKEN_STATUS } from './services/token.service.js';
import { ScheduleContext, ScheduleContextProvider } from './context/ScheduleContext.js';
import { getClientBusinessName } from './services/application.service.js';
// Components
import Login from './pages/Login.js';
import Logout from './pages/Logout.js';
import Preload from './pages/Preload';
import Event from './pages/event/Event.js';
import Download from './pages/Download.js';
import LogoLibraryPage from './pages/logoLibraryPage';
import NotFound from './pages/NotFound';
import MobileDeviceNotAvailableYet from './components/MobileDeviceNotAvailableYet';
import BrowserNotAvailableYet from './components/BrowserNotAvailableYet';
import masterClientTheme, { DEFAULT_THEME } from './clientPackage/masterClientTheme.js';
import UserGuideWrapper from './components/userGuide/UserGuide.js';
// Library
import { Router, useLocation, useMatch, navigate, Redirect } from "@reach/router";
import _ from 'lodash';
import { isMobile, isChrome, isChromium, isEdgeChromium } from 'react-device-detect';
// UI & Styles
import './styles/App.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Healthy from './pages/health/alive.js';
import Maintenance from './components/Maintenance.js';

/**
 * Application Delegator act as health checker behind the scence, duty include:
 * - Prompt for Login (if necessary) on suitable page(s)
 */
const ApplicationDelegator = ({ children }) => {

  const [promptLogin, setPromptLogin] = useState(false);

  // Pages to monitor
  const atPreloadPage  = useMatch(SITE_MAP_URL.PRELOAD);
  const atEventPage    = useMatch(SITE_MAP_URL.EVENT + SITE_MAP_URL.ANY);
  const atDownloadPage = useMatch(SITE_MAP_URL.DOWNLOAD_SCHEDULE);
  const urlLocation    = useLocation();

  const shouldCheckToken = () => _.compact([atPreloadPage, atEventPage, atDownloadPage]).length > 0;


  const rememberURLAndLogout = () => {
    // TODO: remember URL and redirect after login  

    setPromptLogin(false);
    navigate(SITE_MAP_URL.LOGOUT);
  }

  // When:
  // current on target page(s)
  //  && Refresh Token exist
  //  && Refresh Token expired
  // Prompt Login Dialog to obtain new set of user token
  // BUT, skip when browser tab is not in visibility state
  const promptToLoginIfNeeded = () => {

    // Guard - Ignore when out of focus
    if (document.visibilityState === 'hidden') return;

    false && process.env.NODE_ENV === "development"
      && console.log("promptToLoginIfNeeded [", 
        shouldCheckToken(), ": {",
        atPreloadPage !== null, " or ",
        atEventPage !== null, " or ",
        atDownloadPage !== null, "}", 
        "&&", currentTokenStatus(), "]"
      );


    if (shouldCheckToken) {

      const cts = currentTokenStatus();
      switch (cts) {
        case TOKEN_STATUS.NO_TOKEN:
          rememberURLAndLogout();
          break;
        
        case TOKEN_STATUS.TOKEN_VALID:
          setPromptLogin(false);  // continue & cleanup
          break;
      
        case TOKEN_STATUS.REFRESH_TOKEN_EXPIRED:
          setPromptLogin(true);
          break;

        case TOKEN_STATUS.ACCESS_TOKEN_EXPIRED:
          forceRefreshToken()
            .then(
              r => setPromptLogin(false),
              e => rememberURLAndLogout()
            )
          break;

        default:
          rememberURLAndLogout();
          break;
      }

    }

  }


  // --------------------------------------------
  // Auto Refresh token timer  
  useEffect(() => {
    // logging("*** App.useEffect() ***");
    startAutoJWTRefresh();

    return () => stopAutoJWTRefresh();
  }, []);


  // --------------------------------------------
  // Browser focuing monitoring
  useEffect(() => {
    logging("-------------------------\n- App useEffect without Dependency\nWin & Doc Add Event Listener\n-------------------------");

    document.addEventListener("visibilitychange", promptToLoginIfNeeded);
    window.addEventListener("focus", promptToLoginIfNeeded);

    return () => {
      logging("xxx Win & Doc Remove Event Listener xxx");
        
      window.removeEventListener("focus", promptToLoginIfNeeded);
      document.removeEventListener("visibilitychange", promptToLoginIfNeeded)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    promptToLoginIfNeeded();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlLocation.pathname]);


  // --------------------------------------------
  // - Render -----------------------------------
  // --------------------------------------------
  return <>
    {children}
    <PromptLoginDialog 
      open={promptLogin}
      onLoginSuccess={() => setPromptLogin(false)} 
      onCancelLogin={() => setPromptLogin(false)}
    />
  </>;
}


const MUIThemeDelegator = ({ children }) => {

  const { scheduleState: { accountIDCtx } } = useContext(ScheduleContext);
  const [shortBusinessName, setShortBusinessName] = useState("Seamless");

  // load the theme when business name changed
  // Also see: useEffect() below
  const cTheme = useMemo(() => {

    logging(`Loading theme '${shortBusinessName}'`);

    return masterClientTheme[shortBusinessName] ?? createTheme({});
    
  }, [shortBusinessName]);

  // Repeative update event user logout
  // Please consider to improve this logic
  useEffect(() => {
    let cName = getClientBusinessName(true).join("");
    cName = (cName === "") ? DEFAULT_THEME : cName;

    (shortBusinessName !== cName)
      && setShortBusinessName(cName);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountIDCtx]);

  
  return <ThemeProvider theme={cTheme}>
      {children}
    </ThemeProvider>;
}


const App = () => {

  if (isMobile && process.env.NODE_ENV !== "development") {
    return <MobileDeviceNotAvailableYet />;
  }
  
  if (!isChrome && !isChromium && !isEdgeChromium) {
    return <BrowserNotAvailableYet />;
  }
  
  return (
    <ScheduleContextProvider>
      <MUIThemeDelegator>
        <Router>
          {(false) // Maintenance mode switch
          ? <Maintenance path="*" />
          : <>
            <Healthy path={SITE_MAP_URL.AZURE_HEALTH_CHECK} />
            <Logout path={SITE_MAP_URL.LOGOUT} />
            <Login path={SITE_MAP_URL.LOGIN} />
            <Login path={SITE_MAP_URL.RESET_PASSWORD} />

            <ApplicationDelegator path="/">
              <UserGuideWrapper path={SITE_MAP_URL.USER_GUIDE} />

              <Preload         path={SITE_MAP_URL.PRELOAD} />
              <Event           path={SITE_MAP_URL.EVENT + SITE_MAP_URL.ANY} />
              <Download        path={SITE_MAP_URL.DOWNLOAD_SCHEDULE} />
              <LogoLibraryPage path={SITE_MAP_URL.LOGO_LIBRARY} />

              <Redirect noThrow from="*" to={SITE_MAP_URL.NOTFOUND} />
            </ApplicationDelegator>

            <NotFound path={SITE_MAP_URL.NOTFOUND} />
            <Redirect noThrow from="*" to={SITE_MAP_URL.NOTFOUND} />
          </>
          }

        </Router>
      </MUIThemeDelegator>
    </ScheduleContextProvider>
  );
}
export default App;