import APPLICATION_CONSTANT from '../constant.js';
import { getStoredValue, setStoreValue, removeStorage, getCookiesInObject, csDatetimeTickToJSDate } from '../common/utils.js';
import api, { API_URL } from './api.js';
import { isEmpty } from 'lodash';

export const TOKEN_STATUS = Object.freeze({
  NO_TOKEN             : "TS_0",
  TOKEN_VALID          : "TS_1",
  ACCESS_TOKEN_EXPIRED : "TS_2",
  REFRESH_TOKEN_EXPIRED: "TS_3",
});


// Shorthand - Status related
export const shouldRememberMe    = () => getStoredValue(APPLICATION_CONSTANT.REMEMBER_ME, true) === true;
export const hasAccessToken      = () => getCookiesInObject('ae') != null;
export const hasRefreshToken     = () => getCookiesInObject('re') != null;
export const isValidToken        = () => (currentTokenStatus() === TOKEN_STATUS.TOKEN_VALID); 

// Shorthand Fn
export const isLoggedin = () => {
  
  const cookie = getCookiesInObject();
  // console.log("isLoggedin cookie", cookie);
  if ("ae" in cookie && "re" in cookie) {
    return (!isEmpty(cookie.ae) && !isEmpty(cookie.re)) 
  }
}
export const isATExpired = () => {
  const ate = getCookiesInObject('ae')

  if (ate) {
    let ated = csDatetimeTickToJSDate(ate)
    return ated < Date.now()
  }

  return true;
}
export const isRTExpired = () => {
  const rte = getCookiesInObject('re')

  if (rte) {
    let rted = csDatetimeTickToJSDate(rte)
    return rted < Date.now()
  }

  return true
}


/**
 * 
 * @param {ACCESS_EXPIRY | REFRESH_EXPIRY} typeOfToken 
 * @returns Unless the expiry value can be retrieve and with active period, all other case will got TRUE
 */
export const isExpiredToken = (typeOfToken = APPLICATION_CONSTANT.ACCESS_EXPIRY) => {

  // Guard
  if (!isLoggedin()) {
    return true;
  }

  return (typeOfToken === APPLICATION_CONSTANT.ACCESS_EXPIRY) ? isATExpired() : isRTExpired()
}


/**
 * 
 * @param {boolean} rememberFlag 
 * @returns true means saved without error; false means save action with error.
 * Ref to 
 */
export const setRememberMe = (rememberFlag) => {

  try {
    setStoreValue(APPLICATION_CONSTANT.REMEMBER_ME, rememberFlag.toString());
    return true;
  } catch (e) {}

  return false;
}


/**
 * Obtain current user token status
 * @return {string:TOKEN_STATUS}
 */
export const currentTokenStatus = () => {

  // Guard
  if (!isLoggedin()) { return TOKEN_STATUS.NO_TOKEN }

  if (isRTExpired()) { return TOKEN_STATUS.REFRESH_TOKEN_EXPIRED }

  if (isATExpired()) { return TOKEN_STATUS.ACCESS_TOKEN_EXPIRED }

  return TOKEN_STATUS.TOKEN_VALID
}



/**
 * Decode and breakdown JWT token and store in browser localStorage
 * @param {JSON} jwtToken JWT Token in JSON
 */
export const setUserToken = (jwtToken, decodeJWT = false) => {
  // console.log(jwtToken);

  try {
    setStoreValue(APPLICATION_CONSTANT.USER_ID,        jwtToken.userId);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }

};

export const removeUserToken = () => {
  removeStorage(APPLICATION_CONSTANT.TOKEN_PAYLOAD);
  removeStorage(APPLICATION_CONSTANT.USER_ROLES);
  // removeStorage(APPLICATION_CONSTANT.USER_ID);
};

 
/**
 * Refresh Token Related
 * 
 */
export const forceRefreshToken = async () => {

  // Guard
  if (!hasAccessToken() && !hasRefreshToken()) return Promise.reject(TOKEN_STATUS.NO_TOKEN);
  if (isExpiredToken(APPLICATION_CONSTANT.REFRESH_EXPIRY)) return Promise.reject(TOKEN_STATUS.REFRESH_TOKEN_EXPIRED);

  (process.env.NODE_ENV === "development") 
    && console.log(`*** FORCE REFRESH TOKEN [${Date()}] ***`);

  return api.get(API_URL.REFRESH_TOKEN)
    .then(
      r => { return Promise.resolve(TOKEN_STATUS.TOKEN_VALID); },
      e => {
        (process.env.NODE_ENV === "development") && console.error("...refresh token fail", e);
        // removeUserToken();  // TBC
        return Promise.reject(e);
      }
    );

}


// TODO: Reconsider following three auto function
export const refreshTokenIfNeeded = async (shouldAutoRefresh = false) => {

  if (isValidToken()) return Promise.resolve(TOKEN_STATUS.TOKEN_VALID);

  return forceRefreshToken()
    .then(
      r => {
        shouldAutoRefresh && startAutoJWTRefresh();
        return Promise.resolve(r);
      },
      e => Promise.reject(e)
    );

}


let autoTokenRefreshInterval = null;
export const startAutoJWTRefresh = () => {
  
  if (hasRefreshToken() && autoTokenRefreshInterval === null) {
    const intervalSec = (60 * 1000 * (process.env.NODE_ENV === "development" ? 3 : 15));
    process.env.NODE_ENV === "development" && console.log(`[token service] Auto Token Refresh ${intervalSec/1000}sec`);

    autoTokenRefreshInterval = setInterval(() => {
      // refreshTokenIfNeeded();
      forceRefreshToken()
        .then(
          r => {}/* (process.env.NODE_ENV === "development") 
                  && console.log("BG JWT Refreshed", new Date(Date.now()).toUTCString()) */,
          e => { stopAutoJWTRefresh(); /* Promise.reject(e); */ }
        )
    }, intervalSec);
  
    return true;
  }

  return false;
}

export const stopAutoJWTRefresh = () => {
  
  if (autoTokenRefreshInterval !== null) {
    process.env.NODE_ENV === "development" && console.log("BG JWT Refresh Stopped " + new Date(Date.now()).toUTCString());
    clearInterval(autoTokenRefreshInterval);
    return true;
  }

  return false;
}