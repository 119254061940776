import { useState, useContext, useEffect } from 'react';
import { SITE_MAP_URL } from '../constant';
// import ChangePassword from '../pages/member/ChangePassword';
import DiscardScheduleChangesDialog from '../components/DiscardScheduleChanges';
import AuthComponent from '../components/AuthComponent';
// Services & Context
import { getClientBusinessName } from '../services/application.service';
import { hasAccessToken } from '../services/token.service';
import { getUserProfiles } from '../services/user.service';
import { ScheduleContext } from '../context/ScheduleContext';
// Library
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { navigate, Link } from '@reach/router';
import { PropTypes } from 'prop-types';
// mui
import { AppBar, Container, Box, Toolbar, Menu, MenuItem, Typography, IconButton, Stack, Divider, ListItemIcon, useTheme, Dialog } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon    from '@mui/icons-material/Logout';
import { grey } from '@mui/material/colors';
import HelpIcon from '@mui/icons-material/Help';

import '../styles/site-template.scss';

// Site Logo (Client)
const SiteTitle = () => {

  const cName = getClientBusinessName(true).join("");

  return <Box component="img"
      src={(cName !== "") ? `/images/${cName}/logo.svg` : "/logo192.png" }
      sx={{ maxWidth: '150px', height: "auto", maxHeight: "50px" }}
    />
}


const SiteLinks = () => {

  const { scheduleState: { hasChanges } } = useContext(ScheduleContext);
  const [afterDiscardURL, setAfterDiscardURL] = useState("");
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const isActive = ({ isPartiallyCurrent }) => {
    return {
      style: {
        position: 'relative',
        color: 'inherit',
        textDecoration: 'none',
        padding: '8px 12px 20px',
        borderRadius: '6px 6px 0 0',
        bottom: "-6px",
        backgroundColor: isPartiallyCurrent ? theme.palette.branding.highlight : 'inherit',
        "&:hover": {
          backgroundColor: 'rgba(0,0,0, 0.3)' // `rgba(${theme.palette.branding.highlight}, 0.5)`
        }
      }
    }
  }

  const lnkOnClick = (e, targetURL) => {
    e.preventDefault();

    if ( ! hasChanges) {
      setOpenConfirmationDialog(false);
      navigate(targetURL);
    }

    setAfterDiscardURL(targetURL);
    setOpenConfirmationDialog(true);
  }


  return <Stack component='nav' direction="row" alignItems='center' justifyContent="flex-start" spacing={2} flexGrow={1} sx={{ pl: 2 }}>
    <Link onClick={(e) => lnkOnClick(e, SITE_MAP_URL.EVENT) }             to={SITE_MAP_URL.EVENT}             getProps={isActive}>{t('sitemap.manage')}</Link>
    <Link onClick={(e) => lnkOnClick(e, SITE_MAP_URL.DOWNLOAD_SCHEDULE) } to={SITE_MAP_URL.DOWNLOAD_SCHEDULE} getProps={isActive}>{t('sitemap.download')}</Link>
    <Link onClick={(e) => lnkOnClick(e, SITE_MAP_URL.LOGO_LIBRARY)}       to={SITE_MAP_URL.LOGO_LIBRARY}      getProps={isActive}>{t('sitemap.logo_library')}</Link>
    {hasChanges 
      && openConfirmationDialog 
      && <DiscardScheduleChangesDialog 
        performDiscard={() => { setOpenConfirmationDialog(false); navigate(afterDiscardURL); }}
        cancelAction={() => setOpenConfirmationDialog(false)}
      />
    }
  </Stack>
}


const UserMenu = (props) => {

  const [userProfile, setUserProfile]   = useState({});
  const [anchorElUser, setAnchorElUser] = useState(null);
  // const [openChgPwd, setOpenChgPwd]     = useState(false);
  const { t } = useTranslation();

  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);

  // const closeChangePwd = () => setOpenChgPwd(false);
  // const handleChangePwd = () => {
  //   setOpenChgPwd(true);
  //   handleCloseUserMenu();
  // }

  // One-off User Profile fetch
  useEffect(() => {
    const fup = async () => {
      const prof = await getUserProfiles();
      ( ! _.isEmpty(prof) ) && setUserProfile(prof);
    }
    fup();
  }, []);


  return (
    <Box sx={{ flexGrow: 0 }}>
      <IconButton 
        size="large"
        aria-label={t("global.aria.accProfile")}
        onClick={handleOpenUserMenu} 
        sx={{ p: 0 }}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >

        { ! _.isEmpty(userProfile) 
          && <Typography variant='body2' sx={{ pt: 0.5, pb: 1, px: 2 }}>
              {`${userProfile.firstname} ${userProfile.lastname}`}
             </Typography>
        }
        { ! _.isEmpty(userProfile) && <Divider />}


        {/* {false && <MenuItem onClick={handleChangePwd} disabled>
          <Typography textAlign="center">{t('loginPage.chgPwd')}</Typography>
        </MenuItem>}
        <ChangePassword
          open={openChgPwd}
          dialogOption={{
            closeAction: closeChangePwd,
          }}
        /> */}

        <MenuItem onClick={() => navigate(SITE_MAP_URL.LOGOUT)}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <Typography fontWeight={500} textAlign="left">{t('loginPage.LogoutBtn')}</Typography>
        </MenuItem>

      </Menu>
    </Box>
  );

}

const Header = (props) => {

  const { preparing, plainMode } = props;
  const [openHelp, setOpenHelp] = useState(false);

  return plainMode ? 
    <Box component="header" className="frameShadow" 
      sx={{ 
        width: "100%", 
        height: "50px", 
        py: "4px", px: 2, 
        background: 'url(/logo192.png) no-repeat left 16px center', 
        backgroundSize: "40px" 
      }} 
    />
    : 
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Stack direction="row" spacing={1} flexGrow={1} alignItems="center" justifyContent="space-between">
            <SiteTitle flexGrow={1} />
            {(preparing === undefined || preparing === false) ? <SiteLinks /> : <Box flexGrow={1} />}
            
            {hasAccessToken() && <>
              <IconButton aria-label="help" onClick={() => setOpenHelp(true)}>
                <HelpIcon />
              </IconButton>
              <Dialog
                open={openHelp}
                maxWidth="lg"
                fullWidth={true}
                onClose={() => setOpenHelp(false)}
              >
                <iframe src={SITE_MAP_URL.USER_GUIDE} height="800" width="100%" title="user guide"></iframe>
              </Dialog>
            </>}
            {hasAccessToken() && <UserMenu />}
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>;
}

const Copyright = (props) => {
  const { t } = useTranslation();  
  const crTxt = `© ${new Date().getFullYear()} ${t('global.copyright')}`;

  return <Box className="copyText" {...props}>{crTxt}</Box>
}

const SiteIdentity = () => (
  <Box className="siteIdentity" sx={{
    backgroundImage: 'url(/company/logo-slider-rbg-negative-black.svg)',
  }}></Box>
)

const SiteVersion = () => <Box sx={{ color: grey[600], fontSize: "11px", mt: '0 !important' }}>{`v${process.env.REACT_APP_VERSION ?? ""}`} ({process.env.REACT_APP_PIPELINE_BUILDID})</Box>


const Footer = () => (
  <Box component="footer" className='frameShadow'>
    <Container maxWidth="xl" sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
        <Copyright />
        <SiteVersion />
      </Stack>
      <SiteIdentity />
    </Container>
  </Box>
)

const MainWrapper = (props) => <Container maxWidth="xl" component="main" sx={props.sx}>
  {props.children}
</Container>


const StandardContentPage = (props) => {

  let commonBody = (
    <>
      <Header />
      <Container maxWidth="xl" component="main" sx={props.sx}>
        {props.children}
      </Container>
      <Footer />
    </>
  )
  
  return (props.protectedPage) 
    ? <AuthComponent>{commonBody}</AuthComponent> 
    : <>{commonBody}</>;
}
StandardContentPage.propTypes = {
  protectedPage: PropTypes.bool,
  sx           : PropTypes.object,
  children     : PropTypes.node,
}
StandardContentPage.defaultProps = {
  protectedPage : false,
  sx            : {},
}


export {
  Header,
  MainWrapper,
  Footer,
  SiteIdentity,
  Copyright,
  StandardContentPage,
}