import { useState, useEffect } from 'react';

const useRefResize = (targetRef) => {

  const [refWidth,  setRefWidth]  = useState(0);
  const [refHeight, setRefHeight] = useState(0);

  // Helper fn
  // Can't be useCallback for external execution
  const updateRefSize = () => {
    if (targetRef.current) {
      setRefWidth(targetRef.current.offsetWidth);
      setRefHeight(targetRef.current.offsetHeight);
      // process.env.NODE_ENV === "development" && console.log("New Size", targetRef.current.offsetWidth, targetRef.current.offsetHeight);
    }
  };


  useEffect(() => {
    // Monitoring
    window.addEventListener('resize', updateRefSize);

    return () => {
      window.removeEventListener('resize', updateRefSize);
    }

  // [28 Oct 2022] Can't have `useCallback` for updateRefSize if we want to use it externally, don't know what to do with this eslint wanting
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return { refWidth, refHeight, updateRefSize }
};
export default useRefResize;