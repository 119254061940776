import { useState, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ScheduleContext } from '../../context/ScheduleContext';
import { delImage, getLogosByConditions } from '../../services/media.service';
// Component
import LoadingSkeleton, { SKELETON_THEME } from '../LoadingSkeleton';
import LogoCard from './logoCard';
import ConfirmationDialog from '../ConfirmationDialog';
import { DIALOG_ACTION_TYPE } from '../DialogComponent';
// 3rd parties
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Alert, Typography, Collapse, Grid, Stack, Box, TextField, InputAdornment, FormControl, Select, MenuItem, InputLabel, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './logoLibrary.scss';
import UploadDropzone from '../UploadDropzone';
import { grey } from '@mui/material/colors';

export const LogoGroupingOptions = Object.freeze({
  SIZE: 1,
});
export const LogoSortingOptions = Object.freeze({
  NONE            : 0,  // No sorting
  TITLE_ASC       : 1,
  TITLE_DESC      : 2,
  // CREATE_DATE_ASC : 3,
  // CREATE_DATE_DESC: 4,
});


const LogoLibrary = ({
  selectable, // -----
  selectedList, setSelectedList, 
  
  uploadable, // -----
  listenToUploadMode,

  delable,    // -----
  // editable, // -----
  
  collapseSpeed, 
  ...props
}) => {

  const { scheduleState: { accountIDCtx }} = useContext(ScheduleContext);
  const { t } = useTranslation();

  // Core data
  const [logoList, setLogoList]          = useState({});

  // UX/UI
  // const [isFetching, setIsFetching]      = useState(true);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [showLogos, setShowLogos]       = useState(false);
  const [isUploading, setIsUploading]   = useState(false);


  // Search related
  const [searchKeyword, setSearchKeyword]     = useState("");
  const [selectedGroupBy, setSelectedGroupBy] = useState(LogoGroupingOptions.SIZE);
  const [selectedSortBy, setSelectedSortBy]   = useState(0);

  // Selection data list & related
  // const [selectedList, setSelectedList]  = useState([]);
  const selectionLimitationReached       = useMemo(() => (selectedList.length >= props.selectionQuota)
  , [selectedList.length, props.selectionQuota]);

  // Delete logo related
  const [targetDelLogo, setTargetDelLogo] = useState(null);
  const [delErrorMsg, setDelErrMsg]       = useState("");

  // Current screen size
  const screenSizeDisplayTxt = ({ width = '', height = '', unitId = 3 }) => width + t('global.unit.'+unitId) + ' (' + t('global.widthShort') + ') x ' + height + t('global.unit.'+unitId) + ' (' + t("global.heightShort") + ')';


  // Data handling --------------------
  const fetchLogoList = (animation = true, onCompleted = () => {}) => {
    animation && toggleLayout(true); // setIsFetching(true);

    // Prepare conditions
    let apiOptions = {};
    (props.targetLogoSizeFilter) && (apiOptions['filters'] = { rotatableDimension: { width: props.targetLogoSizeFilter.width, height: props.targetLogoSizeFilter.height } });
    (selectedGroupBy) && (apiOptions['group'] = selectedGroupBy);
    (selectedSortBy) && (apiOptions['sort'] = selectedSortBy);
    (_.isString(searchKeyword) && searchKeyword.length) && (apiOptions['keyword'] = searchKeyword);

    getLogosByConditions(accountIDCtx, apiOptions)
      .then(
        r => setLogoList(r),
        e => {
          process.env.NODE_ENV === "development" && console.error(e);
          setLogoList({});
        } // TODO: Error handling, info user data is outdated/from cache
      )
      .finally(() => setTimeout(() => {
        animation && toggleLayout(false); // setIsFetching(false);
        onCompleted();
      }, collapseSpeed));
  }

  // One-off Data fetch (page load)
  useEffect(() => {
    const initFetch = _.once(fetchLogoList);
    initFetch();  // Can't include it into dependence list, or it will be inf-loop
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps


  // User Interaction Handler
  const _promptDelConfirmation = (logoObj) => setTargetDelLogo(logoObj);
  const _performDel = () => {

    delImage(targetDelLogo.id, accountIDCtx)
      .then(
        r => {
          fetchLogoList(
            false, 
            () => setTargetDelLogo(null) 
          );
        },
        e => { console.error(e); setDelErrMsg( t('commonError.logo.delError.msg') ); }
      )
  }

  const _onSelect = (selectedFlag, logoObj) => {
    if (selectedFlag) {
      if ( !_.isNil( _.find(selectedList, { id: logoObj.id }) )) {
        console.warn(`Duplicated selection of logo '${logoObj.id}'`);
      }
      // Still allow to add to the list, if in the future, allow adding logo multiple times at once
      // than, Add logo to the selected list
      setSelectedList([ ...selectedList, logoObj ]);
    } else {
      setSelectedList( _.reject(selectedList, { id: logoObj.id }) )  
    }
  }

  const _setUploadMode = (flag) => {
    if (flag !== isUploading) {

      // Upload completed/end, refetch the latest list
      (!flag) && fetchLogoList(true);

      setIsUploading(flag);
    }
  }
  
  const toggleLayout = (startFetching = false) => {
    if (startFetching) {
      setTimeout(() => setShowSkeleton(true), showLogos ? collapseSpeed : 0);
      setShowLogos(false);
    } else {
      setTimeout(() => setShowLogos(true), showSkeleton ? collapseSpeed : 0);
      setShowSkeleton(false);
    }
  }

  // Clean selected list when switch toggled
  // Not necessary if selectable won't be change interactively
  useEffect(() => {
    (!selectable && selectedList.length) && setSelectedList([]);
  }, [selectedList.length, selectable, setSelectedList])

  // Auto info parent, if needed
  useEffect(() => listenToUploadMode && listenToUploadMode(isUploading)
  , [isUploading, listenToUploadMode]);
  
  // When conditions changed, refetch data
  useEffect(() => {
    fetchLogoList(false);
    console.log("Logo list re-fetched by conditions");
  }, [selectedGroupBy, selectedSortBy, props.targetLogoSizeFilter, searchKeyword, accountIDCtx]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>

    {uploadable
      && <UploadDropzone setUploadMode={_setUploadMode} collapseSpeed={collapseSpeed} sx={{ mb: 2 }} />}


    {/* Control panel */}
    <Collapse in={!isUploading} easing="ease-in-out" timeout={collapseSpeed}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mt: 1, mb: 2 }}>
        {/* Text Search Input */}
        <Box>
          <TextField label={t('imageLib.controlPanel.searchLabel')} variant='outlined' 
            onChange={(e) => setSearchKeyword(e.target.value)}
            size='small'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: 'action.active', ml: 1, my: 0.5 }} />
                </InputAdornment>
              )
            }} 
            sx={{ width: '24vw' }} />
        </Box>
        {/* Grouping & Sorting */}
        <Box>
          <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
            {/* Grouping */}
            <FormControl size='small' sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="group-by-label">{t('imageLib.controlPanel.groupByLabel')}</InputLabel>
              <Select 
                labelId='group-by-label'
                label="Group by" 
                id='group-by'
                value={selectedGroupBy} 
                onChange={(e) => setSelectedGroupBy(e.target.value)}
              >
                {Object.values(LogoGroupingOptions).map((val,i) => 
                  <MenuItem value={val} key={`grp-${i}`}>{t(`imageLib.controlPanel.groupOptions.${val}`)}</MenuItem>
                )}
              </Select>  
            </FormControl>
      
            {/* Sorting */}
            <FormControl size='small' sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="sort-label">{t('imageLib.controlPanel.sortLabel')}</InputLabel>
              <Select 
                labelId='sort-label'
                label={t('imageLib.controlPanel.sortLabel')}
                id='sort'
                value={selectedSortBy} 
                onChange={(e) => setSelectedSortBy(e.target.value)}
              >
                {Object.values(LogoSortingOptions).map((val,i) =>
                  <MenuItem value={val} key={`sort-${i}`}>{t(`imageLib.controlPanel.sortOptions.${val}`)}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Stack>
        </Box>
      </Stack>
    </Collapse>


    {/* Logo list */}
    <Collapse in={showSkeleton && !isUploading} easing="ease-in-out" timeout={collapseSpeed}>
      <LoadingSkeleton enable={true} skeletonTheme={SKELETON_THEME.IMAGE_CARD} sx={{ ...props.sx }} />
    </Collapse>

    <Collapse in={showLogos && !isUploading} easing="ease-in-out" timeout={collapseSpeed}>

      {(_.size(logoList) === 0)
        && <Alert severity="warning" sx={{ mb: 2 }}>
            {`${t("imageLib.noLogoMatchedSize")}`}
        </Alert>
      }

      {/* Logo grid - Flat array */}
      {Object.keys(logoList).sort().map((k) => (

        <Accordion key={`logoGrp-${k}`} disableGutters defaultExpanded={props.targetLogoSizeFilter !== undefined} sx={{ mb: 2 }}>

          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel-${k}`} 
            sx={{ 
              bgcolor: grey[200], 
              '&.Mui-expanded': { 
                minHeight: 'initial',
                '& .MuiAccordionSummary-content': { my: '12px' }
              },
              
            }}
          >
            <Typography>{`${t("global.imgSize")}: ${screenSizeDisplayTxt({ width: k.split('x')[0], height: k.split('x')[1] })}`}</Typography>
          </AccordionSummary>

          <AccordionDetails sx={{ py: 1.5, px: 1 }}>

            <Grid container spacing={1.5} 
              columns={{ 
                xs: Math.min(3, props.maxItemsPerRow), 
                md: Math.min(4, props.maxItemsPerRow), 
                lg: Math.min(5, props.maxItemsPerRow), 
                xl: Math.min(6, props.maxItemsPerRow)
              }}
              className={['logoLibraryGrid', (delable ? 'delable' : '')].join(' ')}
            >
              {
                logoList[k].map((logo) => (
                  <LogoCard
                    key={logo.id}
                    logoObj={logo}
                    selectionLimitationReached={selectionLimitationReached}
                    suggestedLogoRatio={props.suggestedLogoRatio}

                    showImgSize={false}
                    selectable={selectable} 
                    isSelected={ _.some(selectedList, { id: logo.id }) }
                    onSelect={_onSelect}

                    delable={delable}
                    onDel={_promptDelConfirmation}

                    // editable={editable}
                  />
                ))
              }
            </Grid>

          </AccordionDetails>
        </Accordion>
      ))}
    </Collapse>


    {/* Confirm Del logo dialog */}
    {!_.isNull(targetDelLogo) 
      && <ConfirmationDialog
        open={true}
        dialogOption={{
          desc: t("alert.confirmDel") + (targetDelLogo ? ` '${targetDelLogo.title}'?` : "?"), 
          cancelText: t('global.NO'),
          okText: t('global.YES'), 
          okColor: 'error',
          okAction: _performDel, 
          okActionType: DIALOG_ACTION_TYPE.async,
          closeAction: () => { setTargetDelLogo(null); setDelErrMsg(null); },
          actionErrorMsg: delErrorMsg,
          maxWidth: 'sm',      
        }}
      />
    }
  </>
}

// Prop Definition --------------------
LogoLibrary.propTypes = {
  // Features flag
  targetLogoSizeFilter: PropTypes.shape({
    width : PropTypes.number,
    height: PropTypes.number,
    unitId: PropTypes.number,
  }),
  
  // Logo selection related
  selectable     : PropTypes.bool,
  selectionQuota : PropTypes.number,  // Max number of logo(s) can be select, -1 to turn it off
  selectedList   : PropTypes.array,   // Owned by parent, should we?
  setSelectedList: PropTypes.func,    // Owned by parent, should we?
  
  // Logo Upload and related
  uploadable        : PropTypes.bool,
  listenToUploadMode: PropTypes.func,      // NOT owned by parent, but info parent

  // Logo edit and related
  // editable: PropTypes.bool,

  // Logo delete and related
  delable: PropTypes.bool,

  // UX/UI
  suggestedLogoRatio: PropTypes.number,
  collapseSpeed     : PropTypes.number,
  maxItemsPerRow    : PropTypes.number,
}
LogoLibrary.defaultProps = {
  targetLogoSizeFilter: undefined,
  
  // Selection and related
  selectable     : false,
  selectionQuota : 0,
  selectedList   : [],
  setSelectedList: () => {},

  // Upload related
  uploadable        : false,
  listenToUploadMode: undefined,

  // edit related
  // editable: false,

  // delete related
  delable: false,

  suggestedLogoRatio: 0.75,        // Default logo size 4:3
  collapseSpeed     : 600,
  maxItemsPerRow    : 6,
}
export default LogoLibrary;