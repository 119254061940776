import axios from 'axios';
import APPLICATION_CONSTANT, { SITE_MAP_URL } from '../constant';
// import { hasAccessToken, getUserAccessToken, getUserRefreshToken, setUserToken, isExpiredToken, hasRefreshToken } from './token.service';
import { isATExpired, isExpiredToken, isLoggedin, isRTExpired } from './token.service';
import { navigate } from '@reach/router';
import { compact, some, includes, split } from 'lodash';

/**
 * Global Constant for API service.
 * API_URL    - All API URL defined here
 */
export const API_URL = Object.freeze({
  GATEWAY_DOMAIN: process.env.REACT_APP_GATEWAY_DOMAIN,

  // User Account related
  LOGIN            : "/identitymanagement/api/identity/tokens/cookies/access",
  REFRESH_TOKEN    : "/identitymanagement/api/identity/tokens/cookies/refresh",
  LOGOUT           : "/identitymanagement/api/identity/tokens/cookies/prohibit",
  GET_USER_ACCOUNTS: "/identitymanagement/api/identity/users/accounts/",
  FORGET_PASSWORD  : "/identitymanagement/api/identity/passwords/forgot",
  RESET_PASSWORD   : "/identitymanagement/api/identity/passwords/reset",
  CHANGE_PASSWORD  : "/identitymanagement/api/identity/passwords/change",
  USER_PROFILE     : "/identitymanagement/api/identity/profiles/",

  // Application data
  GET_APPLICATION: "/applicationmanagement/api/v1/applications/",
  GET_EVENT        : "/applicationmanagement/api/v1/events/",
  GET_EVENT_VERSION: "/applicationmanagement/api/v1/event/versions/",

  // Fulfilment
  GET_FULFILMENT : "/applicationmanagement/api/v2/fulfilments/",
  POST_FULFILMENT: "/applicationmanagement/api/v2/fulfilments/",
  CLONE_EVENT    : "/applicationmanagement/api/v2/clone/fulfilments/event",
  CLONE_RESOURCE : "/applicationmanagement/api/v2/clone/fulfilments/resource",
  DOWNLOAD_ZIP   : {
    1:  "/applicationmanagement/api/v2/generators/schedules",
    2:  "/applicationmanagement/api/v2/generators/schedules",
    99: "/applicationmanagement/api/v2/generators/schedules",  // for SD only 
  },
  QC_ZIP         : {
    1:  "/applicationmanagement/api/v2/generators/schedules",
    2:  "/applicationmanagement/api/v2/generators/schedules",
    99: "/applicationmanagement/api/v2/generators/schedules",  // for SD only
  },


  // Multimedia
  GET_UPLOAD_MULTIMEDIA: "/multimediamanagement/api/v1/multimedia/",
  POST_NEW_IMAGE       : "/multimediamanagement/api/v1/multimedia/",
  DELETE_IMAGE         : "/multimediamanagement/api/v1/multimedia/",
});

const XAPI = Object.freeze({
  "applicationmanagement" : process.env.REACT_APP_XAPI_AM,
  "identitymanagement"    : process.env.REACT_APP_XAPI_IM,
  "multimediamanagement"  : process.env.REACT_APP_XAPI_MM,
  "notificationmanagement": process.env.REACT_APP_XAPI_NM,
});

/**
 * API Instance with default configuration. This will affect/apply to all API request/response.
 */
const api = axios.create({
  baseURL: API_URL.GATEWAY_DOMAIN,
  headers: {
    "Content-Type": "application/json",  // "Content-Type": "application/json;charset=utf-8",
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SUB_KEY,
  },
  withCredentials: true
});


// const pagesIgnoreJWT = [
//   API_URL.GATEWAY_DOMAIN + API_URL.REFRESH_TOKEN, 
//   // API_URL.GATEWAY_DOMAIN + API_URL.LOGIN
// ];

/**
 * API Default interceptors for Access & Refresh token
 */
api.interceptors.request.use(
  (config) => { 

    const urlFolders = compact( split(config.url, "/") );
    const keys = Object.keys(XAPI).filter(x => urlFolders.includes(x))
    if (keys.length >= 1) {
      config.headers["x-api-key"] = XAPI[keys[0]]
    }
    
    (false && process.env.NODE_ENV === "development") && console.log("AXIOS XAPI", keys, config.url, config.headers["x-api-key"]);
    return config;

    // refreshTokenIfNeeded()
      // .then(
        // () => {
          // console.log("instance.interceptors.request.use");
/*
          if (hasAccessToken() && !isExpiredToken(APPLICATION_CONSTANT.REFRESH_EXPIRY)) {

            // When it is not login, append JWT Bearer token
            if ( ! pagesIgnoreJWT.includes(config.url) ) {

              config.headers["Authorization"] = `Bearer ${getUserAccessToken()}`;
              // config.headers["x-access-token"] = token;
            } else {
              (process.env.NODE_ENV === "development") 
                && console.log(`- JWT Bearer token SKIPPED -----`);
            }
          }
          return config;
*/
        // },
        // e => return config
      // )

  },
  (error) => {
    (process.env.NODE_ENV === "development") 
      && console.error("==========\ninstance.interceptors.request.use.err", error, "\n==========\n");
    
    // Error handling
    navigate(SITE_MAP_URL.LOGOUT);
  }
);


api.interceptors.response.use(
  (res) => {
    // console.log("instance.interceptors.response.use");
    return res;
  },
  async (err) => {

    const originalConfig = err.config;
    
    (process.env.NODE_ENV === "development") 
      && console.error("axios.interceptors.response Error: ", err.response.status, "_retry", !originalConfig._retry, "isExpiredToken", isExpiredToken(APPLICATION_CONSTANT.REFRESH_EXPIRY));

    if (err.response.status === 401) {
      
      // Second time of 401, Logout
      if (originalConfig._retry || some(err.response.data.Errors, e => includes(["1000002", "1000003", "1000004"], e.Code))) {
        navigate(SITE_MAP_URL.LOGOUT);  // TODO: Inform user for the reason
      }
    
      // Try refresh JWT
      if (
        (
          isLoggedin() 
          &&
          (isATExpired() && !isRTExpired())
        )
        || some(err.response.data.Errors, ["Code", "1000000"])
      ) {
        try {

          (process.env.NODE_ENV === "development") && console.log("401 - Try refresh token");
          originalConfig._retry = true;

          api.get(API_URL.GATEWAY_DOMAIN + API_URL.REFRESH_TOKEN)
            .then(
              () => {},
              err => {
                navigate(SITE_MAP_URL.LOGOUT);  // TODO: Alert user for the reason
                return Promise.reject(err);
              }
            )
          (process.env.NODE_ENV === "development") && console.log("Retry");

          // Resume previous work
          const followup = api(originalConfig);/* .then(
            r => Promise.resolve(r),
            e => Promise.reject(e)
          ) */
          return Promise.resolve( followup );

        } catch (_err) {
          (process.env.NODE_ENV === "development") && console.error("interceptors refresh error", _err);
          return Promise.reject(_err);
        }
      }
    } 
      
    console.error("Non 401 error", err.response.status, err);
    return Promise.reject(err);
  }
);
export default api;
