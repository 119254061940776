import { useState } from 'react';
import PropTypes from 'prop-types';
import { DIALOG_ACTION_TYPE, DialogOptionType } from './DialogComponent';
// MUI
import Button from '@mui/material/Button';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
// Icon
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';


// Helper Function --------------------
const DialogTitleFn = ({ dialogOption, handleClose }) => {

  const { t } = useTranslation();

  return <>{dialogOption.title && 
    <DialogTitle id="alert-dialog-title">
      {dialogOption.title}
      <IconButton
        aria-label={t("global.close")}
        onClick={handleClose}
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>}</>
}



// Primary Action Buttons
const DialogPrimaryActionButtonFn = ({ dialogOption, handleOK }) => {

  const [actionLoading, setActionLoading] = useState(false);
  const { t } = useTranslation();

  const performOK = () => {
    setActionLoading(true);
    handleOK && handleOK();
  }

  return <>
    {dialogOption.okActionType === DIALOG_ACTION_TYPE.async &&
      <LoadingButton 
        autoFocus
        loading={actionLoading && dialogOption.actionErrorMsg === ""}
        disabled={actionLoading && dialogOption.actionErrorMsg === ""}
        variant="contained"
        color={dialogOption.okColor ?? "primary"} 
        onClick={performOK} 
      >
        {dialogOption.okText ?? t("global.OK")}
      </LoadingButton>
    }

    {dialogOption.okActionType === DIALOG_ACTION_TYPE.button &&
      <Button 
        autoFocus
        disabled={actionLoading}
        variant="contained"
        color={dialogOption.okColor ?? "primary"} 
        onClick={performOK} 
      >
        {dialogOption.okText ?? t("global.OK")}
      </Button>
    }

  </>
}


// Action Button Panel (Container)
const ActionButtonPanel = ({ dialogOption, cancelAction, okAction }) => {

  const { t } = useTranslation();

  return (
    <Stack alignItems="stretch" spacing={1} sx={{ flexGrow: 1 }}>

      <Stack direction="row" alignItems='center' justifyContent='space-between' spacing={1}>
        {/* Secondary Group */}
        <Stack direction="row">
          <Button onClick={cancelAction} color="info" /* variant="outlined" */>{dialogOption.cancelText ?? t("global.cancel")}</Button>
        </Stack>

        {/* Primary Group */}
        <Stack direction="row">
          <DialogPrimaryActionButtonFn dialogOption={dialogOption} handleOK={okAction} />
        </Stack>
      </Stack>

      {/* Error Message if any */}
      {dialogOption.actionErrorMsg && dialogOption.actionErrorMsg !== "" && 
      <Alert severity="error" 
        sx={{ 
          alignSelf: "flex-end",
          "& .MuiAlert-message": { pt: "2px", pb: "1px" },
          "& .MuiAlert-icon":    { pt: "1px", pb: "0px", mr: "8px" }
        }}
      >
        {dialogOption.actionErrorMsg}
      </Alert>}

    </Stack>
  )

}


// - Core -----------------------------
/**
 * 
 * @param {object, optional} dialogOption {
 *  - Props
 *  title
 *  desc
 *  okText: string, default 'OK'
 *  okColor
 *  cancelText: string, default 'Cancel'
 *  maxWidth: string, default 'lg
 *  - Behavior
 *  okAction
 *  okActionType: DIALOG_ACTION_TYPE, default: DIALOG_ACTION_TYPE.button
 *  closeAction
 *  actionErrorMsg
 * }
 * @param {string, optional, "async"/"button"} dialogOption.okActionType
 * 
 * @returns Material UI Dialog Component 
 */
export const ConfirmationDialog = (props) => {

  const defaultOption = {
    okActionType: DIALOG_ACTION_TYPE.button,
    actionErrorMsg: "",
  }
  const dialogOption = { ...defaultOption, ...props.dialogOption };


  const handleClose = () => {
    dialogOption.closeAction && dialogOption.closeAction();
  };

  const handleOK = () => {
    dialogOption.okAction && dialogOption.okAction();
  }

  return (
    <Dialog 
      open={props.open}   
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby='alert-dialog-description'
      maxWidth={dialogOption.maxWidth ?? "lg"}
      fullWidth
    >
      <DialogTitleFn dialogOption={dialogOption} handleClose={handleClose} />

      <DialogContent>

        {dialogOption.desc &&
        <DialogContentText id="alert-dialog-description">
          {dialogOption.desc}
        </DialogContentText>}

      </DialogContent>

      <DialogActions>
        <ActionButtonPanel 
          dialogOption={dialogOption}
          cancelAction={handleClose}  
          okAction={handleOK}
        />
      </DialogActions>
      
    </Dialog>
  );

}


ConfirmationDialog.propTypes = {
  open        : PropTypes.bool,
  dialogOption: DialogOptionType,
}

export default ConfirmationDialog;