import { Fragment, useState, useRef, useEffect } from 'react';
import { Header, Footer /* StandardContentPage */ } from '../site-template/SiteFrame';
import { getStoredValue, logging, setStoreValue, removeStorage } from '../common/utils';
import EventMeta from '../components/EventMeta';
import LoadingSkeleton from '../components/LoadingSkeleton';
import { getZIPQCStatus, requestZIPQC } from '../services/schedule.service';
import { getFullEventList } from '../services/application.service';
import { SomethingMissing } from '../components/SomethingWrong';
import { EVENT_VERSION_VALIDATION_STATUS, SCHEDULE_STATUS } from '../constant';
// import ActionFeedbackDialog from '../components/ActionFeedbackDialog';
import DialogComponent from '../components/DialogComponent';
// mui
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material';
// library
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';


/**
 * F1WEB-444 (Temporary) ZIP QC request process
 * ZIP file QC status:
 *    "0": "Blank, never generated" <- UI ONLY, backend will return/treat it as 404, this is backend design
 *    "1": "Pending",
 *    "2": "Approved",
 *    "3": "Revoked"
 * Refer to: "Schedule validation process automated" on Confluence. https://spt93.atlassian.net/wiki/spaces/SST/pages/1290174478/Schedule+validation+process+automated
 * 
 * @param {string/guid} Event ID - Event ID GUID 
 */
const RequestQCButton = ({ eventId, ...props}) => {

  const { t } = useTranslation();

  const [eventQCStatus, setEventQCStatus] = useState(0);
  const [zipURL, setZipURL]               = useState(null);

  const [isFetching, setIsFetching]       = useState(true);
  const [dialogOpen, setDialogOpen]       = useState(false);  // better UX/UI
  const [dialogOptions, setDialogOptions] = useState({});

  // Interval for 'Repeative check ZIP generation status'
  const repeativeCheckZIPStatus = useRef(null)
  // let repeativeCheckZIPStatus = null
  // Interval end

  // TOTALLY TEMPORARY SOLUTION, until maunal QC is not necessary ----
  const FAIL_RETRY_COUNTER_KEY = 'frck';
  const getFailCount   = () => getStoredValue(FAIL_RETRY_COUNTER_KEY, 0);
  const isFirstFail    = () => getStoredValue(FAIL_RETRY_COUNTER_KEY) < 1;
  const failPlusOne    = () => setStoreValue(FAIL_RETRY_COUNTER_KEY, getFailCount() + 1);
  const resetFailCount = () => removeStorage(FAIL_RETRY_COUNTER_KEY);
  // TOTALLY TEMPORARY SOLUTION, until maunal QC is not necessary ----

  const dismissDialog = () => {
    setDialogOpen(false);
    setTimeout(() => setDialogOptions({}), 200);
  }
  const dialogDefaultOptions = {
    okAction: () => dismissDialog(),
    maxWidth: 'sm',
  };

  const startZIPMonitor = () => {
    if (repeativeCheckZIPStatus.current === null) {
      repeativeCheckZIPStatus.current = setInterval(_fetchZIPQCStatus, 2000);
      // console.log("Start ZIP monitor", repeativeCheckZIPStatus.current);
    }
  }

  const stopZIPMonitor = () => {
    if (repeativeCheckZIPStatus.current !== null) {
      clearInterval(repeativeCheckZIPStatus.current)
      repeativeCheckZIPStatus.current = null
      // console.log("Stop ZIP monitor", repeativeCheckZIPStatus.current)
    }
  }

  const _requestQC = () => {
    setIsFetching(true);

    requestZIPQC(eventId)
      .then(
        r => { 
          resetFailCount();

          /* process.env.NODE_ENV === "development" && console.log("Req QC", r);
          setDialogOptions({
            ...dialogDefaultOptions,
            desc: t('downloadPage.alert.reqQC.success'),
          });
          setDialogOpen(true); */

          // 21 May 2024, ZIP Generation is running in background so UI should auto reload the status
          // _fetchZIPQCStatus()
          startZIPMonitor();
        },
        e => { 
          console.log("Req QC err", e);
          stopZIPMonitor();
          
          setDialogOptions({
            ...dialogDefaultOptions,
            okAction: () => { console.log("retry"); failPlusOne(); _requestQC(); },
            okText: t('global.retry'),
            okColor: "warning",
            closeAction: () => { console.log("cancel"); dismissDialog(); },
            desc: t(`downloadPage.alert.reqQC.fail-${isFirstFail() ? 'first' : 'again'}`),
          });
          setDialogOpen(true);
        }
      )
      .finally(() => {
        _fetchZIPQCStatus();
      })
  }

  const _fetchZIPQCStatus = () => {

    setIsFetching(true);

    getZIPQCStatus(eventId)
      .then(
        r => {
          /* process.env.NODE_ENV === "development" 
            && console.log(
              "ZIP QC Status",
              eventId,
              r.status,
              "z:"+r.version?.format(), 
              (r.version !== undefined && props.eventLastUpdateUTC.isAfter(r.version)), 
              "u:"+props.eventLastUpdateUTC.format()
            ); */

          // console.log("fetchZIPQCStatus:", eventId, r.status, props.eventLastUpdateUTC, props.eventLastUpdateUTC.isAfter(r.version));
          // console.dir(r);

          const outdatedFlag = r.version !== undefined && props.eventLastUpdateUTC.isAfter(r.version);
          setEventQCStatus( outdatedFlag ? 0 : r.status); // Outdated ZIP will be ignored and start-over

          // Loading indicator
          setIsFetching( r.status === EVENT_VERSION_VALIDATION_STATUS.PENDING );

          // ZIP generated, prepare URL for download
          (!outdatedFlag && r.status === EVENT_VERSION_VALIDATION_STATUS.APPROVED) && setZipURL(r.zip);

          // Stop Interval once ZIP finished
          (r.status === EVENT_VERSION_VALIDATION_STATUS.PENDING) 
          ? startZIPMonitor() 
          : stopZIPMonitor()

        },
        e => {
          process.env.NODE_ENV === "development" && console.error(e);
          setEventQCStatus(0);
          setIsFetching(false);
        }
      )
      // .finally(() => { 
      //   setIsFetching(false); 
      // })

  }

  useEffect(() => {
    _fetchZIPQCStatus();

    return () => {
      if (repeativeCheckZIPStatus.current !== null) {
        console.log("useEffect before return", repeativeCheckZIPStatus.current);
        stopZIPMonitor()
      }
    }
  }, [repeativeCheckZIPStatus.current]);   // eslint-disable-line react-hooks/exhaustive-deps

  return (eventQCStatus === EVENT_VERSION_VALIDATION_STATUS.APPROVED)
    ? <Button variant="contained" href={zipURL}>{t('downloadPage.downloadBtn')}</Button>
    : <>
      <LoadingButton 
        disabled={(eventQCStatus === EVENT_VERSION_VALIDATION_STATUS.PENDING || isFetching)}
        loading={eventQCStatus === EVENT_VERSION_VALIDATION_STATUS.PENDING || isFetching}
        onClick={_requestQC}
        variant="outlined" 
        color={eventQCStatus >= EVENT_VERSION_VALIDATION_STATUS.REVOKED ? "warning" : "primary"}
      >
        {t(`downloadPage.QCBtn.${eventQCStatus}`)}
      </LoadingButton>

      <DialogComponent
        open={dialogOpen}
        dialogOption={dialogOptions}
      />
    </>

};
RequestQCButton.propTypes = {
  eventId: PropTypes.string.isRequired,
  eventLastUpdateUTC: PropTypes.instanceOf(moment)
}
RequestQCButton.defaultProps = {
}


const EventSelectionItem = (props) => {

  const { t } = useTranslation();
  // const [requesting, setRequesting] = useState(false);
  // const [reqResult, setReqResult] = useState({});


  /* const handleClick = () => {
    // Temporary disable row selection
    return;

    if (props.selectedFlag || props.obj.status.id !== SCHEDULE_STATUS.COMPLETE) return;

    props.onClick && props.onClick(props.obj)
  } */

  const lastUpdateMeta = () => {
    const statusID = props.obj.status.id;
    const lastModifiedOn = props.obj.lastModifiedOn.utc;
    
    if (lastModifiedOn) {
      const lud = moment.utc(lastModifiedOn);
      return t("global.lastUpdate") + ` ` + lud.fromNow();
    } else {
      if (statusID === SCHEDULE_STATUS.BLANK) {
        return t("schedulePage.eventStatus.blank");
      } else if (statusID === SCHEDULE_STATUS.INCOMPLETE) {
        return t("schedulePage.eventStatus.incomplete");
      } else if (statusID === SCHEDULE_STATUS.COMPLETE) {
        return t("schedulePage.eventStatus.completed");
      }

      // consider it is ERROR, should be empty
      return "";
    }
  }

  /* const _onRequestDownload = () => {
    setRequesting(true);

    setTimeout(() => {
      
      setReqResult({
        success: true,
        errorMsg: ``,
      });
      setRequesting(false);

    }, 1500);
  } */

  return <>
    <ListItem 
      className={`
        ${(props.isPast && props.obj.status.id !== SCHEDULE_STATUS.COMPLETE)  ? "isPast" : ""}
        ${props.selectedFlag ? " isSelected" : ""}
        ${(props.obj.status.id === SCHEDULE_STATUS.COMPLETE) ? "" : " isUnselectable"}
      `}
      /* onClick={handleClick} */
    >

      <EventMeta eventMeta={props.obj} dimmer={props.isPast} />

      {props.obj.status.id === SCHEDULE_STATUS.INCOMPLETE &&
        <Stack direction='row' alignItems='center' spacing={0.5}>
          <WarningAmberRoundedIcon color="warning" fontSize="large" />
          <Typography variant='body2'>{lastUpdateMeta()}</Typography>
        </Stack>
      }

      {props.obj.status.id === SCHEDULE_STATUS.COMPLETE &&
        <Stack direction='row' alignItems='center' spacing={0.5}>
          <CheckCircleIcon color="success" fontSize="large" />
          <Typography variant='body2'>{lastUpdateMeta()}</Typography>

          {/* {!props.isPast 
            &&  */}
            <Box sx={{ ml: 0.5 }}>
              <RequestQCButton 
                eventId={props.obj.id} 
                eventLastUpdateUTC={ moment.utc(props.obj.lastModifiedOn.utc) } 
                />
            </Box>
          {/* } */}
        </Stack>
      }

      {/* {!_.isEmpty(reqResult)
        && <ActionFeedbackDialog open={true}>

          <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
            <CheckCircleIcon color='success' fontSize='large' />
            <ErrorOutlineIcon color='error' fontSize='large' />

            {(reqResult.success)  && <Typography>Request received, we will inform you when it ready</Typography>}
            {(!reqResult.success) && <Typography>Error happened, please try again later</Typography>}
          </Stack>

          <Button variant='outlined' onClick={() => setReqResult({})} sx={{ mt: 2 }}>
            {t("global.close")}
          </Button>

        </ActionFeedbackDialog>} */}

    </ListItem>

  </>;
}



const Download = (props) => {

  const [selectedEvent, setSelectedEvent]       = useState("");
  const [pageIsLoading, setPageIsLoading]       = useState(true);
  const [zipFileURL, setZipFileURL]             = useState("");
  const [zipRecommendedName, setZipRecommendedName] = useState("");
  const [eventData, setEventData]               = useState([]);
  const [isLoadingEvent, setIsLoadingEvent]     = useState(true);

  const todayRef    = useRef(null);
  const downloadRef = useRef(null);

  const theme = useTheme();
  const { t } = useTranslation();

  // ----------------------------------
  // force moment to update the latest
  // It is USELESS now, but it will be call again under <EventSelectionItem />
  moment.tz.guess(true);
  let today = moment.utc();
  let todayShow = false;
  // ----------------------------------  

  const dividerProps = { variant: 'middle' };
  const todayProps = { 
    variant: "fullWidth",
    textAlign: "left",
    sx: {
      ":before, :after": { 
        borderTopColor: theme.palette.branding.main, 
        borderTopWidth: '3px' 
      },
      color: theme.palette.branding.main,
      fontWeight: 'bold'
    },
  }

  const handleEventClick = (eventObj) => {
    if (eventObj) {
      setSelectedEvent(eventObj.id);

      const suggestedZIPName = eventObj.title.replace(" ", "-") + "_" + moment.utc( eventObj.lastModifiedOn.utc ).format("YYYY-MM-DD_hh-mm-ss") + ".zip";
      logging("Suggested ZIP: " + suggestedZIPName);
      setZipRecommendedName(suggestedZIPName)
    }
  }

  const scrollToRef = (targetRef, delay = 0, block = 'center') => setTimeout((ref) => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({behavior: "smooth", block: block});
    }
  }, delay);


  // Fetch Event List
  useEffect(() => {

    setIsLoadingEvent(true);

    getFullEventList(true)
      .then(
        eventList => {

          // console.log("Download.getFullEventList", eventList );

          if (eventList && eventList.length > 0) {
            // setEventData( eventList );
            setEventData( eventList );
            setPageIsLoading(false);
          }
        },
        e => setEventData([])
      )
      .finally(() => setIsLoadingEvent(false));

  }, []);
  
  // Scroll to Today with delay
  useEffect(() => {
    (eventData.length > 0) && scrollToRef(todayRef, 300);
  }, [eventData]);

  // Auto Click the ZIP link if appear
  useEffect(() => {
    if ((zipFileURL && zipFileURL.length > 0) && (downloadRef && downloadRef.current)) {
      downloadRef.current.click();
      setTimeout(() => {
        setZipFileURL("");
      }, 500);
    }
  }, [zipFileURL]);


  return (
    <>
      <Helmet>
        <title>{t("sitemap.download")} | {t("global.productNameShort")}</title>
      </Helmet>
      <Header />
      
      <Container maxWidth="xl" component="main"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', pt: 3, maxWidth: "94%" }}
      >
    {/* <StandardContentPage protectedPage={true} 
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', pt: 3, maxWidth: "94%" }}
    > */}
      <LoadingSkeleton enable={isLoadingEvent} center />

      {!isLoadingEvent && <>
      
        {pageIsLoading && <LoadingSkeleton center />}

        {!pageIsLoading && (eventData === null || eventData.length === 0) && <SomethingMissing /> }

        {!pageIsLoading && eventData && eventData.length > 0 && <>
          <Box sx={{ width: "90%", mb: 2 }} alignItems="flex-start">
            {/* <Typography variant='h6'>{t("downloadPage.pageTitle")}</Typography> */}
            <Typography variant='subtitle1'>{t("downloadPage.pageSubTitle")}</Typography>
          </Box>

          <Paper elevation={4} sx={{ maxWidth: '92%', minWidth: '80%' }}>

            <List disablePadding sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>

              {eventData.map((obj,i) => {

                const isPast = today.isAfter(obj.endBaseDate.utc);
                
                const todayFlag = (!todayShow && !isPast);
                if (todayFlag) todayShow = true;  // Once only, turn it off

                return <Fragment key={"f_eItem" + i}>
                  {i > 0 &&
                    <Divider 
                      component="li" 
                      { ...(todayFlag ? todayProps : dividerProps) } 
                      { ...(todayFlag ? {ref: todayRef} : {}) } // Scroll to
                    >
                      {todayFlag ? t("global.nowEventSplit") : ""}
                    </Divider>
                  }
                
                  <EventSelectionItem 
                    key={"eItem"+i} 
                    obj={obj} 
                    isPast={isPast}
                    onClick={handleEventClick}
                    selectedFlag={selectedEvent === obj.id}
                  />

                </Fragment>
              })}

            </List>

          </Paper>

        </>}

        {(zipFileURL !== "") && <Link href={zipFileURL} download={zipRecommendedName} ref={downloadRef} sx={{ position: 'absolute', top: "-100%", left: "-100%", zIndex: "-9" }}>Download Schedule</Link>}

      </>}
    {/* </StandardContentPage> */}

      </Container>
      <Footer />
    </>
  );

}
export default Download;