import { createContext, useReducer } from 'react';
import _ from 'lodash';
import { getAccountID, getApplicationID } from '../services/application.service';


const SCHEDULE_CONTEXT_ACTION_TYPE = Object.freeze({
  UPDATE_TRIGGER: 0,
  CLEAR_TRIGGER_CTX: 1,

  // ID Related
  UPDATE_ACC_ID     : 10,
  UDPATE_APP_ID     : 11,
  UPDATE_EVENT_ID   : 12,
  UPDATE_RESOURCE_ID: 13,
  UPDATE_SCREEN_ID  : 14,

  // Screen LOGO Preview Related
  SET_PREVIEW_LOGO: 30,
  DEL_PREVIEW_LOGO: 39,

  SET_DOWNLOADING: 80,
  SET_DOWNLOADED : 81,

  // Reset
  RESET_ALL : 99,
})

const initialState = Object.freeze({
  // Flags or Shorthands
  hasChanges: false,
  isLoading: false,

  // Actual Data
  accountIDCtx    : getAccountID() ?? '',
  applicationIDCtx: getApplicationID() ?? '',
  eventIDCtx      : '',
  resourceIDCtx   : '',
  screenIDCtx     : '',
  assetIDCtx      : '',

  // Logo for Screen Preview ONLY
  logoPreviewCtx: {},

  // Fulfilment Data
  screenTriggersCtx  : {}  // multiple triggerID: { multimedia with Slots array }
});

const scheduleReducer = (state, action) => {

  // Helpers
  const emptyCTX = () => initialState;
  const updateTrigger = (newTriggerData) => {  
    
    const clone = {...state.screenTriggersCtx};
    Object.keys(newTriggerData).forEach(k => {
      if (clone[k]) {
        clone[k].multimedia = newTriggerData[k].multimedia;
      } else {
        clone[k] = newTriggerData[k];
      }
    });

    const result = { 
      ...state,
      ...{ 
        screenTriggersCtx: clone,
        hasChanges: true 
      },
    };

    return result;
  };
  

  // Straight Update method should be keep for short-term use!
  // - While the usage of this context is still a bit fussy
  if ( ! _.hasIn(action, 'type') ) {
    
    // Guard
    if ('accountIDCtx' in action && action.accountIDCtx === null) {
      (process.env.NODE_ENV === "development") && console.warn("Overwrite CTX", action);
      throw new Error("Overwrite accID with null value");
    }
    if ('applicationIDCtx' in action && action.applicationIDCtx === null) {
      (process.env.NODE_ENV === "development") && console.warn("Overwrite CTX", action);
      throw new Error("Overwrite appID with null value");
    }

    // contiune overwrite
    return { ...state, ...action };
  }
  // Straight Update End ----------

        
  // Action Command based
  switch (action.type) {

    case SCHEDULE_CONTEXT_ACTION_TYPE.UPDATE_TRIGGER:
      // Guard
      const payload = action.payload ?? {};
      if (payload === null) return new Error("[CTX-5PL] Schedule Payload is missing");
      // Update
      return updateTrigger(payload);

    // Reset All
    case SCHEDULE_CONTEXT_ACTION_TYPE.RESET_ALL:
      return emptyCTX();


    // Set Application ID & clear following
    case SCHEDULE_CONTEXT_ACTION_TYPE.UPDATE_ACC_ID:
      // Guard
      if ( ! _.hasIn(action, "payload")) return new Error("[CTX-5cID] Account ID is missing");
      // Update
      return {
        ...emptyCTX(),
        ...{
          accountIDCtx: action.payload,
        }
      }


    // Set Application ID & clear following
    case SCHEDULE_CONTEXT_ACTION_TYPE.UDPATE_APP_ID:
      // Guard
      if ( ! _.hasIn(action, "payload")) return new Error("[CTX-5aID] Application ID is missing");
      // Update
      return {
        ...emptyCTX(),
        ...{
          accountIDCtx    : state.accountIDCtx,
          applicationIDCtx: action.payload,
        }
      }


    // Set Event ID & clear following
    case SCHEDULE_CONTEXT_ACTION_TYPE.UPDATE_EVENT_ID:
      // Guard
      if ( ! _.hasIn(action, "payload")) return new Error("[CTX-5eID] Event ID is missing");
      // Update
      return {
        ...emptyCTX(),
        ...{
          accountIDCtx    : state.accountIDCtx,
          applicationIDCtx: state.applicationIDCtx,
          eventIDCtx      : action.payload,
        }
      }


    // Set Driver ID (resource ID) & clear following
    case SCHEDULE_CONTEXT_ACTION_TYPE.UPDATE_RESOURCE_ID:
      // Guard
      if ( ! _.hasIn(action, "payload")) return new Error("[CTX-5rID] Resource ID is missing");
      // Update
      return {
        ...emptyCTX(),
        ...{
          accountIDCtx    : state.accountIDCtx,
          applicationIDCtx: state.applicationIDCtx,
          eventIDCtx      : state.eventIDCtx,
          resourceIDCtx   : action.payload,
        }
      }


    // Set Screen ID 
    case SCHEDULE_CONTEXT_ACTION_TYPE.UPDATE_SCREEN_ID:
      // Guard
      if ( ! _.hasIn(action, "payload")) return new Error("[CTX-5sID] Screen ID is missing");
      // Update
      return {
        ...emptyCTX(),
        ...{
          accountIDCtx    : state.accountIDCtx,
          applicationIDCtx: state.applicationIDCtx,
          eventIDCtx      : state.eventIDCtx,
          resourceIDCtx   : state.resourceIDCtx,
          screenIDCtx     : action.payload.screenIDCtx,
          assetIDCtx      : action.payload.assetIDCtx,
        }
      }


    // Set Logo Preview
    case SCHEDULE_CONTEXT_ACTION_TYPE.SET_PREVIEW_LOGO:
      // Guard
      if ( ! _.hasIn(action, "payload")) return new Error('[CTX-5LP] Preview Logo is missing');
      // Update
      const lpc = _.cloneDeep(state.logoPreviewCtx);
      lpc[action.payload.assetID] = action.payload.logo;
      return {
        ...state,
        ...{
          logoPreviewCtx: lpc
        }
      }

    // Reset Preview Logo
    case SCHEDULE_CONTEXT_ACTION_TYPE.DEL_PREVIEW_LOGO:
      return {
        ...state,
        ...{
          logoPreviewCtx: {}
        }
      }

    case SCHEDULE_CONTEXT_ACTION_TYPE.CLEAR_TRIGGER_CTX:
      return {
        ...state,
        ...{ 
          screenTriggersCtx: {},
          hasChanges: false
        }
      };

    case SCHEDULE_CONTEXT_ACTION_TYPE.SET_DOWNLOADING:
      return {
        ...state,
        ...{ isLoading: true }
      };

    case SCHEDULE_CONTEXT_ACTION_TYPE.SET_DOWNLOADED:
      return {
        ...state,
        ...{ isLoading: false }
      }

    // --------------------------------
    // Unknown action.type, nothing change
    default:
      process.env.NODE_ENV === "development" && console.error(`[CTX-CMD] Unknown Schedule Reducer Command "${action.type}"`);
      return state;
  }
      
  
  
}

const ScheduleContext = createContext();

const ScheduleContextProvider = ({ children }) => {
  const [scheduleState, scheduleDispatch] = useReducer(scheduleReducer, initialState);

  return (
    <ScheduleContext.Provider value={{ scheduleState, scheduleDispatch }}>
      {children}
    </ScheduleContext.Provider>
  )
}


export { ScheduleContext, ScheduleContextProvider, SCHEDULE_CONTEXT_ACTION_TYPE };