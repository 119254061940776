import { useContext, useEffect } from "react";
import { navigate } from "@reach/router";
import { ScheduleContext, SCHEDULE_CONTEXT_ACTION_TYPE } from "../context/ScheduleContext";
import { performLogout } from "../services/user.service";
import { SITE_MAP_URL } from "../constant";
import api, { API_URL } from "../services/api";


const Logout = () => {

  const { scheduleDispatch } = useContext(ScheduleContext);

  useEffect(() => {

    // Reset Schedule Context
    scheduleDispatch({ type: SCHEDULE_CONTEXT_ACTION_TYPE.RESET_ALL });
  
    // Reset Local & Session storage
    performLogout();
  
    api.get(API_URL.LOGOUT)
      .finally(() => {
        navigate(SITE_MAP_URL.LOGIN)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  })


  return <></>;
};
export default Logout;