import { useRef, useState, useEffect, useCallback } from 'react';
// library
import { useTranslation } from 'react-i18next';
// mui
import TextField from '@mui/material/TextField';


const LoginEmailInput = ({ defaultValTxt, onChangeValidation }) => {

  const [inputError, setInputError] = useState("");
  const inputRef = useRef();
  
  const { t } = useTranslation();

  
  const inputValidation = useCallback(() => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const emailTxt = inputRef.current.value ?? "";

    if (emailTxt.length === 0) {
      setInputError( t("loginPage.error.emailReq") );
      onChangeValidation && onChangeValidation([false, emailTxt]);
    } else if (!emailRegex.test(emailTxt)) {
      setInputError( t("loginPage.error.emailNotAddr") );
      onChangeValidation && onChangeValidation([false, emailTxt]);
    } else {
      setInputError("");
      onChangeValidation && onChangeValidation([true, emailTxt]);
    }
  }, [inputRef, t, onChangeValidation]);


  // One time, set default value
  useEffect(() => { 
    if (defaultValTxt && defaultValTxt.length > 0 && inputRef.current) {
      inputRef.current.value = defaultValTxt;
      inputValidation();
    }
  }, [inputRef]);   // eslint-disable-line react-hooks/exhaustive-deps


  return <TextField
    inputRef={inputRef}
    type="email"
    name="email"
    required
    fullWidth
    label={t('loginPage.loginInputLabel')}
    margin="normal"
    autoFocus
    error={inputError !== ""}
    helperText={inputError}
    onChange={inputValidation}
  />

};
export default LoginEmailInput;