import { useState } from 'react';
import ImageSelectionDialog from '../../../../../components/Logo/ImageSelectionDialog';
// Lubrary
import { useTranslation } from 'react-i18next';
// mui
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors'


const AddImageCard = (props) => {

  const { t } = useTranslation();
  const [openImgDialog, setOpenImgDialog] = useState(false);

  let dialogOption = {};
  if (props.selectionQuota) {
    dialogOption = {...dialogOption, 'selectionQuota': props.selectionQuota };
  } 

  const performAddImage = (imgList) => {
    // console.log('Add Img', imgList);
    props.performAdd && props.performAdd(imgList)
  }

  return <>
    <Button 
      aria-label={t("global.add") + " " + t("global.image")}
      sx={{ 
        width: '150px', 
        height: '150px', 
        borderRadius: 3, 
        margin: "0 10px",
        border: `dashed 4px ${grey[300]}`
      }}
      onClick={e => setOpenImgDialog(true)}
    >
      <AddPhotoAlternateIcon sx={{ fontSize: '3rem' }} />
    </Button>
    
    <ImageSelectionDialog
      open={openImgDialog}
      dialogOption={dialogOption}
      closeAction={e => setOpenImgDialog(false)}
      okAction={imgList => performAddImage(imgList)}
    />

  </>
};
export default AddImageCard;