import { useRef, useState, useEffect, useMemo } from 'react';
import { setRememberMe, shouldRememberMe, isValidToken } from "../../services/token.service";
import { performLogin } from "../../services/user.service";
import ForgetPwdDialog from './ForgetPwdDialog';
import LoginEmailInput from './component/LoginEmailInput';
import LoginPwdInput from './component/LoginPwdInput';
// Library
import { useTranslation } from "react-i18next";
import _ from 'lodash';

// mui
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
// icon
import KeyIcon from '@mui/icons-material/Key';


export const LoginFormPanel = ({ onLoginSuccess, allowLogoutFn }) => {

  const formRef  = useRef(null);
  // Email
  const [emailAddr, setEmailAddr]   = useState(process.env.REACT_APP_LOGIN_EMAIL);
  const [emailValid, setEmailValid] = useState(false);
  // Pwd
  const [pwdTxt, setPwdTxt]         = useState(process.env.REACT_APP_LOGIN_PWD);
  const [pwdValid, setPwdValid]     = useState(false);
  // Forget Password
  const [forgetPwdOpen, setForgetPwdOpen]           = useState(false);

  const [submitErrorMsg, setSubmitErrorMsg]         = useState();
  const [loadingFlag, setLoadingFlag]               = useState(false);

  const formValidationFlag = useMemo(() => (emailValid && pwdValid), [emailValid, pwdValid]);

  const { t } = useTranslation();  

  
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formValidationFlag) return false;

    setLoadingFlag(true);

    performLogin(emailAddr, pwdTxt, true)
      .then(
        () => onLoginSuccess && onLoginSuccess(),
        e => {
          setLoadingFlag(false);
          
          //console.error('err', e)
          if (_.isArray(e)) {
            const errMsg = e.map(c => t(`commonError.${c}`))
  
            setSubmitErrorMsg( errMsg.join('\n- ') );
          } else {
            setSubmitErrorMsg( t(`loginPage.error.UnknownError`) );
          }
        } // catch
      )   // then

  }
 
  const onEmailChangeValidation = ([validFlag, emailAddrTxt]) => {
    setEmailAddr(emailAddrTxt);
    setEmailValid(validFlag);
  }

  const onPwdChangeValidation = ([validFlag, pwdTxt]) => {
    setPwdTxt(pwdTxt);
    setPwdValid(validFlag);
  }


  // One off, check if the user is 'remembered' and the token is still valid
  useEffect(() => {
    isValidToken() && onLoginSuccess && onLoginSuccess();
  }, [onLoginSuccess]);


  return (<>
    <Box>
      <form
        ref={formRef}
        noValidate
        onSubmit={handleSubmit}
      >
        <LoginEmailInput defaultValTxt={emailAddr} onChangeValidation={onEmailChangeValidation} />
        <LoginPwdInput   defaultValTxt={pwdTxt}    onChangeValidation={onPwdChangeValidation} />

        {false && <FormControlLabel
          control={<Checkbox value="remember" color="primary" defaultChecked={shouldRememberMe()} />}
          label={t('loginPage.RememberMe')}
          onChange={e => setRememberMe(e.target.checked)}
        />}

        <LoadingButton
          type="submit"
          loading={loadingFlag}
          loadingPosition="start"
          startIcon={<KeyIcon />}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={(loadingFlag || !formValidationFlag)}
        >
          {t('loginPage.Login')}
        </LoadingButton>

        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button variant="text" onClick={() => !forgetPwdOpen && setForgetPwdOpen(true)}>
              {t('loginPage.ForgetPwd')}
            </Button>
          </Grid>
          {allowLogoutFn && <Grid item>
            <Button variant="text" color="error" onClick={allowLogoutFn}>
              {t("loginPage.Logout")}
            </Button>
          </Grid>
          }
        </Grid>

      </form>
    </Box>

    <Box sx={{ mt:1, width: '100%' }}>
      {(submitErrorMsg) && <Alert severity="error" sx={{ marginTop: 2 }}>
        {submitErrorMsg}
      </Alert>}
    </Box>

    <ForgetPwdDialog 
      open={forgetPwdOpen}
      onClose={() => setForgetPwdOpen(false)}
      emailAddrTxt={emailAddr}  
    />

  </>
  );

}