import { useEffect, useState } from 'react';
import { getResourcesList } from '../../../../services/application.service';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
// MUI
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';


const ResourceSelectInput = (props) => {

  const { resourceOption, resourceList, ...p } = props;

  const { t } = useTranslation();
  const [rList, setRList] = useState(resourceList ?? [])
  const [selectedResource, setSelectedResource] = useState("all")

  useEffect(() => {
    let rl = getResourcesList();
    rl = rl.map(r => _.omit(r, ["assets"]))
    setRList(rl)
    // console.log(rl)
  }, [])
  
  useEffect(() => {
    setSelectedResource(resourceOption.value ?? "all")
  }, [resourceOption.value])


  return (
  <FormControl {...p}>
    <InputLabel id={resourceOption.inputID+"-label"}>{resourceOption.inputLabel}</InputLabel>
    <Select
      labelId={resourceOption.inputID+"-label"}
      label={resourceOption.inputLabel}
      id={resourceOption.inputID+"-select"}
      value={(rList.length === 0) ? "" : selectedResource}
      onChange={(e) => { resourceOption.handleChange && resourceOption.handleChange(e.target.value, resourceOption.inputID)}}
    >
      <MenuItem value="all" key="all"
        disabled={((resourceOption.disabledItems ?? []).includes("all"))}
      >{t("cloneFulfilment.toAllResource")}</MenuItem>

      {rList.map((d) => 
        <MenuItem value={d.id} key={d.id} 
          disabled={((resourceOption.disabledItems ?? []).includes(d.id))}
        >
          <Stack direction='row'>
            <Box sx={{ minWidth: 30 }}>{d.alias}.</Box>
            <Box>{d.firstName} {d.lastName}</Box>
          </Stack>
        </MenuItem>
      )}
    </Select>
    {p.error === true && <FormHelperText>{t("cloneFulfilment.error.allToAll")}</FormHelperText>}
  </FormControl>  
  );
}
export default ResourceSelectInput;