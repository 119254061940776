import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneFulfilment } from '../../../services/schedule.service';
import EventSelectInput from './input/EventSelectInput';
import ResourceSelectInput from './input/DriverSelectInput';
import PropTypes from 'prop-types';
// mui
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
// icon
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';

const CloneEventSelectionForm = (props) => {

  const { displayDirection, cloneMode, cloneOnce, destinationEventID, sourceEventID, destinationResourceID, sourceResourceID, exitFormFn, shouldReloadData, ...p } = props;

  const { t } = useTranslation();

  // Dropdown value
  const [userSelectedFromEvent, setUserSelectedFromEvent]       = useState(sourceEventID ?? "");
  const [userSelectedToEvent,   setUserSelectedToEvent]         = useState(destinationEventID ?? "");
  const [userSelectedFromResource, setUserSelectedFromResource] = useState(sourceResourceID ?? "all");
  const [userSelectedToResource,   setUserSelectedToResource]   = useState(destinationResourceID ?? "all");
  
  // Flow control
  const [cloneButtonEnable, setCloneButtonEnable] = useState(false);
  const [cloneInProgress, setCloneInProgress]     = useState(false);
  const [cloneResult, setCloneResult]             = useState(null);

  const handleCloneOnClick = () => {
    // Reset
    setCloneInProgress(true);
    setCloneResult(null);

    // Clone
    cloneFulfilment(
      userSelectedFromEvent, 
      userSelectedToEvent,
      (userSelectedFromResource !== 'all' && userSelectedToResource !== 'all') ? userSelectedFromResource : null,
      (userSelectedFromResource !== 'all' && userSelectedToResource !== 'all') ? userSelectedToResource   : null,
    )
    .then(
      r => { 
        setCloneResult(true); 
        setCloneInProgress(false);
        setCloneButtonEnable(!(cloneMode === "eventClone" || cloneMode === undefined))
      },
      e => setCloneResult(false)
    )
  }

  // Clone button enable/disable
  useEffect(() => {

    const validation = () => {
      // Both event
      if (userSelectedFromEvent === "" || userSelectedToEvent === "") {
        setCloneButtonEnable(false)
        return
      }

      if (cloneMode === "resourceClone") {
        setCloneButtonEnable((userSelectedFromResource === 'all' && userSelectedToResource === 'all')
          || (userSelectedFromResource !== 'all' && userSelectedToResource !== 'all')) 
        return 
      }

      setCloneButtonEnable(true)
    }
    validation()

  }, [cloneMode, userSelectedFromEvent, userSelectedFromResource, userSelectedToEvent, userSelectedToResource])

  return (
    <Stack 
      direction={displayDirection ?? "column"} 
      alignItems={(displayDirection === "row" ? "center" : "stretch")} 
      spacing={(displayDirection === "row" ? 4 : 2)}
      gap={ cloneMode === "resourceClone" ? 0 : 1.5} 
      sx={{ flexGrow: 1 }}
      {...p}
    >

      {/* From */}
      <Stack direction="row" gap={2} flexWrap="wrap" 
        sx={(cloneMode === "resourceClone") ? { border: 1, px: 2, pb: 2, borderColor: 'grey.300', borderRadius: 1, width: 1 } : {}}
      >
        {cloneMode === "resourceClone" && 
          <Box sx={{ mt: -1.5, width: 1 }}>
            <Typography component="h6" fontWeight='bold' sx={{ width: 'fit-content', bgcolor: 'white', px: 1, textTransform: 'capitalize', color: 'text.secondary' }}>{t("cloneFulfilment.from")}</Typography>
          </Box>
        }

        <EventSelectInput 
          eventOption={{
            inputLabel: t("cloneFulfilment.fromEvent"),
            inputID: "cloneFromEvent",
            markCurrentItem: destinationEventID,
            value: userSelectedFromEvent,
            handleChange: val => setUserSelectedFromEvent(val),
          }}
          eventMode={"completed"}
          size="small"
          sx={{ width: 1, maxWidth: 'sm' }}
        />

        {cloneMode === "resourceClone" && 
          <ResourceSelectInput 
            resourceOption={{
              inputLabel: t("cloneFulfilment.fromResource"),
              inputID: "cloneFromResource",
              // disabledItems: (userSelectedToResource === 'all') ? null : ['all'],
              value: userSelectedFromResource,
              handleChange: val => setUserSelectedFromResource(val),
            }}
            sx={{ width: 1, maxWidth: 350 }} 
            size="small"
            error={( userSelectedFromResource === 'all' && userSelectedToResource !== 'all' )}
          />
        }
      </Stack>

      {/* Arrow */}
      {cloneMode === "resourceClone" && 
        (displayDirection === "row" ? <ArrowForwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon sx={{ alignSelf: 'center' }}/>)
      }

      {/* To */}
      <Stack direction="row" gap={2} flexWrap="wrap" 
        sx={(cloneMode === "resourceClone") ? { border: 1, px: 2, pb:2, borderColor: 'grey.300', borderRadius: 1, width: 1 }:{}}
      >
        {cloneMode === "resourceClone" && 
          <Box sx={{ mt: -1.5, width: 1 }}>
            <Typography component="h6" fontWeight='bold' sx={{ width: 'fit-content', bgcolor: 'white', px: 1, textTransform: 'capitalize', color: 'text.secondary' }}>{t("cloneFulfilment.to")}</Typography>
          </Box>
        }
        
        <EventSelectInput
          eventOption={{
            disabled: true,
            inputLabel: t("cloneFulfilment.toEvent"),
            inputID: "cloneToEvent",
            value: userSelectedToEvent,
            handleChange: val => setUserSelectedToEvent(val)
          }}
          eventMode={"future"}
          size="small"
          sx={{ width: 1, maxWidth: 'sm' }}
        />

        {cloneMode === "resourceClone" && 
          <ResourceSelectInput 
            resourceOption={{
              inputLabel: t("cloneFulfilment.toResource"),
              inputID: "cloneToResource",
              // disabledItems: (userSelectedFromResource === 'all') ? null : ['all'],
              value: userSelectedToResource,
              handleChange: val => setUserSelectedToResource(val),
            }}
            sx={{ width: 1, maxWidth: 350 }} 
            size="small"
            error={( userSelectedToResource === 'all' && userSelectedFromResource !== 'all' )}
          />
        }
      </Stack>

      {cloneResult !== null && 
        <Stack direction={'row-reverse'}>
          {cloneResult === false && <Alert severity="error">{t("cloneFulfilment.cloneFailed.msg")}</Alert>}
          {cloneResult === true  && <Alert severity='success'>{t("cloneFulfilment.cloneCompleted.msg")}</Alert>}
        </Stack>
      }

      <Stack direction={'row-reverse'} justifyContent={'space-between'} gap={1}>
        <LoadingButton 
          loading={cloneInProgress}
          disabled={!cloneButtonEnable || cloneInProgress}
          onClick={handleCloneOnClick}
          color={"primary"}
          variant='contained'
          // sx={{ textTransform: 'capitalize' }}
        >{t("global.clone")}</LoadingButton>

        <Button
          onClick={() => { 
            exitFormFn && exitFormFn();
            cloneResult && shouldReloadData();
          }}
        >{t("global.exit")}</Button>
      </Stack>

    </Stack>
  )

}

CloneEventSelectionForm.propTypes = {
 
  cloneMode            : PropTypes.string,  // "eventClone", "resourceClone"
  sourceEventID        : PropTypes.string,  // Optional
  sourceResourceID     : PropTypes.string,  // Optional
  destinationEventID   : PropTypes.string,
  destinationResourceID: PropTypes.string,  // Optional

  // Flow Control
  cloneOnce: PropTypes.bool,  // Clone button remain disable after successfully clone

  // UI
  displayDirection: PropTypes.string,   // Stack (flex) direction: "row", "column"...

  // Delegate
  exitFormFn      : PropTypes.func,
  shouldReloadData: PropTypes.func,
}

export default CloneEventSelectionForm;