import { useRef, useState, useEffect, useCallback } from 'react';
// library
import { useTranslation } from 'react-i18next';
// mui
import TextField from '@mui/material/TextField';


const LoginPwdInput = ({ defaultValTxt, onChangeValidation, toMatch, labelTxt, exam, resetInputFlag }) => {

  const [inputError, setInputError] = useState("");
  const inputRef = useRef();
  
  const { t } = useTranslation();


  const inputValidation = useCallback(() => {
    const pwdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$£^/:!%*?&])[A-Za-z\d#@$£^/:!%*?&]{8,30}$/g;
    const pwdTxt = inputRef.current.value ?? "";

    // Required
    if (pwdTxt.length === 0) {
      setInputError( t("loginPage.error.pwdReq") );
      onChangeValidation && onChangeValidation([false, pwdTxt]);

    // Password Again is matching 
    } else if (toMatch && toMatch.length > 0 && toMatch !== pwdTxt) {
      setInputError( t("loginPage.error.pwdNotMatch") );
      onChangeValidation && onChangeValidation([false, pwdTxt]);
    
    // Password fulfilling all the rules
    } else if (exam === true && !pwdRegex.test(pwdTxt)) {
      setInputError( t("loginPage.error.pwdExamFail") );
      onChangeValidation && onChangeValidation([false, pwdTxt]);

    // All pass
    } else {
      setInputError("");
      onChangeValidation && onChangeValidation([true, pwdTxt]);
    }

  }, [inputRef, t, onChangeValidation, toMatch, exam]);


  // One time, set default value
  useEffect(() => {
    if (defaultValTxt && defaultValTxt.length > 0) {
      inputRef.current.value = defaultValTxt;
      inputValidation();
    }
  }, [inputRef]);   // eslint-disable-line react-hooks/exhaustive-deps

  // As requested by parent, reset input value and error and style
  useEffect(() => {
    if (resetInputFlag) {
      inputRef.current.value = "";
      setInputError("");
    }
  }, [resetInputFlag]);

  useEffect(() => {
    // Additional validation when both password has some value
    let shouldValidate = (toMatch ?? "").length > 0 && ((inputRef.current.value ?? "").length ?? 0) > 0

    if (shouldValidate) {
      inputValidation()
    }

  // inputRef changes will be handled by 'onChange', so it is not necessary to include in [deps]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toMatch])    


  return <TextField
    inputRef={inputRef}
    type="password"
    name="password"
    required
    fullWidth
    label={labelTxt ?? t('loginPage.pwdInputLabel')}
    margin="normal"
    error={inputError !== ""}
    helperText={inputError}
    onChange={inputValidation}
  />
};
export default LoginPwdInput;