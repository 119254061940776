import { createContext, useState } from 'react';

const PreviewImageContext = createContext([{}, ()=>{}]);

const PreviewImageProvider = ({ children }) => {
  const [previewImageState, setPreviewImageState] = useState({
    currentScreenID: undefined,
    screenImg: {},
  });

  return (
    <PreviewImageContext.Provider value={[previewImageState, setPreviewImageState]}>
      {children}
    </PreviewImageContext.Provider>
  );
}

export { PreviewImageContext, PreviewImageProvider};