import DriverSelectList from './DriverSelectList';
// MUI
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { grey } from '@mui/material/colors';
// Library
import { useTranslation } from 'react-i18next';


const DriverList = (props) => {
  
  const { t } = useTranslation();

  return (
    <Paper elevation={2}>
      <Typography component='h6' variant="subtitle2" pl={2} py={1} sx={{ borderBottom: `solid 1px ${grey[300]}`}}>
        {t("schedulePage.driverSectionTitle")}
      </Typography>
      <DriverSelectList updateSelectedDriver={props.onDriverChange} onPageMetaChange={props.onPageMetaChange} />
    </Paper>
  );
};
export default DriverList;