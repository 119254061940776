import { useEffect, useState } from 'react';
import moment from 'moment';
import { getEventWithCompletedSchedule, getUpcomingEvent } from '../../../../services/application.service';
import { formatEventDateRange } from '../../../../common/utils';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
// MUI
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';


const EventSelectInput = (props) => {

  const { eventOption, eventList, eventMode, ...p } = props;
  const { t } = useTranslation();

  const [separatorIndex, setSeparatorIndex] = useState(-1);
  const [eventModeList, setEventModeList] = useState(eventList ?? []);

  const calcSeparatorIndex = (eList) => {
    const today = moment.utc();
    const endDates = eList.map(e => e.endBaseDate.utc)
    let sepOnIdx = _.findIndex(endDates, e => today.isBefore(e));
    return sepOnIdx;
  }

  useEffect(() => {

    const prepareData = async () => {

      let eList = []

      switch (eventMode) {
        case "future":
          setSeparatorIndex(-1);  // Disable separator
          eList = await getUpcomingEvent();
          break;
      
        case "completed":
          // Today separator
          eList = await getEventWithCompletedSchedule();
          break;

        default:
          // eList = eventList ?? []
          break;
      }

      eList = eList.map(e => _.omit(e, ["conditions"]));  // Slim the size
      eList = _.orderBy(eList, ['startBaseDate.utc'], ['asc'])
      
      if (eventMode === "future") {
      } else {
        const idx = calcSeparatorIndex(eList)
        setSeparatorIndex( idx )
      }

      setEventModeList(eList)

    }
    (eventModeList.length === 0) && prepareData();
    
    // Default selection
    // console.log(eventOption.value, eventOption.inputID, eList.map(e => `${e.id} ${e.title}`))
    // setSelectedEvent(eventOption.value ?? "")

  }, [eventList, eventMode, eventModeList.length, eventOption.value])


  return (
  <FormControl {...p}>
    <InputLabel id={eventOption.inputID+"-label"}>{eventOption.inputLabel}</InputLabel>
    <Select
      disabled={eventOption.disabled}
      label={eventOption.inputLabel}
      labelId={eventOption.inputID+"-label"}
      id={eventOption.inputID+"-select"}
      // value={eventModeList.length === 0 ? "" : selectedEvent}
      value={(eventModeList.length === 0) ? "" : eventOption.value ?? ""}
      onChange={(e) => { /*setSelectedEvent(e.target.value);*/ eventOption.handleChange && eventOption.handleChange(e.target.value, eventOption.inputID) }}
    >

      {((separatorIndex > -1) 
        ? [
          _.take(eventModeList, separatorIndex), // Past events
          { id: null }, // Today separator
          _.takeRight(eventModeList, Math.max(0, (eventModeList.length - separatorIndex)) )  // Future events
        ].flat()
        : eventModeList
      )
      .map((e, i) => (
        (e.id === null)
        ? <Divider key="nowSplit" textAlign='left' sx={{ ":before, :after": { borderTopColor: 'gray', borderTopWidth: '2px' }, color: "gray", fontWeight: 'bold' }}>{t("global.nowEventSplit")}</Divider>
        : <MenuItem value={e.id} key={e.id}
          disabled={(eventOption.disabledItems ?? []).includes(e.id)}
        >
          <Stack direction='row' alignItems="center" justifyContent="space-between" flexGrow={1} spacing={2}>
            <Box sx={{ minWidth: 150, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} flexShrink={1}>{eventOption.markCurrentItem === e.id ? <span>{t("cloneFulfilment.currentMarker")}&nbsp;</span> : <></>}{e.title}, {e.description}</Box>
            <Box fontSize="0.85rem">{formatEventDateRange(e.startBaseDate.utc, e.endBaseDate.utc)}</Box>
          </Stack>
        </MenuItem>
      ))}

    </Select>
  </FormControl>
  );
}

EventSelectInput.propTypes = {
  // Selection/Dropdown
  eventOption: PropTypes.shape({
    inputLabel  : PropTypes.string,
    inputID     : PropTypes.string,
    value       : PropTypes.string,
    // Interaction
    handleChange: PropTypes.func,
    // Control flag
    disabled     : PropTypes.bool,
    disabledItems: PropTypes.array,
    markCurrentItem: PropTypes.string,
  }),
  // Either data list has been provided, or the components needs to be gather it by itself
  eventList: PropTypes.array,
  eventMode: PropTypes.string,  // "future": future events; "completed": Event with Completed Fulfilment
}

export default EventSelectInput;