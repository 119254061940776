import { useState, useRef, useEffect } from 'react';
import ScenarioCardFrame from './ScenarioCardFrame';
// Library
import { useTranslation } from 'react-i18next';
// mui
import Stack from '@mui/material/Stack';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { red } from '@mui/material/colors'




const SecondWithSequenceCard = (props) => {

  const { triggerDic, logo, isReadOnly } = props;

  const [errorFlag, setErrorFlag] = useState(false);
  const inputEl = useRef(null);

  const { t } = useTranslation();

  // Data Handling
  const onValueChanged = () => props.onValueChanged && props.onValueChanged(
    props.idx, 
    inputEl.current.value ? inputEl.current.value : 0
  );

  const onDel = () => props.performDel && props.performDel(logo.uuid);

  // Validation
  const inputValidation = () => {
    const val = inputEl.current.value;

    const hasError = (
      val == null 
        || val < triggerDic.seconds.minimum 
        || val > triggerDic.seconds.maximum
    );
    
    setErrorFlag( hasError );

    return !hasError;
  }

  
  useEffect(() => {
    if ( logo.slots[0].duration > 0 ) {
      inputEl.current.value = logo.slots[0].duration;
    }

    inputValidation();
  })

  return (
    <ScenarioCardFrame logo ={logo} alt={``} onDel={onDel} isReadOnly={isReadOnly}>

      <Stack direction="row" alignItems='center' spacing={1}>
        <label htmlFor={`${triggerDic.id}-inVal-${props.idx}`}>{props.idx}.</label>
        <Input
          disabled={isReadOnly}
          inputRef={inputEl}
          id={`${triggerDic.id}-inVal-${props.idx}`} 
          type="number"
          placeholder={`${triggerDic.seconds.minimum}+`}
          fullWidth
          required
          size="small"
          label="second"
          error={errorFlag}
          variant={errorFlag?"filled":"standard"}
          endAdornment={<InputAdornment position="end">{t("global.sec")}</InputAdornment>}
          inputProps={{ 
            'aria-label': 'display second',
            min: triggerDic.seconds.minimum, max: triggerDic.seconds.maximum, 
            maxLength: triggerDic.seconds.maximum.length,
            // value: inputSec
          }} 
          onChange={onValueChanged}
          sx={{ 
            backgroundColor: errorFlag ? red[200] : 'none',
            // color: errorFlag ? grey[100] : 'currentColor',
            paddingLeft: 0.5,
          }}
        />
      </Stack>

    </ScenarioCardFrame>
  );
}
export default SecondWithSequenceCard;