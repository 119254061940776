import { useContext, useMemo } from 'react';
import ScenarioFrame, { EnumScenarioStatus } from './ScenarioFrame';
import FixedRangeCard from '../Cards/FixedRangeCard';
import AddImageCard from '../Cards/AddImageCard';
import { ScheduleContext, SCHEDULE_CONTEXT_ACTION_TYPE } from '../../../../../context/ScheduleContext';
import { FixedRange_hasEmptySlot, FixedRange_OverlapSlot, FixedRange_EmptySlotCount } from '../../../../../parser/ScenarioHelper';
import { fixedScenarioValidator } from '../../../../../parser/ScenarioParser';
// Library
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
// MUI
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import { SCHEDULE_STATUS } from '../../../../../constant';


const FixedRangeScenario = (props) => {

  const { triggerDic: { triggerId, instances: { maximum }}, isReadOnly } = props;

  // Schedule Context
  const { scheduleState, scheduleDispatch } = useContext(ScheduleContext);

  // Data Shorthand
  const itemList = useMemo(() => scheduleState.screenTriggersCtx[triggerId]?.multimedia ?? [], [scheduleState.screenTriggersCtx, triggerId]);
  const setItemList = (newList) => {
    const p = {};
    p[triggerId] = { id: triggerId, multimedia: newList };

    scheduleDispatch({ 
      type: SCHEDULE_CONTEXT_ACTION_TYPE.UPDATE_TRIGGER,
      payload: p
    });
  };

  // Libraries
  const { t } = useTranslation();


  const onValueChanged = (changes, idx) => {

    let newItem = [...itemList];
    newItem[idx[0]].slots[idx[1]].start = changes.slots[0].start;
    newItem[idx[0]].slots[idx[1]].end   = changes.slots[0].end;
    setItemList(newItem);

    // console.log('onValueChanged: ', changes, idx, newItem);
  }

  const performDel = (idx) => {

    if (!_.isArray(idx) || idx.length !== 2 || idx.some(i => !_.isInteger(i))) return false;

    let newList = Object.values(itemList);

    const slotsCnt = newList[idx[0]].slots.length;

    if (slotsCnt <= 1 && idx[1] === 0) { 
      // Since it has single slots, so DEL the image obj
      newList = newList.filter((o,i) => i !== idx[0]);
      process.env.NODE_ENV === "development" && console.log("Perform Del[ ,0]", idx, itemList, newList);
    } else {
      // Delete one Slots ONLY
      newList[idx[0]].slots = newList[idx[0]].slots.filter((o,i) => i !== idx[1]);  
      process.env.NODE_ENV === "development" && console.log("Perform Del[ , ]", idx, itemList, newList);
    }
    
    setItemList(newList);

    return true;
  } 
  
  const performAdd = (imgs) => {
    
    // Data Guard
    const pendingImg = _.isString(imgs) ? [imgs] : _.isArray(imgs) ? imgs : [];

    // Prepare
    let newList        = _.clone(itemList);

    // Get the last index number at this moment
    const allStart = newList.map(i => i.slots[0].start);
    const allEnd = newList.map(i => i.slots[0].end);
    let theLastIdx = allStart.length === 0 ? 1 : ( _.max([...allStart, ...allEnd]) + 1 );
    // (process.env.NODE_ENV === "development") && console.log( "the last idx", theLastIdx, allStart, allEnd );

    // Add it one by one
    pendingImg.forEach(i => {

      newList.push({
        id: i.id,
        url: i.url,
        uuid: uuidv4(),
        slots: [{
          start   : theLastIdx,
          end     : theLastIdx,
          duration: props.triggerDic.seconds.minimum,
        }]
      });

      theLastIdx++;
    })

    newList = _.sortBy(newList, [i => i.slots[0].start]);
    setItemList(newList);

  }

  const getOverallStatus = useMemo(() => 
    (fixedScenarioValidator(itemList, props.triggerDic) === SCHEDULE_STATUS.COMPLETE) 
      ? EnumScenarioStatus.Neutral 
      : EnumScenarioStatus.Error
    , [itemList, props.triggerDic]
  );

  const getStatusMessage = useMemo(() => {
    
    if (itemList.length === 0) return t("scenario.pleaseAddImg");

    let stateMsg = getOverallStatus === EnumScenarioStatus.Error
                    ? `${t('commonError.errorOccupied')} | ` 
                    : "";
    
    stateMsg += `${itemList.length} ${t(`global.${itemList.length > 1 ? "logos":"logo"}`)}`;

    return stateMsg;

  }, [itemList.length, getOverallStatus, t]);


  // Render ---------------------------
  return (
    <ScenarioFrame
      isReadOnly={isReadOnly}
      triggerDic={props.triggerDic}
      scenarioStatus={getOverallStatus}
      statusMessage={getStatusMessage}
      // statusMeta={null}
      performDelScenario={props.performDelScenario}
    >
      <Grid container columns={5} direction="row" justifyContent="flex-start" alignItems="flex-start"
        sx={{ marginTop: "0 !important" }}
      >
        {itemList.map((item,i) => {

          let { slots, ...mm } = item;
          // TODO: In case, please verify it is necessary
          (slots.length === 0) && slots.push({});
          
          return slots.map((slot,j) => (

            <Grid item 
              // key={`${mm.id}_${j}`}
              key={mm.uuid}
              sx={{ 
                padding: "0 10px 12px !important", 
                flexBasis: 'auto !important',
              }}
            >
              <FixedRangeCard 
                isReadOnly={isReadOnly}
                triggerDic={props.triggerDic}
                logo={{...mm, "slots": _.castArray(slot)}}
                idx={[i,j]}
                onValueChanged={onValueChanged}
                performDel={performDel}
                overlapped={FixedRange_OverlapSlot(itemList, maximum)}
              />
            </Grid>

          ))  // Single Slot
        }     // Slots Loop
        )}    {/* Media List Loop */}

        {!isReadOnly && 
          <Fade in={FixedRange_hasEmptySlot(itemList, maximum)}>
            <Grid item>
              <AddImageCard 
                selectionQuota={FixedRange_EmptySlotCount(itemList, maximum)} 
                performAdd={performAdd} 
              />
            </Grid>
          </Fade>
        }

      </Grid>
    </ScenarioFrame>
  )
}
export default FixedRangeScenario;