import { useContext, useEffect, useMemo } from "react";
import { getResourcesList } from "../../../services/application.service";
import { ScheduleContext } from "../../../context/ScheduleContext";
import { EventDelegatorContext, EVENT_MANAGEMENT_DELEGATOR_NOTIFICATION_TYPE, EVENT_MANAGEMENT_ISSUE } from "../../../context/EventDelegatorContext";
// mui
import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from '@mui/material/Avatar';
// Icon
/* [F1WEB-200] 
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; */
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// Library
import _ from "lodash";


const DriverSelectList = ({ onPageMetaChange, ...props}) => {

  const { scheduleState: { resourceIDCtx } } = useContext(ScheduleContext);
  const { eventDelegatorDispatch }           = useContext(EventDelegatorContext);

  const driverList = useMemo(() => getResourcesList(), []);

  // Interaction
  const handleDriverClick = (e,did) => {
    e.preventDefault();

    props.updateSelectedDriver && props.updateSelectedDriver(did);
  }

  // Validate resourceIDCtx
  useEffect(() => {
    // TODO: [F1WEB-201] Ex-Driver will be consider as 'invalid resourceID', need to handle it with API
    if (driverList.length > 0 && resourceIDCtx !== "") {
      const isValidRID = _.some(driverList, { id: resourceIDCtx });

      eventDelegatorDispatch({
        type: isValidRID 
                ? EVENT_MANAGEMENT_DELEGATOR_NOTIFICATION_TYPE.ERROR_GONE 
                : EVENT_MANAGEMENT_DELEGATOR_NOTIFICATION_TYPE.ERROR_FOUND,
        payload: EVENT_MANAGEMENT_ISSUE.INVALID_RESOURCE_ID
      })

    }
  }, [driverList, resourceIDCtx, eventDelegatorDispatch]);

  // Update page title with driver name
  useEffect(() => {
    const { firstName, lastName } = _.find(driverList, {id: resourceIDCtx}) ?? {};
  
    onPageMetaChange 
      && onPageMetaChange({ driverDisplayName: [firstName, lastName].join(' ').trim() });
  }, [driverList, resourceIDCtx, onPageMetaChange]);


  return (
    <List>
      {driverList.map((d,i) => 
        <ListItem key={d.id} disablePadding>
          <ListItemButton 
            onClick={(e) => handleDriverClick(e, d.id)} 
            selected={(resourceIDCtx === d.id)}
          >

            <ListItemAvatar>
              {d.photo ? 
                <Avatar alt="" src={d.photo} sx={{ width: 50, height: 50, boxShadow: 3, }} /> : 
                <AccountCircleIcon sx={{ width: 50, height: 50, color: '#999', boxShadow: 3, borderRadius: '50%' }} />
              }
            </ListItemAvatar>

            <ListItemText primary={`${d.firstName} ${d.lastName}`}></ListItemText>
            
            {/* TODO: [F1WEB-200] Driver Schedule high-level status or summary
            <NoteAddOutlinedIcon />
            <WarningAmberRoundedIcon color="warning" />
            <CheckCircleIcon color="success" /> */}
            <NavigateNextIcon />

          </ListItemButton>
        </ListItem>
      )}
    </List>
  );
}
export default DriverSelectList;