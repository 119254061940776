import { SITE_MAP_URL } from '../constant';
import { LoginFormPanel } from '../pages/member/LoginFormPanel';
// library
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
// mui
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';


const PromptLoginDialog = ({ open, onLoginSuccess, onCancelLogin }) => {

  const { t } = useTranslation();

  // Stop user close this dialog by clicking outside
  const handleClose = () => {}
  const choiceToCancel = () => {
    onCancelLogin && onCancelLogin();
    navigate(SITE_MAP_URL.LOGOUT);
  }


  return <Dialog
    open={open}
    onClose={handleClose}
    fullWidth
    maxWidth="sm"
  >
    <DialogTitle>{t("loginPage.alert.sessionExpiredPromptTitle")}</DialogTitle>

    <DialogContent>
      <Alert severity='warning' sx={{ mb: 2 }}>{t("loginPage.alert.sessionExpiredPromptDesc")}</Alert>
      <LoginFormPanel onLoginSuccess={onLoginSuccess} allowLogoutFn={choiceToCancel} />
    </DialogContent>
  </Dialog>

}
export default PromptLoginDialog;