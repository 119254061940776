import { Chip } from "@mui/material";

export const DocAbout = () => (
  <>
    <h2>About Seamless Digital Limited</h2>
    <p>Seamless Digital is a spin-off from Silverstone Paint Technology (SPT). Founded in late 2008, the business has grown to become the largest paint facility within Formula One – and the all-round market leader for high-performance paint technology and coatings. This season, 70% of the F1 grid is serviced by SPT.</p>
  </>
);

export const DocIndex = () => (
  <article>
    <section>
      <h2>Welcome to the User Guide for the Dynamic Messaging Platform!</h2>
      <p>The Dynamic Messaging Platform is a software platform designed to enable the display of Digital Out-of-Home (DOOH) ads on mobile assets. Our platform utilises patented hardware and innovative software systems, allowing you to manage and deliver location, time, and audience-specific advertisements across networks.</p>
      <p>This guide will provide you with step-by-step instructions on how to effectively utilise the Dynamic Messaging Platform to create, manage, and optimise your advertising campaigns, along with valuable tips.</p>
    </section>
    
    <section>
      <h2>Compatibility</h2>
      <h4>Supported Browsers</h4>
      <ul className="narrowSpace">
        <li>Chrome</li>
        <li>Microsoft Edge (Chromium)</li>
        <li>Firefox <i className="text-remark">(coming soon)</i></li>
        <li>Safari <i className="text-remark">(coming soon)</i></li>
      </ul>
    </section>

    <section>
      <h4>Supported Devices</h4>
      <ul className="narrowSpace">
        <li>Desktop</li>
        <li>Laptop</li>
        <li>Tablet <i className="text-remark">(coming soon)</i></li>
        <li>Mobile phone <i className="text-remark">(coming soon)</i></li>
      </ul>
    </section>

    <section>
      <DocAbout />
    </section>
  </article>
);

export const DocReleaseNote = () => (
  <article>
    <h1>Release Notes</h1>

    <section>
      <h4 className="title">Version 2.6.2 <span className="dateText">22 Jul 2024</span>:</h4>
      <ul className="narrowSpace">
        <li>Added support for Indycar.</li>
      </ul>
    </section>

    <section>
      <h4 className="title">Version 2.6.1 <span className="dateText">16 Jul 2024</span>:</h4>
      <ul className="narrowSpace">
        <li>UI Bug fix.</li>
      </ul>
    </section>

    <section>
      <h4 className="title">Version 2.6.0 <span className="dateText">9 Jul 2024</span>:</h4>
      <ul className="narrowSpace">
        <li>Security enhancement.</li>
      </ul>
    </section>

    <section>
      <h4 className="title">Version 2.5.3 <span className="dateText">9 Jul 2024</span>:</h4>
      <ul className="narrowSpace">
        <li>Schedule generation API updated.</li>
      </ul>
    </section>

    <section>
      <h4 className="title">Version 2.5.2 <span className="dateText">15 May 2024</span>:</h4>
      <ul className="narrowSpace">
        <li>Security enhancement.</li>
      </ul>
    </section>

    <section>
      <h4 className="title">Version 2.5.1 <span className="dateText">25 April 2024</span>:</h4>
      <ul className="narrowSpace">
        <li>Services monitor added.</li>
      </ul>
    </section>

    <section>
      <h4 className="title">Version 2.5.0 <span className="dateText">March 2024</span>:</h4>
      <ul className="narrowSpace">
        <li>Schedule clone feature released.</li>
      </ul>
    </section>

    <section>
      <h4 className="title">Version 2.4.0 <span className="dateText">December 2023</span>:</h4>
      <ul className="narrowSpace">
        <li>Data structure upgraded.</li>
      </ul>
    </section>

    <section>
      <h4 className="title">Version 2.3.0 <span className="dateText">14 September 2023</span>:</h4>
      <ul className="narrowSpace">
        <li>Presenting 'Read Only' mode for completed event.</li>
      </ul>
    </section>

    <section>
      <h4 className="title">Version 2.2.3 <span className="dateText">25 July 2023</span>:</h4>
      <ul className="narrowSpace">
        <li>Client information update.</li>
        <li>A bug fix.</li>
      </ul>
    </section>

    <section>
      <h4 className="title">Version 2.2.2 <span className="dateText">17 July 2023</span>:</h4>
      <ul className="narrowSpace">
        <li>A bug fix.</li>
      </ul>
    </section>

    <section>
      <h4 className="title">Version 2.2.1 <span className="dateText">5 July 2023</span>:</h4>
      <ul className="narrowSpace">
        <li>Request the file and download the ZIP schedule rejoined as a single process.</li>
        <li>Enhanced the data schema.</li>
      </ul>
    </section>

    <section>
      <h4 className="title">Version 2.2.0 <span className="dateText">30 June 2023</span>:</h4>
      <ul className="narrowSpace">
        <li>User guide: This refers to the page you are currently viewing.</li>
        <li>Client data correction.</li>
      </ul>
    </section>

    <section>
      <h4 className="title">Version 2.1.2 <span className="dateText">16 June 2023</span>:</h4>
      <ul className="narrowSpace">
        <li>Resolved a bug related to the Logo Library.</li>
        <li>Improved the stability of application data storage.</li>
      </ul>
    </section>

    <section>
      <h4 className="title">Version 2.1.0 <span className="dateText">13 June 2023</span>:</h4>
      <ul className="narrowSpace">
        <li>Added support for logos in both portrait and landscape orientations.</li>
        <li>Enhanced the visual preview of logo images for multiple sizes.</li>
        <li>Improved the user experience for logo preview.</li>
        <li>Fixed a bug in the logo library's search and filtering functionality.</li>
        <li>Enhanced error messages for logo uploads.</li>
        <li>Upgraded the error capture system.</li>
      </ul>
    </section>

    <section>
      <h4 className="title">Version 2.0.0 <span className="dateText">26 April 2023</span>:</h4>
      <ul className="narrowSpace">
        <li>Introduced a logo library with search, filter, and sorting capabilities.</li>
        <li>Improved the application's data storage capabilities.</li>
        <li>Implemented an automatic validator for schedule generation.</li>
      </ul>
    </section>

    <section>

    </section>
    <h4 className="title">Version 1.0.1 <span className="dateText">13 Feb 2023</span>:</h4>
    <ul className="narrowSpace">
      <li>Fixed a user interface issue.</li>
    </ul>

    <h4 className="title">Version 1.0.0 <span className="dateText">3 February 2023</span>:</h4>
    <ul className="narrowSpace">
      <li>Launched the Dynamic Messaging Platform.</li>
    </ul>
  </article>
);

export const DocLogin = () => (
  <article>
    <h2>Accessing the Dynamic Messaging Platform</h2>
    <p>To access the platform, please follow these steps:</p>
    <ol>
      <li>
        Access the Dynamic Messaging Platform on Google Chrome or Microsoft Edge <a href="https://f1.seamlessdigital.com">https://f1.seamlessdigital.com</a> or <a href="https://golf.seamlessdigital.com">https://golf.seamlessdigital.com</a>
      </li>
      <li>
        Enter your login email and password into the corresponding input fields, then click the <Chip component="span" label="Login" color="info" size="small" /> button to proceed.
        <img src="/images/userGuide/login/login-form.png" alt="Login Form" className="maxW-800" />
      </li>
      <li>Click on the <Chip component="span" label="Login" color="primary" size="small" /> Login to continue.</li>
    </ol>


    <h2>Incorrect Email and/or Password</h2>
    <p>If the login email address and/or password are entered incorrectly, a warning message will be displayed, and the corresponding input fields will be marked in <span className="text-red">red</span>.</p>
    <img src="/images/userGuide/login/login-error.png" alt="Login Form" className="maxW-800" />


    <h2>Forgot Password</h2>
    <p>To reset your password, please follow these steps:</p>
    <ol>
      <li>
        If you have forgotten your password, click on the <Chip component="span" label="Forgot Password?" variant="outlined" size="small" /> link below the <Chip component="span" label="Login" color="info" size="small" /> button to open the following dialog box.
        <img src="/images/userGuide/login/forgot-pwd.png" alt="Forgot Password" className="maxW-500" />
      </li>
      <li>Enter your login email address into the input field and click on the <Chip component="span" label="Reset password" color="info" size="small" /> button. Check your email and follow the instructions provided to set a new password.</li>
    </ol>
  </article>
);

export const DocEventAccess = () => (<article>
  <h2>Accessing the Event Schedule</h2>

  <ol className="extraSpace">
    <li>
      Upon successful login, you will be directed to the <b>Schedule Management</b> page. This page displays a list of all the events in chronological order.
      <img src="/images/userGuide/schedule/SchedulePage.png" alt="Schedule Page" className="maxW-800" />
    </li>

    <li>
      <p>To create or modify a schedule for a specific event, click on the <Chip component="span" label="Edit" variant="outlined" color="info" size="small" /> button.</p>
      <p>If an event already has a schedule, the schedule status along with the last updated timestamp will be shown on the right-hand side of the event.</p>
      <p>Events without a schedule status indicate that the event <b>does not</b> have a schedule.</p>
      <img src="/images/userGuide/schedule/EventListClickEdit.png" alt="Schedule Page" className="maxW-700" /><br />
      
      <div className="callout info">
        Please note that you cannot modify past events.
        <img src="/images/userGuide/schedule/PastEvent.png" alt="past event" className="maxW-600" />
      </div>
    </li>

    <li>
      <p>Below is an example of the <b>Event Management</b> page, which shows basic information about the event on the top left-hand side. Click on the <Chip component="span" label="Go back" variant="outlined" color="info" size="small" /> button to navigate back to the event list.</p>
      <p>Underneath the event information, a list of <b>Screen Owners</b> is displayed, waiting for your selection.</p>
      <p>Next, choose a person by clicking on their name, such as "Person 2".</p>

      <img src="/images/userGuide/schedule/EventManagement-1.png" alt="Schedule Page" className="maxW-800" />
    </li>

    <li>
      <p>Once a person is selected, a preview of the screens associated with that person will appear. Select a screen by clicking on the red and white box corresponding to the desired screen, such as "Left Top Screen".</p>
      
      <img src="/images/userGuide/schedule/PickAScreen.png" alt="" className="maxW-800" />
    </li>

    <li>
      <p>After selecting a screen, the system will fetch the latest schedule from the server. A loading indicator will be displayed as shown below:</p>

      <img src="/images/userGuide/schedule/LoadingSchedule.png" alt="" className="maxW-800" />
    </li>

    <li>
      <p>Next to the <b>Screen Selection</b> section, you will find <b>Screen Basic Information</b>, including the name of the current screen and the acceptable logo dimensions (highlighted in a red box).</p>
      
      <p>If the screen does not have a schedule yet, a message will indicate this. To assign the first logo to the scenario, please refer to the next chapter on <b>Create Schedule</b>.</p>

      <img src="/images/userGuide/schedule/ScreenMeta.png" alt="" className="maxW-800" />
      <div className="callout info">
        The currently selected screen is outlined in green.
      </div>
    </li>
  </ol>  
</article>)

export const DocEventCreate = () => (<article>
  <h2>Create a Schedule</h2>

  <ol className="extraSpace">
    <li>
      <p>The system organises logos into different <b>Scenarios</b> based on specific situations. To associate logos with suitable scenarios, click on <Chip component="span" label="Add scenario" variant="outlined" color="success" size="small" />.</p>

      <img src="/images/userGuide/schedule/AddScenario.png" alt="" className="maxW-800" />
    </li>

    <li>
      <p>The system supports multiple scenarios, each containing different content, conditions, and display sequences.</p>
      <p>The available scenarios may vary depending on the screen specifications and the nature of the application. Don't worry if your screen has different scenario options.</p>
      <p>The below example is based on F1 usage</p>
      <p>Click on one or more scenarios, such as "Master," "Laps," "Qualifying," etc., and click <Chip component="span" label="OK" color="info" size="small" /> to continue.</p>

      <img src="/images/userGuide/schedule/SelectScenarios.png" alt="Select Scenario" className="maxW-800" />

      <div className="callout warning">
        Scenarios will appear grey (disabled) if they have already been applied to the schedule.
        <img src="/images/userGuide/schedule/AdditionalScenario.png" alt="select more scenario" className="maxW-400" />
      </div>
    </li>

    <li>
      <p>The scenarios are now ready to be populated with logos. Click on the <b>Add logo</b> cell.</p>
      <p>In the top right-hand corner of each scenario block, a status message provides an overview of the scenario's situation, such as the total length of seconds for all logos and an error icon indicating any issues with logos.</p>

      <img src="/images/userGuide/schedule/AddFirstLogo.png" alt="" className="maxW-800" />
    </li>

    <li>
      <p>The logo library will appear, displaying all images that exactly match the current screen size.</p>
      <p>Please refer to the <b>Logo Library</b> section for explanations on <b>Uploading Logos</b>, <b>Searching and Filtering</b>, etc.</p>

      <img src="/images/userGuide/schedule/SelectLogos-b4.png" alt="" className="maxW-800" />
    </li>

    <li>
      <p>The selected logo is now assigned to the <Chip component="span" label="Master" size="small" /> scenario. Each logo will be added with default values, such as seconds, which you can adjust accordingly.</p>

      <img src="/images/userGuide/schedule/LogoAddedIntoScenario.png" alt="" className="maxW-800" />

      <div className="callout info maxW-500">
        Hovering over an individual logo will display a preview on the screen above, providing a visual preview of the output.

        <img src="/images/userGuide/schedule/HoverToPreview.png" alt="" className="maxW-400" />
      </div>
    </li>
  </ol>
</article>)

export const DocEventClone = () => (<article>
  <h2>Cloning schedule</h2>
  <p>There are two options for cloning: either the entire event schedule or a specific person's schedule. If you choose to clone the whole schedule, every person's schedule will be replicated to the corresponding person in the destination schedule. Alternatively, if you opt to clone an individual person's schedule, it can be copied from either the same event or a different event with a different person.</p>

  <h4>Clone entire event</h4>
  <ol className="extraSpace">
    <li>
      <p>Navigate to the upcoming event and locate the <Chip component="span" label="Clone" color="info" variant="outlined" size="small" /> button associated with the event you wish to clone into.</p>
      <div className="callout warning">
        Be aware that if the event already contains a schedule, it will be overwritten and irreversible.
      </div>
      <img src="/images/userGuide/schedule/ScheduleListPageClone.png" alt="Clone schedule" className="maxW-800 mt-1"/>
    </li>
    <li>
      <p>Clicking the <Chip component="span" label="Clone" color="info" variant="outlined" size="small" /> button triggers a pop-up dialog where the destination event is automatically set based on your previous selection.</p>
      <p>If the destination event is incorrect, click <Chip component="span" label="Exit" color="info" variant="outlined" size="small" /> and find the event you want to clone into.</p>
      <p>Click <Chip component="span" label="Clone from event" variant="outlined" size="small" /> to specify the source of the event.</p>

      <img src="/images/userGuide/schedule/CloneEventDialog-1.png" alt="Clone schedule" className="maxW-800"/>
    </li>
    <li>
      <p>All non-blank events without errors can serve as the source event. Click on the event you wish to clone from.</p>

      <img src="/images/userGuide/schedule/CloneEventDialog-2.png" alt="Clone schedule" className="maxW-800 mt-1"/>
    </li>
    <li>
      <p>Once you've selected the source event, the <Chip component="span" label="Clone" color="info" size="small" /> option becomes enabled. Click it to initiate the cloning process.</p>

      <img src="/images/userGuide/schedule/CloneEventDialog-3.png" alt="Clone schedule" className="maxW-800"/>
    </li>
    <li>
      <p>Upon completion of the cloning process, a success message will be displayed. Click <Chip component="span" label="Exit" color="info" variant="outlined" size="small" /> to close the dialog.</p>

      <img src="/images/userGuide/schedule/CloneEventDialog-4.png" alt="Clone schedule" className="maxW-800"/>
    </li>
  </ol>



  <h4>Clone individual person's schedule</h4>
  <p>To Clone a Person's Schedule:</p>

  <ol>
    <li>
      <p>Go to the schedule management page and locate the <Chip component="span" label="Clone" color="info" variant="outlined" size="small" /> button next to the current event.</p>

      <img src="/images/userGuide/schedule/CloneScenario-Management.png" alt="Clone person start" classNam="maxW-800" />
    </li>

    <li>
      <p>A clone dialog will appear, with the current event set as the destination event.</p>
      <img src="/images/userGuide/schedule/ClonePerson-1.png" alt="Clone person 1" className="maxW-800"/>
    </li>

    <li>
      <p>Click <Chip component="span" label="Clone from event" variant="outlined" size="small" /> and choose the source event you want to clone from. Events marked with a star (*) indicate the current event.</p>
      <img src="/images/userGuide/schedule/ClonePerson-2.png" alt="Clone person 2" className="maxW-800"/>
    </li>

    <li>
      <p>In the <Chip component="span" label="Clone from person" variant="outlined" size="small" /> dropdown menu, the option 'Everyone' clones the entire event for everyone.</p>
      <img src="/images/userGuide/schedule/ClonePerson-3.png" alt="Clone person 3" className="maxW-800"/>
    </li>

    <li>
      <p>If you wish to clone someone's schedule to another person, select the specific individuals for both <Chip component="span" label="Clone from person" variant="outlined" size="small" /> and <Chip component="span" label="Clone to person" variant="outlined" size="small" />. Failure to do so will result in one of the options being highlighted in red as an error.</p>
      <img src="/images/userGuide/schedule/ClonePerson-4.png" alt="Clone person 4" className="maxW-800"/>
    </li>

    <li>
      <p>For instance, this example demonstrates cloning from Person 1's schedule to Person 2's schedule.</p>
      <img src="/images/userGuide/schedule/ClonePerson-5.png" alt="Clone person 5" className="maxW-800"/>
    </li>

    <li>
      <p>Click the <Chip component="span" label="Clone" color="info" size="small" /> button to initiate the cloning process. Repeat the process with different combinations as needed.</p>
      <img src="/images/userGuide/schedule/ClonePerson-6.png" alt="Clone person 6" className="maxW-800"/>
    </li>
  </ol>

</article>)

export const DocEventEdit = () => (<article>

  <section>
    <h2>Types of Scenario</h2>
    <p>Before modifying an existing schedule, let's understand the differences between the two types of scenarios:</p>
    <ul className="narrowSpace">
      <li>Time-based Slideshow</li>
      <li>Conditional-based</li>
    </ul>

    <h4>Time-based Slideshow</h4>
    <p>The time-based slideshow displays logos in a sequential order with predetermined lengths of time in seconds. Each logo can be assigned a different duration. Once the last logo is displayed, the slideshow restarts continuously until:</p>
    <ul className="narrowSpace">
      <li>Power is turned off or lost</li>
      <li>Another scenario is triggered</li>
    </ul>

    <h4>Conditional-based</h4>
    <p>The conditional-based scenario displays specific logos when certain conditions are met. The combination of conditions varies based on the nature of the scenario. For example:</p>
    <ul className="narrowSpace">
      <li>In a racing sport event, when it is lap 6.</li>
      <li>In a motorsport event, when the car enters the pit stop for the second time.</li>
      <li>In a golf event, when the player is at holes 10 and 12.</li>
    </ul>

    <p>As you are aware, multiple scenarios run simultaneously during a single event, and the DMP system automatically triggers the appropriate scenario and displays the corresponding logo based on the conditions.</p>
    <p>In the following chapter, we will explain the operations of both types of scenarios.</p>
  </section>

  <hr />

  <section>
    <h2>Modifying an Existing Schedule</h2>

    <span className="text-remark"><i>If needed, follow the <b>Access Event Schedule</b> section to access an existing schedule.</i></span>

    <p>Once the schedule is loaded, you can make various modifications such as <b>changing logo durations</b>, <b>rearranging the display sequence of logos</b>, <b>modifying logo conditions</b>, and <b>deleting a scenario</b>. These topics will be discussed in the upcoming sections.</p>

    <img src="/images/userGuide/schedule/LogoAddedIntoScenario.png" alt="Logo Added into Scenario" className="maxW-800" />
  </section>

  <hr />

  <DocLogoDel />

  {/* <section>
    <h2>Remove a logo from scenario</h2>

    <ol className="extraSpace">
      <li>
        To remove a logo from scenario, hover mouse pointer to the logo you wish to remove. A red cross button <Chip component="span" label="X" color="error" size="small" /> will appear on top right-hand corner on the logo. Click the button and follow the instruction.
        <img src="/images/userGuide/schedule/clickDel.png" alt="" className="maxW-600" />
      </li>
      <li>
        Clicking <Chip component="span" label="X" size="small" /> will open a confirmation dialog box. Click <Chip component="span" label="Yes" color="error" size="small" /> to confirm or <Chip component="span" label="No" variant="outlined" size="small" /> to disregard. 
        <img src="/images/userGuide/schedule/delConfirm.png" alt="" className="maxW-500" />
      </li>
    </ol>
  </section> */}

</article>)

export const DocEventDuration = () => (<article>
  <h2>Adjusting the Duration of a Logo</h2>
  <h4>for Slideshow Type of Scenario</h4>

  <p>For all scenarios except Laps and Pits (F1), you can change the duration of a logo by entering a valid value in seconds, such as 20 to 150, in the designated input field marked with a <Chip component="span" label="b" variant="outlined" color="error" size="small" />.</p>
  <p>The specified value represents the length of time in seconds that the logo will be displayed on the screen. For example, in the Master Scenario, the logo "Dyson" will be shown for 20 seconds.</p>
  <img src="/images/userGuide/schedule/updateSecond.png" alt="Updating Logo Duration" className="maxW-500" />

  <div className="callout info">
    Please note that the time boundaries in seconds may vary depending on the specific application or requirements.
  </div><br/>

  <p>If an invalid value is entered in the input field, such as a value outside the range of 20 to 150 seconds or a non-numeric character, both the input field and the Scenario Status will turn red, indicating an invalid scenario.</p>
  <img src="/images/userGuide/schedule/secondInvalid.png" alt="Invalid Logo Duration" className="maxW-500" />
</article>)

export const DocEventOrder = () => (<article>
  <h2>Adjusting the Display Sequence of a Logo</h2>
  <h3>For Slideshow Type of Scenario</h3>

  <ol className="extraSpace">
    <li>
      <p>By default, the logos in a Slideshow scenario are displayed in the order they were added. To change the display sequence of the logos, simply drag & drop the logo to its desired location:</p>
      
      <img src="/images/userGuide/schedule/reorderBegin.png" alt="" className="maxW-600" /><br/>
      <img src="/images/userGuide/schedule/reorderEnd.png" alt="" className="maxW-600" />

      <div className="callout info">
        Each logo in the scenario is assigned a number based on the order it was added. This number indicates the sequence in which the logos will be displayed on the screen.
      </div>
      <div className="callout info">
        To cancel the action and restore the original order, simply drag the logo out of the scenario and release it.
      </div>
    </li>

    <li>
      <p>After reordering the logos, the numbering will automatically update to reflect the new display sequence. For example, Allianz is now logo number 2.</p>
      <img src="/images/userGuide/schedule/reorderFinished.png" alt="" className="maxW-600" />
    </li>
  </ol>

  <hr />

  <h3>For Non-Slideshow Type of Scenario (Laps and Pits)</h3>
  <p>Please refer to the next chapter for instructions on adjusting the display sequence in non-slideshow scenarios.</p>

</article>)

export const DocEventConditions = () => (<article>
  <h2>Setting Logo Conditions for Scenarios 'Laps and Pits'</h2>
  <p>In non-slideshow scenarios like Laps and Pits, the sequence of logos does not matter as they appear based on specified moments, such as specific laps or pit stops.</p>
  <p>The value range determines the duration (in terms of Laps or Pits) for which the logo will be displayed on the associated screen.</p>
  <p>For example, setting the value range from 3 to 3 means the logo will be displayed only during Lap/Pit 3, starting from the beginning of Lap/Pit 3 until its completion.</p>
  <p>The Scenario Status indicates the number of logos assigned to that particular scenario. For instance, the Laps scenario contains 2 logos.</p>

  <img src="/images/userGuide/schedule/FixedConditionHighlight.png" alt="" className="maxW-600" /><br />

  <div className="callout info maxW-600">
    If the value range specified in the input field overlaps with other logos, both the input field and the Scenario Status will turn red, and an exclamation mark will be displayed to indicate an invalid scenario.
    <img src="/images/userGuide/schedule/fixInvalid.png" alt="" className="maxW-500" />
  </div>
</article>)

export const DocLogoDel = () => (<article>

  <h2>Deleting a Logo from a Scenario</h2>

  <ol className="extraSpace">
    <li>
      <p>Hovering over the logo you want to delete. A box shadow will appear around the logo, along with a small red cross button (<Chip component="span" label="X" color="error" size="small" />), for example, the logo for Dyson.</p>
      <p>Click the <Chip component="span" label="X" color="error" size="small" /> button to delete the logo from the Scenario.</p>
      
      <img src="/images/userGuide/schedule/clickDel.png" alt="deleting a logos - step 1" className="maxW-800" />
      <div className="callout info">
        Deleting a logo from a Scenario will not affect the logo in other Scenarios or in the 'Logo Library'.
      </div>
    </li>

    <li>
      <p>Clicking the <Chip component="span" label="X" color="error" size="small" /> button will open a confirmation dialog box. Click "Yes" to confirm the deletion or "No" to cancel the deletion.</p>

      <img src="/images/userGuide/schedule/delConfirm.png" alt="Deleting a Logo - Step 2" className="maxW-500" />
      <div className="callout info">
        The confirmation dialog box ensures that logos are not deleted accidentally.
      </div>
    </li>

    <li>
      <p>After deleting a logo, it will be removed from the Scenario, and the remaining logos will be reorganized accordingly.</p>
      <img src="/images/userGuide/schedule/afterDel.png" alt="Deleting a Logo - Step 3" className="maxW-600" />
    </li>
  </ol>

</article>)

export const DocEventDel = () => (<article>
  <h2>Deleting a Scenario</h2>

  <p><b>Important: Please note that once a scenario is deleted and the changes are submitted, it cannot be undone.</b></p>
  
  <ol className="extraSpace">
    <li>
      <p>To delete a scenario, locate the <Chip component="span" label="X" size="small" /> button located at the top right-hand corner of each scenario. Click the <Chip component="span" label="X" size="small" /> button associated with the scenario you want to delete.</p>
      <img src="/images/userGuide/schedule/ClickDelScenario.png" alt="Deleting a Scenario - Step 1" className="maxW-600" />
    </li>
    <li>
      <p>Clicking the <Chip component="span" label="X" size="small" /> button will prompt a confirmation dialog box. To confirm the deletion, click the <Chip component="span" label="Delete" color="error" size="small" /> button. To cancel the deletion, click the <Chip component="span" label="Cancel" variant="outlined" size="small" /> button.</p>
      <img src="/images/userGuide/schedule/ConfirmDelScenario.png" alt="Deleting a Scenario - Step 2" className="maxW-600" />
    </li>
  </ol>

  <hr />

  <DocLogoDel />

</article>)

export const DocEventSave = () => (<article>
  <h2>Saving the Schedule</h2>
  <p>When creating or modifying a Schedule, you can save your changes using the Action Panel located at the bottom of the browser.</p>

  <h3>Save & Submit</h3>
  <h4>for Error-Free Schedules</h4>
  <p>
    The <Chip component="span" label="Save & Submit" color="primary" size="small" /> button appears when the Schedule does not contain any errors. Clicking the <Chip component="span" label="Save & Submit" color="primary" size="small" /> button will save or overwrite the schedule on the server. The Schedule Status in the <b>Download Schedule</b> section will display a green tick, indicating that the Schedule is ready to be downloaded.
  </p>

  <ol className="extraSpace">
    <li>
      <p> If the Schedule does not contain any errors, click the <Chip component="span" label="Save & Submit" color="primary" size="small" /> button to save the new or modified Schedule.</p>
    
      {/* For e.g., Driver Lando Norris contains a Schedule with only one Scenario, the Master Scenario. The Master Scenario consists of 2 logos, Amazon, and Boeing. The Scenario Status shows no errors hence the `Save & Submit` is available to click. */}

      <img src="/images/userGuide/schedule/SaveSubmitBtn.png" alt="Save & Submit button" className="maxW-600" />

      <div className="callout info">
        On the left-hand side, the <Chip component="span" label="Discard changes" variant="outlined" color="error" size="small" /> button allows you to cancel any unsaved changes and revert to the last saved version of the Schedule.
      </div>
      {/* <div className="callout info">
        Errors are found in the Scenario Status bar for each Scenario.
      </div> */}
    </li>

    <li>
      <p>Clicking the <Chip component="span" label="Save & Submit" color="primary" size="small" /> button will open a confirmation dialog box. Click <Chip component="span" label="Yes, Save & Submit" color="success" size="small" /> to confirm or <Chip component="span" label="No" variant="outlined" color="primary" size="small" /> to cancel the operation.</p>
      <img src="/images/userGuide/schedule/saveConfirm.png" alt="Save confirmation" className="maxW-600" />
    </li>

    <li>
      <p>Clicking <Chip component="span" label="Yes, Save & Submit" color="success" size="small" /> will display a confirmation dialog box indicating that the Schedule has been successfully saved and is now available for download. Click <Chip component="span" label="Close" variant="outlined" color="primary" size="small" /> to dismiss the dialog box.</p>
      <img src="/images/userGuide/schedule/saveDone.png" alt="Save completed" className="maxW-200" />
    </li>
  </ol>

  <hr />

  <h3>Save as Draft</h3>
  <h4>for Schedule with Errors</h4>

  <p>If the Schedule contains errors, you can save it as a draft to address the issues later. However, the Schedule will not be available for download until all errors have been resolved.</p>

  <ol className="extraSpace">
    <li>
      <p>If the Schedule contains errors, click the <Chip component="span" label="Save as draft" variant="outlined" color="secondary" size="small" /> button to save the Schedule and address the errors at a later time.</p>
      
      <img src="/images/userGuide/schedule/saveDraft.png" alt="Save as draft button" className="maxW-600" />
      <div className="callout info">
        The <Chip component="span" label="Save as draft" variant="outlined" color="secondary" size="small" /> button appears when the Schedule contains errors. Clicking the <Chip component="span" label="Save as draft" variant="outlined" color="secondary" size="small" /> button will save the Schedule as a draft, and the Event Status will display an exclamation mark, indicating that the Schedule is not available for download.
      </div>
    </li>

    <li>
      Clicking the <Chip component="span" label="Save as draft" variant="outlined" color="secondary" size="small" /> button will open a confirmation dialog box. Click <Chip component="span" label="Yes, Save as draft" color="warning" size="small" /> to confirm or <Chip component="span" label="No" variant="outlined" color="primary" size="small" /> to cancel the operation.
      <img src="/images/userGuide/schedule/draftConfirmation.png" alt="Save completed" className="maxW-600" />
    </li>

    <li>
      Clicking <Chip component="span" label="Yes, Save as draft" color="warning" size="small" /> will display a confirmation dialog box indicating that the Schedule has been successfully saved as a draft and can be modified later. Click <Chip component="span" label="Close" variant="outlined" color="primary" size="small" /> to dismiss the dialog box.
      <img src="/images/userGuide/schedule/saveDone.png" alt="Save completed" className="maxW-200" />
      <div className="callout info">
        If a Schedule contains a Scenario assigned with no logos, the Scenario will be excluded from the Schedule.
      </div>
    </li>
  </ol>

  <hr /> 

  <h3>Discard Changes</h3>
  <ol>
    <li>
      To cancel any unsaved changes and leave the page, click the <Chip component="span" label="Discard changes" color="error" size="small" /> button.
      <img src="/images/userGuide/schedule/discardBtn.png" alt="Discard button" className="maxW-600" />
    </li>
    <li>
      A confirmation dialog box will appear to confirm the discard operation. Click <Chip component="span" label="Yes" color="success" size="small" /> to discard the changes or <Chip component="span" label="No" variant="outlined" color="primary" size="small" /> to cancel.
      <img src="/images/userGuide/schedule/discardConfirm.png" alt="Discard confirmation" className="maxW-500" />
    </li>
    <li>
      The Schedule will be rolled back to the last saved version, discarding any unsaved changes.
    </li>
  </ol>

</article>)

export const DocDownloadQC = () => (<article>
  <h2>Download Schedule</h2>

  <ol className="extraSpace">
    <li>
      <p>After successfully saving and submitting the Schedule, go to the <b>Download Schedule</b> page. On the <b>Download Schedule</b> page, you will see a list of racing events in chronological order.</p>
      
      <p>Each event may have a <b>Schedule Status</b> displayed on the right-hand side, indicating the last updated time stamp of the Schedule for that event.</p>

      <img src="/images/userGuide/download/downloadPage.png" alt="Download Schedule Page" className="maxW-800" />

      <div className="callout info">
        An event without a Schedule Status means that the event does not contain a Schedule.
      </div>
    </li>

    <li>
      <p>If the event has a <b>Schedule Status</b> showing a green tick, it means the Schedule is complete and error-free. In this case, click the <Chip component="span" label="Request file" variant="outlined" color="primary" size="small" /> button to initiate the download process.</p>

      <p>For example, "Event 3" is an error-free Schedule.</p>

      <img src="/images/userGuide/download/ClickReqQC.png" alt="" className="maxW-800" />

      <div className="callout warning">
        If mouse point became a 'Circle-backslash' symbol, it means the Schedule is blank or contain error, and download is forbidden.
        <img src="/images/userGuide/download/blankEvent.png" alt="" className="maxW-800" />
      </div>
    </li>

    <li>
      <p>Clicking the <Chip component="span" label="Request file" variant="outlined" color="primary" size="small" /> button will send a request for the Schedule file, and wait until it finished.{/*  and the button will change to a <Chip component="span" label="Pending" variant="outlined" size="small" /> status. A confirmation dialog box will appear, verifying that the request has been successfully sent. Click <Chip component="span" label="OK" color="primary" size="small" /> to close the dialog box. */}</p>
      
      {/* <img src="/images/userGuide/download/QC-Email.png" alt="" className="maxW-500" /> */}

      {/* <div className="callout warning">
        If the request is unsuccessful a dialog box will appear requesting you to try again. If the issue persists a second dialog box will appear, please email support@seamlessdigital.com
      </div> */}
    </li>

    {/* <li>
      <p>The event status will change to <b>Pending</b>, indicating that the Schedule is awaiting approval in the quality control process.</p>
      <img src="/images/userGuide/download/eventPending.png" alt="" className="maxW-600" />
    </li>

    <li>
      <p>Once the data package has been successfully validated, you will receive an email notification indicating that the Schedule data package is ready for download.</p>
      <img src="/images/userGuide/download/QC-Approval-Email.png" alt="Approval email" className="maxW-600" />
    </li> */}

    <li>
      <p>To download the Schedule, return to the <b>Download</b> page or refresh the page (if necessary), and click the <Chip component="span" label="Download" color="primary" size="small" /> button. Wait for a short while as the files are downloaded to your device.</p>

      <img src="/images/userGuide/download/ClickDownload.png" alt="" className="maxW-600" />
    </li>

    <li>
      <p>Once the download is complete, you will find a ZIP file in your browser's download bar (for Chrome) or in the Downloads folder on your device. The ZIP file will be named after the event title and date/time of the download, such as "Event2-xxxx.zip".</p>

      <img src="/images/userGuide/download/ZIPDownloaded.png" alt="" className="maxW-600" />

      <div className="callout info">
        The naming of the downloaded zip folder is given by Event Title-DateTime. Where Date is YYYYMMDD and Time is HHMMSS
      </div>
    </li>

    <li>
      <p>Extract the contents of the downloaded ZIP file. Copy all the files and folders to the SD card that will be used with the screen. This includes copying the folder(s) and individual schedule files (e.g., c004_s01.sch, c004_s02.sch, etc.) to the SD card.</p>

      <img src="/images/userGuide/download/copyAll.png" alt="" className="maxW-300" />
    </li>

    <li>
      <p>Once the files have been copied to the SD card, it is now ready to be inserted into the hardware for use.</p>

      <div className="callout warning">
        Note: Do not copy the zipped downloaded folder to the SD card. Only copy the extracted files and folders.
        <img src="/images/userGuide/download/notFolder.png" alt="" className="maxW-300" />
      </div>
    </li>
  </ol>
</article>)

export const DocLibUpload = () => (<article>
  <h2>Accessing Logo Library</h2>

  <p>To upload a new logo, you have two options: you can either go to the Logo Library page or use the "Add logo" feature in a Scenario. Both options offer the same features and behavior, but the Logo Library lightbox will only display logos that match the size of the selected screen.</p>

  <p>Follow these steps to upload a logo using the Logo Library page:</p>

  <h3>Accessing the <b>Logo Library</b> page</h3>

  <ol>
    <li>
      To access the <b>Logo Library</b> page, click on the <b>Logo Library</b> icon in the top right-hand corner of the header menu. 
      <img src="/images/userGuide/logoLib/LibraryPage.png" alt="Logo Library Page" className="maxW-800" /> 
    
      <div className="callout info">
        You can also access the Logo Library by clicking on the "Add logo" button on the Schedule Management page.
        <img src="/images/userGuide/schedule/AddLogoBtn.png" alt="" className="maxW-500" />
      </div>
    </li>
  </ol>

  <hr />

  <h2>Uploading a new logo into the Logo Library</h2>
  <p>Follow these steps to upload a new logo to the Logo Library:</p>
  <ol className="extraSpace">
    <li>
      Start by using the "Drag and drop JPG or PNG logo files here or click to browse logo files" area.
      <img src="/images/userGuide/logoLib/DropZone.png" alt="" className="maxW-800" />
    </li>
  
    <li>
      Drag a PNG or JPG (PNG is recommended) logo file into the designated area outlined with a dashed line, such as the "Boeing" example.

      <img src="/images/userGuide/logoLib/GreenDrop.png" alt="" className="maxW-800" />

      <div className="callout info">
        You can upload multiple images at once.
      </div>
    </li>

    <li>
      <p>Once the logo file is in the correct format, indicated by the green outline of the designated area, proceed to drop the logo file(s).</p>
      <img src="/images/userGuide/logoLib/PendingUpload.png" alt="" className="maxW-800" />

      <div className="callout info">
        You can upload multiple JPG or PNG files simultaneously.
      </div>
      
      <div className="callout warning">
        If the logo file(s) are in an incorrect format, the designated area will turn <b><span className="text-red">red</span></b>.
        <img src="/images/userGuide/logoLib/redDrop.png" alt="" className="maxW-800" />
      </div>
    </li>

    <li>
      After successfully uploading the correct logo file(s), a preview window will display the uploaded logo(s).

      <ol className="narrowSpace lAlpha">
        <li>Preview of the uploaded logo.</li>
        <li>Input field for the logo title.</li>
        <li>
          To add the logo file(s) to the Logo Library, click the "Upload" button for each logo, or click "Cancel" to disregard the upload.
          <img src="/images/userGuide/logoLib/PendingCardRemark.png" alt="" className="maxW-300" />
        </li>
      </ol>

      <div className="callout info">
        Providing well-written logo title will optimise the logo search and filter functions.
      </div>
    </li>

    <li>
      Once the chosen logo file(s) are uploaded, the Logo Library window will reappear, showing the newly uploaded logos.

      <img src="/images/userGuide/logoLib/afterUpload.png" alt="" className="maxW-800" />    

      <div className="callout info">
        The uploaded logos are added to the end of the library.
      </div>
    </li>

  </ol>
</article>)

export const DocLibDel = () => (<article>

  <h2>Deleting a Logo from Logo Library</h2>
  <p>To delete a logo from the Logo Library, follow these steps:</p>

  <ol className="extraSpace">
    <li>
      <p>Hover the mouse pointer over the logo you wish to delete. You will see a box shadow around the logo and an <Chip component="span" label="X" color="error" size="small" /> button displayed. Click the <Chip component="span" label="X" color="error" size="small" /> button to delete the logo from the Library.</p>

      <img src="/images/userGuide/logoLib/DelLogo.png" alt="delete logos" className="maxW-800" />
      <div className="callout info">
        Deleting a logo from the Logo Library will <b>not</b> affect the logo in any Scenarios.
      </div>
    </li>

    <li>
      <p>Clicking the <Chip component="span" label="X" color="error" size="small" /> button will open a confirmation dialog box. You can click "Yes" to confirm the deletion or "No" to disregard it.</p>

      <img src="/images/userGuide/schedule/delConfirm.png" alt="delete confirmation" className="maxW-500" />
      <div className="callout info">
        The confirmation dialog box ensures that logos are not deleted unintentionally.
      </div>
    </li>
  </ol>
</article>)


/* export const DocEventAssign = () => (<article>
  <h2>Assign a logo into Scenario</h2>

  <ol className="extraSpace">
    <li>
      To add logos in a Scenario, click on the blue `Add logo` button outlined with a dashed line and this will open the Logo library window e.g., click `Add logo` for the `Master` scenario.
      <img src="/images/userGuide/schedule/ClickAddLogo.png" alt="" className="maxW-800" />
    </li>

    <li>
      The Logo library will present a range of logos to add to the Scenario. To select logos, click on the logo, then click `OK` to add the logos to the Scenario. 
      <img src="/images/userGuide/schedule/LoadingLogo.png" alt="loading logos" className="maxW-400" />
      <img src="/images/userGuide/schedule/SelectLogo.png" alt="select logos" className="maxW-800" />
      <div className="callout info">
        Use the Search and Filter functions to simplify your experience. 
      </div>
      <div className="callout info">
        When a logo is selected the logo will darken and a circular green tick symbol will be shown. 
      </div>
      <div className="callout info">
        A logo can be added to a Scenario multiple times.
      </div>
    </li>

    <li>
      The logos are now successfully assigned to the Scenario.
      <img src="/images/userGuide/schedule/LogoAddedWithMarker.png" alt="logos added into scenario" className="maxW-800" />

      <div className="callout info">
        Each logo will be numbered as they are added to the Scenario. This indicates the order the logos will be displayed. See a. 
      </div>
      <div className="callout info">
        The Scenario Status indicates the number of logos used out of 99 and the total time (in seconds) logos are shown in that Scenario e.g., 2 / 99 logos used in the Master Scenario with a total display time of 40 seconds. 
      </div>
    </li>
  </ol>

</article>)
 */

export const DocLibSearch = () => (<article>
  <h2>Searching in Logo Library</h2>

  <p>In the Logo Library section, located below the upload image dropzone, you will find a search box on the left-hand side and grouping & sorting controls on the right-hand side.</p>

  <p>The <b>Search input box</b> on the left-hand side is designed to search for logo titles (regardless of size) and display the matching results in the result section. The search is <b>case-insensitive</b>, meaning that the words entered in the search box will be treated as lowercase or uppercase.</p>

  <img src="/images/userGuide/logoLib/Text-Search-Highlighted.png" alt="" className="maxW-800" />

  <hr />

  <h2>Sorting in Logo Library</h2>

  <p>The <b>Sort by</b> selection is used to sort all logo titles. Currently, there are limited sorting options available, but more options will be provided in future releases.</p>

  <img src="/images/userGuide/logoLib/LogoSorting.png" alt="" className="maxW-800" />

</article>)


/**
 * <Translation Key>: <ReactNode>
 * 
 * p.s. Translation key please refer to translation.json -> userGuide -> nav
 */
export const DocsSiteMap = {
  "intro"   : <DocIndex />,
  "whatsNew": <DocReleaseNote />,

  "s1": {
    "type": "separator"
  },
  
  "login"   : <DocLogin />,

  "eventGroup": {
    "eventAccess"    : <DocEventAccess />,
    "eventCreate"    : <DocEventCreate />,
    "eventClone"     : <DocEventClone />,
    "eventEdit"      : <DocEventEdit />,
    "eventDuration"  : <DocEventDuration />,
    "eventOrder"     : <DocEventOrder />,
    "eventConditions": <DocEventConditions />,
    "eventDel"       : <DocEventDel />,
    "eventSubmit"    : <DocEventSave />,
  },

  "downloadGroup": {
    "downloadReq": <DocDownloadQC />
  },

  "libGroup": {
    "libUpload": <DocLibUpload />,
    "libDel"   : <DocLibDel />,
    "libSearch": <DocLibSearch />,
  }  
}