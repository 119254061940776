// mui
import IconButton from '@mui/material/IconButton';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
// Icon
import CloseIcon from '@mui/icons-material/Close';



const ActionFeedbackDialog = ({ open, onClose, maxWidth, title, hideTitleClose, children }) => 
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="dialogTitle"
    aria-describedby='dialogDesc'
    maxWidth={maxWidth ?? 'sm'}
  >
    {title && <DialogTitle id="dialogTitle" sx={{ paddingRight: '60px' }}>
      {title}
      {hideTitleClose === null 
        && <IconButton 
        aria-label="close" 
        onClick={onClose}
        sx={{ position: 'absolute', right: 8, top: 8 }}
      ><CloseIcon /></IconButton>}
    </DialogTitle>}

    <DialogContent id="dialogDesc"
      sx={{ textAlign: 'center' }}
    >
      {children}
    </DialogContent>

  </Dialog>

export default ActionFeedbackDialog;