import { Header, MainWrapper, Footer } from "../site-template/SiteFrame";
// Component
import LogoLibrary from "../components/Logo/logoLibrary";
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";



const LogoLibraryPage = (prop) => {

  const { t } = useTranslation();

  return <>
      <Header />

      <Helmet>
        <title>{t("sitemap.logo_library")} | {t("global.productNameShort")}</title>
      </Helmet>

      <MainWrapper sx={{ pt: 2 }}>
        <LogoLibrary 
          selectable={false} 
          selectionQuota={0} 
          /* editable={false} */ 
          uploadable={true}
          delable={true}
          sx={{ pt: 2 }}
        />
      </MainWrapper>
      <Footer />
    </>
}

LogoLibraryPage.propTypes = {

}
LogoLibraryPage.defaultProps = {

}
export default LogoLibraryPage;
