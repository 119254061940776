import { useState } from 'react';
import { SITE_MAP_URL } from '../constant';
// mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
// icon
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';

const SomethingHappenedFrame = ({ enable, children, center = false, ...p}) => <>
  {enable &&
    <Box {...p}>
      <Stack
        spacing={1}
        direction="column"
        alignItems={center ? "center" : "flex-start"}
        justifyContent="flex-start"
      >
        {children}
      </Stack>
    </Box>
  }
</>


// Error or Warning
export const SomethingWrong = ({severity = 'error', title, subtitle, showLogout, ...p}) => {

  const { t } = useTranslation();

  return (
    <SomethingHappenedFrame {...p}>

      {/* <HealingIcon color={severity} sx={{ fontSize: '5rem' }} /> */}
      <SomethingMissingIcon w="120px" h="120px" />

      {(title || subtitle) && <Alert severity={severity}>
        <AlertTitle>{title}</AlertTitle>
        {subtitle}
      </Alert>}

      {showLogout && <Link href={SITE_MAP_URL.LOGOUT}>{t("loginPage.Logout")}</Link>}

    </SomethingHappenedFrame>
  );
}


// Missing & Reload
export const SomethingMissing = ({severity = "warning", title, retryAction, ...p}) => {

  const [loadingFlag, setLoadingFlag] = useState(false);

  const handleClick = () => {
    setLoadingFlag(true);
    retryAction && retryAction();
  }

  return <>
    <SomethingHappenedFrame {...p}>

      <WarningAmberRoundedIcon color={severity} sx={{ fontSize: '5rem' }} />

      {title &&
      <Alert severity={severity}>

        {title}

        <LoadingButton 
          loading={loadingFlag}
          disabled={loadingFlag}
          variant="outlined" 
          size="small" 
          startIcon={<ReplayIcon />}
          onClick={handleClick}
        >
          Reload
        </LoadingButton>

      </Alert>}

    </SomethingHappenedFrame>
  </>
} 


export const SomethingMissingIcon = ({ children, w, h,  }) => {
  return <>
    <Box sx={{ width: w ?? "200px", height: h ??"200px", mt: 3 }} display="flex" justifyContent="center" alignItems="center">
      <WarningAmberRoundedIcon color="warning" sx={{ fontSize: "160px" }} />
    </Box>

    {children}
  </>
}