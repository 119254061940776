import { useContext, useEffect, useState } from 'react';
import { SITE_MAP_URL } from '../../constant';
// Components
import SelectedEvent from "./components/SelectedEvent";
import DriverList from "./components/DriverList";
import ScreenPreview from "./components/ScreenPreview";
import ScenarioContainer from "./components/scenarioControl/ScenarioContainer";
import ConfirmationDialog from '../../components/ConfirmationDialog';
// Context 
import { PreviewImageProvider } from "../../context/PreviewImageContext";
import { SCHEDULE_CONTEXT_ACTION_TYPE, ScheduleContext } from "../../context/ScheduleContext";
// MUI
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Stack from '@mui/material/Stack';
// Library
import { Router, navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Helmet } from 'react-helmet';


// Components
const MainEle = ({ onScreenChange, onPageMetaChange }) => {


  return (
  <main>
    {/* <div style={{'display': 'none'}}>Main: {props["*"] ?? "N/A"}</div> */}
    <PreviewImageProvider>
      <Router>
        <ScreenPreview path=":driverID" onScreenChange={onScreenChange} onPageMetaChange={onPageMetaChange}>
          <ScenarioContainer path=":screenID" />
        </ScreenPreview>
      </Router>
    </PreviewImageProvider>
  </main>
  )
}

const AsideEle = ({eventID, onDriverChange, onLeave, onPageMetaChange}) => {
  
  return (
    <aside>
      <Stack spacing={2}>
        <SelectedEvent eventID={eventID} onLeave={onLeave} onPageMetaChange={onPageMetaChange} />
        <DriverList onDriverChange={onDriverChange} onPageMetaChange={onPageMetaChange} />
        {/* <Router primary={false}>
          <ScheduleOverview path=":driverID/*" />
        </Router> */}
      </Stack>
    </aside>
  )
}


// Shell (and act as management delegator)
const Management = (props) => {

  const { scheduleState: { hasChanges, eventIDCtx, resourceIDCtx, assetIDCtx }, scheduleDispatch } = useContext(ScheduleContext);
  const { t } = useTranslation();

  const [targetAssetID, setTargetAssetID]   = useState("");
  const [targetDriverID, setTargetDriverID] = useState("");
  const [dialoagOpen, setDialogOpen]        = useState(false);
  const [dialogOption, setDialogOption]     = useState(null);
  // const urlMatch = useMatch(`/event/management/:eventID/:driverID/*`);

  // Dynamic update page title
  const [dynamicPageTitle, setDynamicPageTitle] = useState("");
  let pageTitleMeta = {};
  const _updatePageMeta = (newMetaObj) => {

    pageTitleMeta =  _.omitBy(
        { ...pageTitleMeta, ...newMetaObj }, 
        o => o === ""
      ) 
    
    let pTitle = t("sitemap.manage") + ' | ' + t("global.productNameShort");

    ('eventDisplayName'  in pageTitleMeta) && (pTitle = pageTitleMeta.eventDisplayName + ' | ' + pTitle);
    ('driverDisplayName' in pageTitleMeta) && (pTitle = pageTitleMeta.driverDisplayName + ' | ' + pTitle);
    ('screenDisplayName' in pageTitleMeta) && (pTitle = pageTitleMeta.screenDisplayName + ' | ' + pTitle);

    setDynamicPageTitle(pTitle);
  }


  // - Start - Global - Start -
  const closeConfirmDiscardDialog = () => {
    setDialogOpen(false);
    setTargetAssetID("");
    setTargetDriverID("");
  }
  // - End - Global - End -


  // - Start - Changing Resource / Screen Aert - Start -
  const onScreenChange = (assetID) => {

    // Guard
    if (assetIDCtx !== "" && assetID === assetIDCtx) return;

    // Continue
    if (hasChanges) {
      setTargetAssetID(assetID);
      setDialogOption({
        // title: t("schedulePage.alert.leaveB4SubmitTitle"),
        desc: t("schedulePage.alert.warnB4ChgScreenDesc"),
        maxWidth: 'sm',
        okColor: "error",
        okText: t("schedulePage.yesDiscardBtn"),
        cancelText: t("global.NO"),
        okAction: () => performScreenChange(assetID),
        closeAction: () => closeConfirmDiscardDialog()
      });
      setDialogOpen(true);
    } else {
      performScreenChange(assetID);
    }
  }
  
  const performScreenChange = (aID = targetAssetID) => {
    closeConfirmDiscardDialog();
    scheduleDispatch({ hasChanges: false });
    if (eventIDCtx !== "" && resourceIDCtx !== "") {
      navigate(SITE_MAP_URL.EVENT + SITE_MAP_URL.EVENT_MANAGEMENT + `/${eventIDCtx}/${resourceIDCtx}/${aID}/`);
    } else {
      console.error("Event ID & Driver ID is missing from CTX");  // TODO: Error
      navigate(SITE_MAP_URL.EVENT); 
    }
  }

  const onDriverChange = (resourceID) => {

    // Guard
    if (resourceIDCtx !== "" && resourceID === resourceIDCtx) return;

    // Continue
    if (hasChanges) {
      setTargetDriverID(resourceID);
      setDialogOption({
        // title: t("schedulePage.alert.leaveB4SubmitTitle"),
        desc: t("schedulePage.alert.warnB4ChgDriverDesc"),
        maxWidth: 'sm',
        okColor: "error",
        okText: t("schedulePage.yesDiscardBtn"),
        cancelText: t("global.NO"),
        okAction: () => performDriverChange(resourceID),
        closeAction: () => closeConfirmDiscardDialog()
      });
      setDialogOpen(true);
    } else {
      performDriverChange(resourceID);
    }
  }

  const performDriverChange = (rID = targetDriverID) => {
    closeConfirmDiscardDialog();
    scheduleDispatch({ hasChanges: false });
    scheduleDispatch({ type: SCHEDULE_CONTEXT_ACTION_TYPE.DEL_PREVIEW_LOGO });
    if (eventIDCtx !== "") {
      navigate(SITE_MAP_URL.EVENT + SITE_MAP_URL.EVENT_MANAGEMENT + `/${eventIDCtx}/${rID}`);
    }
  }

  // when user choice to Leave 'Schedule Management Mode', then back to Event Selection page
  // by clicking "Mange another Event"
  const onLeave = (fn) => {

    if (hasChanges) {
      setDialogOption({
        // title: t("schedulePage.alert.leaveB4SubmitTitle"),
        desc: t("schedulePage.alert.warnB4GoBackWithChangesDesc"),
        maxWidth: 'sm',
        okColor: "error",
        okText: t("schedulePage.yesDiscardBtn"),
        cancelText: t("global.NO"),
        okAction: () => {
          closeConfirmDiscardDialog();
          scheduleDispatch({ hasChanges: false });
          fn();
        },
        closeAction: closeConfirmDiscardDialog
      });
      setDialogOpen(true);
    } else {
      fn();
    }
  }
  // - End - Changing Resource / Screen Aert - END -
  

  // --------------------------------------------
  // - Start - Blocking and warning user about 'Back' - START -
  const performDiscardGoBack = () => {
    closeConfirmDiscardDialog();
    scheduleDispatch({ hasChanges: false });
    navigate(-2); // TODO: not a good solution
  }

  const backWatcher = (e, initialFlag = false) => {

    if (!initialFlag) {
      setDialogOption({
        // title: t("schedulePage.alert.leaveB4SubmitTitle"),
        desc: t("schedulePage.alert.warnB4GoBackWithChangesDesc"),
        okColor: "error",
        okText: t("schedulePage.yesDiscardBtn"),
        cancelText: t("global.NO"),
        maxWidth: "sm",
        okAction: performDiscardGoBack,
        closeAction: closeConfirmDiscardDialog
      });
      setDialogOpen(true);
    }

    window.history.pushState(null, null, document.URL);
  }
  const reloadWatcher = (e) => {
    e.preventDefault();
    // backWatcher(e);

    e.returnValue = null;
    window.onbeforeunload = function () {
      return null;
    }
    
  }

  useEffect(() => {
    
    if (hasChanges) {
      //window.history.pushState(null, null, document.URL);\
      backWatcher(null, true);

      window.addEventListener('popstate', backWatcher);
      window.addEventListener('beforeunload', reloadWatcher);
    } else {
      window.removeEventListener('popstate', backWatcher);
      window.removeEventListener('beforeunload', reloadWatcher);
    }

    return () => {
      window.removeEventListener('popstate', backWatcher);
      window.removeEventListener('beforeunload', reloadWatcher);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanges]);
  // - End - Blocking and warning usr about 'Back' - END -
  // --------------------------------------------


  // --------------------------------------------
  // - Render -----------------------------------
  return (
  <Container maxWidth="xl">
    <Helmet>
      <title>{dynamicPageTitle}</title>
    </Helmet>
    <Grid container columnSpacing={1}>
      <Grid item sx={{ width: 320 }}>
        <AsideEle eventID={eventIDCtx} onDriverChange={onDriverChange} onLeave={onLeave} onPageMetaChange={_updatePageMeta} />
      </Grid>
      <Grid item sx={{ width: 'calc(100% - 320px)' }}>
        <MainEle onScreenChange={onScreenChange} {...props} onPageMetaChange={_updatePageMeta} />
      </Grid>
    </Grid>
    <ConfirmationDialog
      open={dialoagOpen}
      dialogOption={dialogOption}
    />
  </Container>)
};
export default Management;