// Components
import { Header, Footer } from "../site-template/SiteFrame";
import { SomethingMissingIcon } from "./SomethingWrong";
// library
import { useTranslation } from "react-i18next";
// mui
import { Stack, Typography } from "@mui/material";


const BrowserNotAvailableYet = () => {
  const { t } = useTranslation();

  return <>
    <Header plainMode={true} />
    <Stack direction="column" alignItems="center" justifyContent="flex-start" spacing={2} sx={{ py: 2, height: "calc(100vh - 50px - 41px)", overflowY: 'auto' }}>
      <SomethingMissingIcon w="140px" h="140px" />
      <Typography variant="h5" sx={{ fontWeight: 600, textAlign: "center" }}>
        {t(`commonError.NotChromeNotReadyTitle`)}
      </Typography>
      <Typography variant="body1" sx={{ width: "80%", textAlign: "center" }}>
      {t(`commonError.NotChromeNotReadyDesc`)}
      </Typography>
    </Stack>
    <Footer />
  </>;
}
export default BrowserNotAvailableYet;