import api, { API_URL } from "./api";
import { setUserToken, startAutoJWTRefresh, stopAutoJWTRefresh } from "./token.service";
import { getCookiesInObject, getStoredValue, removeStorage, setStoreValue } from "../common/utils";
import APPLICATION_CONSTANT, { RESET_PASSWORD_MODE, SITE_MAP_URL } from "../constant";
import _ from 'lodash';
import * as Sentry from '@sentry/browser';

/**
 * UI Login function
 * @param {string} email 
 * @param {string} password 
 */
export const performLogin = async (email, password, stayAlive = false) => {

  return api.post(API_URL.LOGIN, { email, password })
    .then(
      r => {
        // console.log( getCookiesInObject("ui") )
        setUserToken({ 'userId': getCookiesInObject("ui") });
        stayAlive && startAutoJWTRefresh();
    
        return Promise.resolve([]);              
      },
      e => Promise.reject([ 100905 ]) // TODO: Since API insist to return 'HTTP 401' instead of 200, i have no way but fake the error for UI error message
    )
} 

/**
 * UI Logout function
 */
export const performLogout = () => {
  window.localStorage.clear();
  window.sessionStorage.clear();

  stopAutoJWTRefresh();
}


// - Fn -------------------------------
export const getUserID = () => {
  let cui = getCookiesInObject("ui") 
  let tui = getStoredValue(APPLICATION_CONSTANT.USER_ID) 
  
  console.log("getUserID: " + cui + " | " + tui)

  return cui ?? tui;
};
export const getRAWUserProfile = () => getStoredValue(APPLICATION_CONSTANT.USER_PROFILE) ?? {};
export const setRAWUserProfile = (prof) => setStoreValue(APPLICATION_CONSTANT.USER_PROFILE, prof);
export const clearRAWUserProfile = () => removeStorage(APPLICATION_CONSTANT.USER_PROFILE);

/**
 * Check if user is belong to one of the role
 * @param {String/Array of String} role 
 * @returns 
 */
export const hasRole = (role) => {
  try {
    const roles = getStoredValue(APPLICATION_CONSTANT.USER_ROLES) ?? [];
  
    return _.includes(roles, role);
  } catch (e) {
    console.error(e);
  }

  return false;
}



 // Who am I? What Product(s) I can work on?
export const getUserAccount = async () => {

  const userID = getUserID();
  if (userID === null || userID === undefined) return Promise.reject("User ID is missing");

  return api.get(API_URL.GET_USER_ACCOUNTS + userID)
    .then(
      r => {
        const { succeeded, data, errors } = r.data;

        return succeeded 
          ? Promise.resolve(data.userAccounts)
          : Promise.reject( errors.map(e => e.code) )
  
      },
      e => Promise.reject(e)
    );
  
}


export const forgetPwd = async (email) => {

  if ( _.size(email) === 0 ) return Promise.reject("Email Address is missing");


  return api.post(API_URL.FORGET_PASSWORD, { "email": email, "callbackorigin": window.location.origin + SITE_MAP_URL.FORGET_PASSWORD })
    .then(
      r => {
        const { succeeded, data, errors } = r.data;

        if (succeeded) {
          return Promise.resolve(data);
        }

        return Promise.reject(errors);
      },
      e => Promise.reject(e)
    )


}


export const resetPwd = async (changeMode, postBody) => {

  if ( _.size(postBody) === 0 ) return Promise.reject("Parameter is missing");

  const reqURL = (changeMode === RESET_PASSWORD_MODE.RESET) ? API_URL.RESET_PASSWORD : API_URL.CHANGE_PASSWORD;

  return api.post(reqURL, postBody)
    .then(
      r => {
        const { succeeded, data, errors } = r.data;

        if (succeeded) {
          return Promise.resolve(data);
        }

        return Promise.reject(errors);
      },
      e => Promise.reject(e)
    )

}


export const getUserProfiles = async (userId) => {

  const cachedUP = getRAWUserProfile();
  if (! _.isEmpty(cachedUP) ) {
    return cachedUP
  } else {

    // Fetch if not cached
    const up = await api.get(API_URL.USER_PROFILE + (userId ?? getUserID()));
  
    const { data: { succeeded, errors, data: { profile } } } = up;
  
    // (process.env.NODE_ENV === "development") && console.log("User profile", succeeded, profile, up);
    
    (errors) && Sentry.captureException(new Error(`${JSON.stringify(errors)}`));

    setRAWUserProfile(JSON.stringify( succeeded ? profile : {} ));
  
    return succeeded ? profile : {};

  }


}