import { useState, useRef, useEffect } from 'react';
import ScenarioCardFrame from './ScenarioCardFrame';
// Library
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
// mui
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Input from '@mui/material/Input';

import { red, yellow } from '@mui/material/colors'




const FixedRangeCard = (props) => {

  const { triggerDic, logo, isReadOnly }  = props;

  const { t } = useTranslation();
  // const [logo, setLogo] = useState(props.logo);
  const inputStartEl = useRef(null);
  const inputEndEl   = useRef(null);
  const [errorOnStart, setErrorOnStart]                 = useState(false);
  const [errorOnEnd, setErrorOnEnd]                     = useState(false);
  const [startChangedTooltips, setStartChangedTooltips] = useState(false);
  const [endChangedTooltips, setEndChangedTooltips]     = useState(false);


  const shortScenarioName = t(`scenario.${triggerDic.element}-title`); // triggerDic.title.split(" ")[0];

  const styles = {
    changed: {
      backgroundColor: yellow[100],
    },
    error: {
      backgroundColor: red[200],
    }
  }

  // Data Handling
  const onValueChanged = () => {

    // Reset
    setEndChangedTooltips(false);
    setStartChangedTooltips(false);

    // Non of my business
    if (document.activeElement !== inputStartEl.current && document.activeElement !== inputEndEl.current) return;

    // Prepare
    const workingInput = (document.activeElement === inputStartEl.current) ? inputStartEl.current : inputEndEl.current;
    const targetInput  = (document.activeElement === inputStartEl.current) ? inputEndEl.current : inputStartEl.current;

    // Rule 1, No input should be empty
    if (targetInput.value === "") {
      targetInput.value = workingInput.value;
    }

    // Rules 2, Start <= End, else, higher End
    // console.log(inputStartEl.current.value, inputEndEl.current.value);
    // if (isValidValue(workingInput.value) && inputStartEl.current.value > inputEndEl.current.value) {
    if (parseInt(inputStartEl.current.value) > parseInt(inputEndEl.current.value)) {

      // targetInput.value = workingInput.value;
      
      if (document.activeElement === inputStartEl.current) {
        targetInput.value = workingInput.value;
        setEndChangedTooltips(true) ;
        setTimeout(() => setEndChangedTooltips(false), 3500); // Dismiss tooltips
      } /* 
      // Disabled the End->Start coping because double digit value will override start value which is not good UX
      else if (document.activeElement === inputEndEl.current) {
        setStartChangedTooltips(true)
        setTimeout(() => setStartChangedTooltips(false), 3500);
      } */
    }

    // inputValidation();

    // Inform slibing if no error
    if (props.onValueChanged) {
      let updatedLogo = {...logo};

      let sV = parseInt( inputStartEl.current.value );
      let eV = parseInt( inputEndEl.current.value );
      
      // if (isNaN(sV)) sV = "";
      // if (isNaN(eV)) sV = "";

      updatedLogo.slots[0].start = sV;
      updatedLogo.slots[0].end   = eV;
      //console.log("uplodaed deep logo data", updatedLogo);

      props.onValueChanged(updatedLogo, props.idx);
    }
  } // onValueChanged

  const onDel = () => props.performDel && props.performDel(props.idx);

  // Validation
  const isValidValue = (val, isRequired = false) => {
    // console.log(parseInt(val), triggerDic.instances.maximum, (parseInt(val) <= triggerDic.instances.maximum))

    if ( isNaN(val) ) return false;
    if (isRequired && _.isString(val) && val === "") return false;
    
    let v = val;
    let maxV, minV;
    try {
      v = parseInt(v);
      maxV = parseInt(triggerDic.instances.maximum);
      minV = parseInt(triggerDic.instances.minimum);

      if (isNaN(v) || isNaN(maxV) || isNaN(minV)) {
        return false;
      }
    } catch (e) {
      return false;
    }

    return (v >= minV && v <= maxV);
  };


  const inputValidation = () => { //useMemo(() => {

    setErrorOnStart(false);
    setErrorOnEnd(false);

    const sVal = parseInt(inputStartEl.current.value);
    const eVal = parseInt(inputEndEl.current.value);

    // 1. Input Value Guard
    let sErrFlag = !isValidValue(sVal, true);
    let eErrFlag = !isValidValue(eVal, true);

    sErrFlag && setErrorOnStart(true);
    eErrFlag && setErrorOnEnd(true);
    if (sErrFlag || eErrFlag) {
      return false;
    }

    // than, Start > End
    if (sVal > eVal) {
      setErrorOnStart(true);
      return false;
    }

    // Further checking
    let olRange = props.overlapped;

    // 2. is it overlapping the start & end
    sErrFlag = (olRange[sVal-1] === true);
    eErrFlag = (olRange[eVal-1] === true);

    sErrFlag && setErrorOnStart(true);
    eErrFlag && setErrorOnEnd(true);
    
    if (sErrFlag || eErrFlag) return false;

    // 3. Any overlap in between?
    if ((eVal - sVal) > 1) {

      const middleRangeHasError = olRange.slice(sVal-1, eVal).includes(true);
      setErrorOnStart(middleRangeHasError);
      setErrorOnEnd(middleRangeHasError);
      if (middleRangeHasError) return false;

    } 

    // Finally
    return true;
  }; //, [props.overlapped, triggerDic.instances]);

  // useEffect(() => {
    // inputValidation();
    // console.log('useEffect Validate', logo.id);
  // }, []);
  

  useEffect(() => {
    if ( ! isNaN(logo.slots[0].start) ) {
      inputStartEl.current.value = logo.slots[0].start;
    }
    if ( ! isNaN(logo.slots[0]?.end) ) {
      inputEndEl.current.value = logo.slots[0]?.end;
    }

    inputValidation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logo.slots/* , inputValidation */]);


  return (
    <ScenarioCardFrame logo={logo} alt={``} onDel={onDel} isReadOnly={isReadOnly}>
      <Stack direction="row" alignItems='center' spacing={0.5}>

        <span>{ shortScenarioName }</span>
        
        <Tooltip title="value updated" open={startChangedTooltips} arrow>
          <Input
            inputRef={inputStartEl}
            type="number"
            disabled={isReadOnly}
            placeholder={`${triggerDic.instances.minimum}`}
            required
            size="small"
            label="start"
            error={errorOnStart}
            variant={errorOnStart?"filled":"standard"}
            inputProps={{
              'aria-label': `start ${shortScenarioName}`,
              min: triggerDic.instances.minimum, max: triggerDic.instances.maximum, 
              maxLength: triggerDic.instances.maximum.length,
            }}
            onChange={onValueChanged}
            sx={{
              paddingLeft: "2px",
              ...(startChangedTooltips ? styles.changed : {}),
              ...(errorOnStart ? styles.error : {}),
            }}
          />
        </Tooltip>

        <span>{"to"}</span>

        <Tooltip title="value updated" open={endChangedTooltips} arrow>
          <Input
            inputRef={inputEndEl}
            type="number"
            disabled={isReadOnly}
            placeholder={`${triggerDic.instances.maximum}`}
            size="small"
            label="End"
            error={errorOnEnd}
            variant={errorOnEnd?"filled":"standard"}
            inputProps={{
              'aria-label': `end ${shortScenarioName}`,
              min: triggerDic.instances.minimum, max: triggerDic.instances.maximum, 
              maxLength: triggerDic.instances.maximum.length,
            }}
            onChange={onValueChanged}
            sx={{
              paddingLeft: "2px",
              ...(endChangedTooltips ? styles.changed : {}),
              ...(errorOnEnd ? styles.error : {}),
            }}
          />
        </Tooltip>

      </Stack>
    </ScenarioCardFrame>
  )
}
export default FixedRangeCard;