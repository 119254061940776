import { isValidElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon  from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

// User Doc content
import { DocsSiteMap } from './Docs';
import './user-guide.scss'


const drawerWidth = 240;

const DocMain = styled('article', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    maxWidth: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const HelpAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: '#fff',
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


const PageItem = ({ groupID, pageID, currentPageID, onPageClick }) => {

  const {t} = useTranslation();
  
  // const keyStr = useMemo(() => groupID.length > 0 ? groupID+"."+pageID : pageID, [groupID, pageID]);
  const theOne = useMemo(() => currentPageID === pageID || currentPageID === groupID+'.'+pageID, [groupID, pageID, currentPageID]);

  return <ListItemButton 
    onClick={onPageClick}
    sx={{
      fontSize: '0.875rem',
      ...theOne ? { 'backgroundColor': '#dfdfdf' } : {}
    }}
  >
    {t(`userGuide.nav.${pageID}`)}
  </ListItemButton>
}

const UserGuideGroup = ({subItems, onPageClick, ...props}) => {

  const { t } = useTranslation();
  const [toggled, setToggled] = useState(props.defaultOpen ?? true);

  return (<>
    <ListItemButton onClick={() => setToggled(!toggled)} sx={{ fontSize: '0.875rem', justifyContent: 'space-between' }}>
      {t(`userGuide.nav.${props.groupID}`)}
      {toggled ? <ExpandLess sx={{ color: "#666" }} /> : <ExpandMore sx={{ color: '#666' }} />}
    </ListItemButton>
    <Collapse in={toggled} timeout="auto" unmountOnExit>
      <List component="div" sx={{ borderLeft: "solid 1px #ccc", ml: 2, py: 0 }}>
        
        {Object.entries(subItems).map(([k, v],idx) => 
          <PageItem key={`ug.${idx}`} pageID={k} onPageClick={() => onPageClick(props.groupID+'.'+k)} {...props} />
        )}

      </List>
    </Collapse>
  </>)
}


export default function UserGuideWrapper() {

  const { t } = useTranslation();
  const theme = useTheme();

  const [openDrawer, setOpenDrawer]   = useState(true);
  const [currentPage, setCurrentPage] = useState("intro");

  const toggleDrawer = () => setOpenDrawer( !openDrawer );
  const onPageChange = (pageNode) => setCurrentPage(pageNode);
  

  // DEV ----------------
  /* useEffect(() => {
    console.group("Sitemap")
    for (const [key, value] of Object.entries(DocsSiteMap)) {
      console.log(`${key}: ${value}`);
    }
    console.groupEnd("Sitemap")
  }, [DocsSiteMap]) */

  /* useEffect(() => {
    console.log("Page changed: ", currentPage);
  }, [currentPage]) */
  // DEV ----------------


  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>

      <HelpAppBar position='fixed' open={openDrawer}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            sx={{ mr: 2, ...(openDrawer && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {t('userGuide.title')}
          </Typography>
        </Toolbar>
      </HelpAppBar>

      <Drawer
        sx={{
          // position: "absolute",
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={openDrawer}
      >
        <DrawerHeader>
          <IconButton onClick={toggleDrawer}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List component="nav" sx={{ color: '#666', fontSize: '0.875rem' }}>

          {Object.entries(DocsSiteMap).map(([key, val], idx) => {
        
            // Divider
            if (_.hasIn(val, "type") && val.type === "separator") {
              return <Divider key={`ugnav-${idx}`} variant="middle" sx={{ my: 2 }} /> 
            }
            
            if (isValidElement(val)) {
              
              // Link to Doc content
              return <PageItem 
                  key={`ugnav-${idx}`}      // React Key
                  groupID=''                // If parent is a folder
                  pageID={key}              // key of Doc
                  currentPageID={currentPage}   // Different styling for current page
                  onPageClick={() => onPageChange(key)}    // click to change page
                />
            } 

            // Link Gorup
            return <UserGuideGroup 
                key={`ugnav-${idx}`}
                groupID={key} 
                subItems={val} 
                currentPageID={currentPage} 
                onPageClick={onPageChange}
              />

          })}
        
        </List>
      </Drawer>

      <DocMain open={openDrawer} className="userguide">
        {_.get(DocsSiteMap, currentPage)}
      </DocMain>
    </Box>
  );
}