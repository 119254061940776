import { useContext, useState } from 'react';
import ConfirmationDialog from '../../../../../components/ConfirmationDialog.js';
import { DIALOG_ACTION_TYPE } from '../../../../../components/DialogComponent.js';
import { ScheduleContext, SCHEDULE_CONTEXT_ACTION_TYPE } from '../../../../../context/ScheduleContext.js';
// Library 
import { useTranslation } from 'react-i18next';

// mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
// icon
import CancelIcon from '@mui/icons-material/Cancel';



const ScenarioCardFrame = (props) => {

  const { logo, alt, isReadOnly } = props;

  const { scheduleState: { assetIDCtx }, scheduleDispatch } = useContext(ScheduleContext);
  const [hoveringState, setHoveringState] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [delErrorMsg, setDelErrorMsg]     = useState();
  const { t } = useTranslation();

  const closeConfirmationDialog = () => {
    setHoveringState(false);
    setConfirmDialog(false);
  }

  const performDel = () => {

    setDelErrorMsg("");

    if (props.onDel && props.onDel()) {
      // Success
      closeConfirmationDialog();
    } else {
      // Fail
      setDelErrorMsg( t('commonError.errorOccupiedRetry') );
    }

  }

  const performHover = (isHover) => {
    // console.log('hover card', logo.id);
    setHoveringState(isHover);
    scheduleDispatch({ type: SCHEDULE_CONTEXT_ACTION_TYPE.SET_PREVIEW_LOGO, payload: { assetID: assetIDCtx, logo: logo } })
  }
  
  return (
    <Box sx={{ position: 'relative' }}
      onMouseEnter={e=>performHover(true)} 
      onMouseLeave={e=>performHover(false)}      
    >
      <Card 
        elevation={hoveringState?4:0} 
        sx={{ width: '150px' }} 
      >

        <CardMedia component="img" draggable={false} image={logo.url} alt={alt} sx={{ height: '150px', objectFit: 'contain', backgroundColor: '#f3f3f3', padding: '2px', borderRadius: 0 }} />

        <CardContent sx={{ paddingTop: 1, paddingLeft: 0.5, paddingRight: 0.5 }}>
          {props.children}
        </CardContent>
      </Card>

      {!isReadOnly && hoveringState && <IconButton aria-label="delete image"
        size="small"
        onClick={e => { setDelErrorMsg(""); setConfirmDialog(true); }}
        sx={{ 
          position: 'absolute',
          top: "0", right: "0",
          transform: 'translate(40%, -40%)',
          backgroundColor: "#fff",
          padding: '2px',
          '&:hover, &:active': {
            backgroundColor: '#dfdfdf'
          }
        }}
      >
        <CancelIcon color="error" fontSize="large" />
      </IconButton>}

      
      <ConfirmationDialog 
        // key={"dialog-"+props.obj.id}
        open={confirmDialog}
        dialogOption={{ 
          desc: `${t('alert.confirmDel')}?`, 
          okActionType: DIALOG_ACTION_TYPE.async,
          okText: t("global.YES"), 
          okColor: 'error',
          okAction: performDel, 
          closeAction: closeConfirmationDialog,
          cancelText: t('global.NO'),
          actionErrorMsg: delErrorMsg,
          maxWidth: 'sm',
        }}
      />
      
    </Box>
  )
};
export default ScenarioCardFrame;