// import React from 'react';
import ReactDOM from 'react-dom/client';
// MUI
import CssBaseline from '@mui/material/CssBaseline';
import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Internationalization
import './i18n';
import * as Sentry from "@sentry/react";


(process.env.REACT_APP_E === 'l') && Sentry.init({
  dsn    : process.env.REACT_APP_SENTRY_DSN,
  release: `${process.env.REACT_APP_VERSION} (${process.env.REACT_APP_PIPELINE_BUILDID})`,
  environment: "Production",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: process.env.REACT_APP_REPLAY_SESSION_RATE,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: process.env.REACT_APP_REPLAY_ERROR_RATE,

  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        // "localhost", 
        /^https:\/\/(.*\.)?azurewebsites\.net(\/.*)?/i,
        /^http(s)?:\/\/(.*\.)?seamlessdigital\.com(\/.*)?/i,
      ],
    }),
    new Sentry.Replay()  // It won't work due to billing issue
  ],

});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <>  
    <CssBaseline />
    <App />
  </>
  // </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
