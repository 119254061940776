import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// MUI
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
// Icon
import CloseIcon from '@mui/icons-material/Close';

// Definitation
export const DIALOG_ACTION_TYPE = Object.freeze({
  button: "BUTTON",
  async: "ASYNC"
});

export const DialogOptionType = PropTypes.shape({
  // Visual
  title       : PropTypes.string,
  desc        : PropTypes.string,
  okText      : PropTypes.string,
  cancelText  : PropTypes.string,

  // Styling
  maxWidth    : PropTypes.string,
  fullWidth   : PropTypes.bool,
  okColor     : PropTypes.string,
  
  // Action
  okActionType      : PropTypes.oneOf([DIALOG_ACTION_TYPE.button, DIALOG_ACTION_TYPE.async]),
  okBtnDisabled     : PropTypes.bool,
  okActionInProgress: PropTypes.bool,
  okAction          : PropTypes.func,
  closeAction       : PropTypes.func,

  // Switches
  disableActionBar  : PropTypes.bool,
  hideCloseBtn      : PropTypes.bool,

  // Feedback & Error
  actionErrorMsg: PropTypes.string,
})


// Dialog Component
const DialogComponent = (props) => {

  const { open, dialogOption, ...p } = props;

  const { t } = useTranslation();

  const handleClose = () => {
    dialogOption.closeAction && dialogOption.closeAction();
  }

  const handleOK = () => {
    dialogOption.okAction && dialogOption.okAction();
    // handleClose();
  }


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby="alert-dialog-title"
      aria-describedby='alert-dialog-description'
      maxWidth={dialogOption.maxWidth ?? "lg"}
      fullWidth
      {...p}
    >

      {dialogOption.title && 
      <DialogTitle id="alert-dialog-title">
        {dialogOption.title}

        {(!dialogOption.hideCloseBtn ?? true) && <IconButton
          aria-label={t('global.close')}
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        ><CloseIcon /></IconButton>}
      </DialogTitle>}

      <DialogContent>

        {dialogOption.desc &&
        <DialogContentText id="alert-dialog-description">{dialogOption.desc}</DialogContentText>
        }

        {props.children}
      </DialogContent>

      {(!dialogOption.disableActionBar ?? true) && <DialogActions sx={{ mt: 1, p:2, pt: 1 }}>
        <Stack direction="row" alignItems='center' justifyContent='space-between' spacing={1} sx={{ flexGrow: 1 }}>
          <Stack direction="row">
            {dialogOption.closeAction && dialogOption.cancelText != null &&
              <Button onClick={handleClose} color="info">{props.dialogOption.cancelText ?? t("global.cancel")}</Button>
            }
          </Stack>
          <Stack direction="row">
            {dialogOption.okAction && 
              <LoadingButton 
                loading={dialogOption.okActionInProgress}
                disabled={dialogOption.okBtnDisabled || dialogOption.okActionInProgress}
                onClick={handleOK} 
                color={props.dialogOption.okColor ?? "primary"} 
                variant="contained"
              >{props.dialogOption.okText ?? t("global.OK")}</LoadingButton>
            }
          </Stack>
        </Stack>
      </DialogActions>}

    </Dialog>
  );

};

DialogComponent.propTypes = {
  open        : PropTypes.bool,

  dialogOption: DialogOptionType,
};

export default DialogComponent;