import { useState, useContext, useMemo } from 'react';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import { ScheduleContext, SCHEDULE_CONTEXT_ACTION_TYPE } from '../../../../context/ScheduleContext';
import { masterScheduleValidation } from '../../../../parser/ScenarioParser';
import { fulfilmentDataMassage, submitFulfilment } from '../../../../services/schedule.service';
import { SCHEDULE_STATUS } from '../../../../constant';
import ActionFeedbackDialog from '../../../../components/ActionFeedbackDialog';
// Library
import { useTranslation } from 'react-i18next';
// mui
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
// icon
import SaveIcon from '@mui/icons-material/Save';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';


const SUBMIT_RESULT = Object.freeze({
  NONE: 0,
  SUCCESS: 1,
  FAIL: -1,
});


const ActionPanel = (props) => {

  const { scheduleState: { screenTriggersCtx, hasChanges, eventIDCtx, resourceIDCtx, screenIDCtx }, scheduleDispatch } = useContext(ScheduleContext);
  const { t } = useTranslation();

  const [asyncFlag, setAsyncFlag]                               = useState(false);
  const [confirmationDialogOption, setConfirmationDialogOption] = useState(null);
  const [submitResult, setSubmitResult]                         = useState(SUBMIT_RESULT.NONE);
  const masterScheduleStatusFlag = useMemo(() => masterScheduleValidation(screenTriggersCtx), [screenTriggersCtx]);

  // API related
  const fetchScheduleData = (downloadAgain = false) => {

    if (downloadAgain) {
      // TODO: Download data again
    }

    let scheduleData = fulfilmentDataMassage(eventIDCtx, resourceIDCtx, screenIDCtx);
    scheduleDispatch({ 
      'hasChanges': false,
      'screenTriggersCtx': scheduleData,
    });
  }

  // Interaction
  const closeDialog = () => setConfirmationDialogOption(null);
  const closeAndNoChange = () => scheduleDispatch({ hasChanges: false });   // eslint-disable-line no-unused-vars

  const performDiscard    = () => {
    setAsyncFlag(true); // Disable button

    fetchScheduleData();
    
    // Reset Preview Logo
    scheduleDispatch({ type: SCHEDULE_CONTEXT_ACTION_TYPE.DEL_PREVIEW_LOGO });

    closeDialog();
    setAsyncFlag(false);  // Enable button
  };


  // Save Related
  const performSave = () => {
    setAsyncFlag(true);
    
    submitFulfilment(eventIDCtx, resourceIDCtx, screenIDCtx, screenTriggersCtx)
      .then(
        r => {
          closeDialog();
          setSubmitResult(SUBMIT_RESULT.SUCCESS);
          setAsyncFlag(false);    
        },
        e => {
          closeDialog();
          setSubmitResult(SUBMIT_RESULT.FAIL);
          setAsyncFlag(false);    
        }
      )

  };

  const closeFeedback = () => {
    setSubmitResult(SUBMIT_RESULT.NONE); 
    if (submitResult === SUBMIT_RESULT.SUCCESS)
      scheduleDispatch({ hasChanges: false });
  }

  // Default Dialog Option
  const dialogDisardOption = Object.freeze({
    // title   : t("global.discard"),
    desc    : t("alert.discardDesc"),
    okText  : t("schedulePage.yesDiscardBtn"),
    okColor : "error",
    cancelText: t('global.NO'),
    maxWidth: 'sm',
    okAction   : performDiscard,
    closeAction: closeDialog,
  });
  const dialogDraftOption = Object.freeze({
    // title   : t("global.saveDraft"),
    desc    : t("alert.draftDesc"),
    okText  : t("alert.saveDraftBtn"),
    okColor : "warning",
    cancelText: t("global.NO"),
    maxWidth: "sm",
    okAction   : performSave,
    closeAction: closeDialog
  });
  const dialogSaveOption = Object.freeze({
    // title   : t("global.saveNsubmit"),
    desc    : t("alert.saveDesc"),
    okText  : t("alert.saveSubmitBtn"),
    cancelText: t("global.NO"),
    okColor : "success",
    maxWidth: "sm",
    okAction   : performSave,
    closeAction: closeDialog
  });


  return (
    <Box sx={{ 
      position: "fixed", 
      left: 0, 
      right: 0, 
      bottom: 0, 
      backgroundColor: "common.white", 
      borderTop: 'solid 1px rgba(0,0,0, 0.2)', 
      pt: 1, pb: 1.5
    }}
    >
      <Container maxWidth="xl" >

        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Fade in={hasChanges}>
              <Button 
                disabled={confirmationDialogOption !== null || asyncFlag}
                variant="outlined" 
                color="error"
                onClick={() => setConfirmationDialogOption(dialogDisardOption)}
              >
                {t("schedulePage.discardBtn")}
              </Button>
            </Fade>
          </Box>

          <Box>
            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={1}>
                {masterScheduleStatusFlag === SCHEDULE_STATUS.INCOMPLETE && 
                  <Fade in={true}>
                    <LoadingButton 
                      disabled={confirmationDialogOption !== null || asyncFlag}
                      loading={asyncFlag}
                      loadingPosition="start" 
                      startIcon={<SaveIcon />} 
                      variant="outlined" 
                      color="secondary"
                      onClick={() => setConfirmationDialogOption(dialogDraftOption)}
                    >
                      {t("global.saveDraft")}
                    </LoadingButton>
                  </Fade>
                }

                {masterScheduleStatusFlag !== SCHEDULE_STATUS.INCOMPLETE && 
                  <Fade in={true}>
                    <LoadingButton
                      disabled={confirmationDialogOption !== null || asyncFlag}
                      loading={asyncFlag}
                      loadingPosition="start"
                      startIcon={<CloudUploadIcon />}
                      variant="contained"
                      color="primary"
                      onClick={() => setConfirmationDialogOption(dialogSaveOption)} 
                    >
                      {t("global.saveNsubmit")}
                    </LoadingButton>
                  </Fade>
                }
              </Stack>

              {false && <Stack direction="row">
                <Alert severity="success">{t("scenario.draftSuccess")}</Alert>
                <Alert severity="error">{t("scenario.draftFail")}</Alert>
              </Stack>}
            </Stack>
          </Box>
        </Stack>

        {(confirmationDialogOption !== null) && 
          <ConfirmationDialog 
            open={true}
            dialogOption={confirmationDialogOption}
          />
        }
      </Container>

      <ActionFeedbackDialog 
        open={ [SUBMIT_RESULT.SUCCESS, SUBMIT_RESULT.FAIL].includes(submitResult) }
        hideTitleClose
        onClose={closeFeedback}
      >

        <Stack alignItems="center" justifyContent="flex-start">
          {submitResult === SUBMIT_RESULT.SUCCESS &&
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 150, textAlign: 'center', mx: 4 }} />}
          
          {submitResult === SUBMIT_RESULT.FAIL &&
          <ErrorOutlineIcon color="error" sx={{ fontSize: 150, textAlign: 'center', mx: 4 }} />}

          <Typography>
            { (submitResult === SUBMIT_RESULT.SUCCESS) ? t('alert.saveSuccess') : t('alert.saveFail') + " " + t('alert.plsTryAgain')  }
          </Typography>

          <Button variant='outlined' onClick={closeFeedback} sx={{ mt: 4 }}>{t("global.close")}</Button>
        </Stack>

      </ActionFeedbackDialog>

    </Box>
  );

}
export default ActionPanel;