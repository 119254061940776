import { useContext, useEffect } from 'react';
// Components
import { SITE_MAP_URL } from '../../constant';
import { Header, Footer } from '../../site-template/SiteFrame';
import Selection from './Selection.js';
import Management from './Management.js';
// Services
import { getAccountID, getApplicationID } from '../../services/application.service';
import { assetIdToScreenId } from '../../services/schedule.service';
// Context
import { ScheduleContext } from '../../context/ScheduleContext';
import { EventDelegatorContextProvider } from '../../context/EventDelegatorContext';
// Library
import { Router, useMatch, navigate } from '@reach/router';
import _ from 'lodash';

const Event = () => {

  const { scheduleState: { accountIDCtx, applicationIDCtx, eventIDCtx, resourceIDCtx, assetIDCtx }, scheduleDispatch } = useContext(ScheduleContext);

  const urlMatch3 = useMatch(SITE_MAP_URL.EVENT + SITE_MAP_URL.EVENT_MANAGEMENT + SITE_MAP_URL.PARAM_EVENT_ID + SITE_MAP_URL.PARAM_DRIVER_ID + SITE_MAP_URL.PARAM_ASSET_ID);
  const urlMatch2 = useMatch(SITE_MAP_URL.EVENT + SITE_MAP_URL.EVENT_MANAGEMENT + SITE_MAP_URL.PARAM_EVENT_ID + SITE_MAP_URL.PARAM_DRIVER_ID + SITE_MAP_URL.ANY);
  const urlMatch1 = useMatch(SITE_MAP_URL.EVENT + SITE_MAP_URL.EVENT_MANAGEMENT + SITE_MAP_URL.PARAM_EVENT_ID + SITE_MAP_URL.ANY);
  const urlRoot   = useMatch(SITE_MAP_URL.EVENT);

  /**
   * IMPORTANT: After URL changed or page reloaded, 
   * Check all context ID is sync with the situation rebuild Context ID
   * 
   * Rebuild Context ID
   */
  useEffect(() => {

    // TODO: if Account ID RAW data is missing -> 
    // TODO: if Application RAW data is missing -> 
    // TODO: if event RAW data is missing -> 
    if (urlRoot) { 

    } else {

      // TODO: Redirect with Alert, info user the data lost and reload/retry
      const coreID = [getAccountID(), getApplicationID()];
      if ( _.compact( coreID ).length === 0 ) {
        navigate(SITE_MAP_URL.PRELOAD);
      }

      const rebuild = {};
  
      // Core IDs ---------------------
      if (accountIDCtx === ''     && accountIDCtx    !== getAccountID())      rebuild["accountIDCtx"]     = getAccountID();
      if (applicationIDCtx === '' && applicationIDCtx !== getApplicationID()) rebuild["applicationIDCtx"] = getApplicationID();
      
      // Schedule related IDs ---------
      // Prepare IDs
      const aID = urlMatch3 ? urlMatch3.assetID      : "";
      const sID = urlMatch3 ? assetIdToScreenId(aID) : "";
      const rID = urlMatch2 ? urlMatch2.driverID     : "";
      const eID = urlMatch1 ? urlMatch1.eventID      : "";
      
      // Check if needed
      if (aID !== assetIDCtx) {
        rebuild["assetIDCtx"]  = aID;
        rebuild["screenIDCtx"] = sID;
      }
      if (rID !== resourceIDCtx) {
        rebuild["resourceIDCtx"] = rID;
      }
      if (eID !== eventIDCtx) {
        rebuild["eventIDCtx"] = eID;
      }
     
      if (_.size(rebuild)) {
        // process.env.NODE_ENV === "development" && console.log("*** Context ID Rebuild", rebuild)
        scheduleDispatch(rebuild);
      };

    }
    
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ // accountIDCtx, applicationIDCtx, assetIDCtx, eventIDCtx, resourceIDCtx, 
    urlMatch1, urlMatch2, urlMatch3,
    scheduleDispatch
  ]);

  return <>
    <Header />
    <EventDelegatorContextProvider>
      <Router>
        <Selection default />
        <Management path={SITE_MAP_URL.EVENT_MANAGEMENT + SITE_MAP_URL.PARAM_EVENT_ID + SITE_MAP_URL.ANY} />
      </Router>
    </EventDelegatorContextProvider>
    <Footer />
  </>
}
export default Event;