import { trim, isArray, isString } from 'lodash';
import moment from 'moment-timezone';

// - Helper Fn ----------------------------------
/**
 * C# Datetime.ticks to Javascript Date object
 * @param {int64} csDatetimeTick - C# datetime.ticks in int64 
 * @returns return Javascript Date object
 */
export const csDatetimeTickToJSDate = (csDatetimeTick) => {
  const ticksToMicrotime = csDatetimeTick / 10000;
  const epochMicrotimeDiff = 621355968000000000 / 10000;  // 1/1/1970

  return new Date(ticksToMicrotime - epochMicrotimeDiff);
}

/**
 * C# Datetime.ticks to Moment.JS object
 * @param {*} csDatetimeTick C# datetime.ticks in int64
 * @returns return Moment.JS object
 */
export const csDatetimeTickToMomentJS = (csDatetimeTick) => {
  const d = csDatetimeTickToJSDate(csDatetimeTick);
  return moment(d).utc();
}

/**
 * Moment.JS to C# Datetime.ticks
 * @param {*} mjs - Moment.JS object
 * @returns int64 - C# Datetime.Ticks in UTC timezone
 */
export const MomentJSTocsDatetimeTick = (mjs = moment()) => {
  return JSDateTocsDatetimeTick(mjs.toDate());
}

export const JSDateTocsDatetimeTick = (jsd = new Date()) => {
  return 621355968000000000 + (jsd.getTime() * 10000);
}


export const formatEventDateRange = (startD, endD, UTC = true) => {
  const sDate = UTC ? moment.utc(startD) : moment(startD);
  const eDate = UTC ? moment.utc(endD) : moment(endD);

  let txt = sDate.format( (sDate.month() === eDate.month()) ? "D" : "D MMM");
  txt += ` - ${eDate.format("D MMM YYYY")}`;

  return txt;
}

// - Local Storage Core -------------------------
/**
 * Local Storage Utilities Get function
 * @param {string} key 
 * @param {*} defaultVal 
 * @returns return value stored
 */
export const getStoredValue = (key, defaultVal = null, saveIfMissing = false, toLocalStorage = true) => {
  try {
    let sVal = localStorage.getItem(key);

    if (sVal && sVal.length > 0) {
      try {
        sVal = JSON.parse(sVal);
      } catch (e) {}
      
      return sVal;
    }

    if (saveIfMissing) {
      setStoreValue(key, defaultVal, toLocalStorage);
    }
  } catch (error) {
    process.env.NODE_ENV === "development" 
      && console.error(error);
    return defaultVal;
  }

  return defaultVal;
};

/**
 * Store giving value into browser local storage
 * @param {string} key 
 * @param {string/JSON} val 
 */
export const setStoreValue = (key, val, toLocalStorage = true) => {
  // if (typeof window === "undefined")
  //   throw new Error(
  //     "Local Storage isn't available in server-rendering environments"
  //   );

  try {
    
    let targetStorage = localStorage;
    targetStorage.setItem(key, val);

  } catch (error) {
    throw error;
  }
};


export const removeStorage = (key, toLocalStorage = true) => {

  if (typeof window === "undefined")
    throw new Error(
      "Local Storage isn't available in server-rendering environments"
    );

  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    throw error;
  }

}


/**
 * Display debug message ONLY on development environment.
 * 
 * @param {string | object} msg 
 * @param {LOGGING_LEVEL} level
 */
export const LOGGING_LEVEL = Object.freeze({
  log    : 0,
  warning: 1,
  error  : 2
});
export const logging = (msg, level = LOGGING_LEVEL.log, groupDepth = 0) => {

  if (process.env.NODE_ENV !== "development") return;

  for (let g = 0; g < groupDepth; g++) console.group();

  switch(level) {

    case LOGGING_LEVEL.log:
      isArray(msg) && console.table(msg);
      isString(msg) && console.log(msg);
      break;

    case LOGGING_LEVEL.warning:
      console.warn(msg);
      break;

    case LOGGING_LEVEL.error:
      console.error(msg);
      break;
    
    default:
      break;

  }

  for (let g = 0; g < groupDepth; g++) console.groupEnd();
}


export const getCookiesInObject = (attr = null) => {

  let pairs = document.cookie.split(';') ?? "";
  let splittedPairs = pairs.map(cookie => cookie.split("="));

console.log("getCookiesInObject")
console.dir(pairs)
console.log(document.cookie)

  const cookieObj = splittedPairs.reduce((obj, cookie) => {
    obj[decodeURIComponent( trim(cookie[0]) )] = decodeURIComponent( trim(cookie[1]) );
    return obj;
  }, {})

  return (attr === null) ? cookieObj : cookieObj[attr];
}