const APPLICATION_CONSTANT = Object.freeze({
  // - User Token & Role --------------
  TOKEN_CREATION_TIME: (process.env.NODE_ENV === "production") ? "scct" : "cookie_creation_time", 
  TOKEN_PAYLOAD : (process.env.NODE_ENV === 'production') ? "xtp" : "tokenPayload",
  USER_ROLES    : (process.env.NODE_ENV === 'production') ? "xur" : "userRoles",
  USER_ID       : (process.env.NODE_ENV === 'production') ? "cu" : "userId",
  REMEMBER_ME   : (process.env.NODE_ENV === 'production') ? "xrl" : "rememberLogin",
  ACCOUNT_ID    : (process.env.NODE_ENV === 'production') ? "ca" : "accountId",
  USER_PROFILE  : (process.env.NODE_ENV === 'production') ? 'upro' : 'userProfile',

  // Application Related --------------
  APPLICATION_ID      : (process.env.NODE_ENV === 'production') ? "cp" : "applicationId",
  APPLICATION_RAW_DATA: (process.env.NODE_ENV === 'production') ? "dp" : "App_RAW_Data",
  EVENT_RAW_DATA      : (process.env.NODE_ENV === 'production') ? "de" : "Event_RAW_Data",
  SELECTED_EVENT_ID   : (process.env.NODE_ENV === 'production') ? "ue" : "Selected_Event_ID",
  FULFILMENT_RAW_DATA : (process.env.NODE_ENV === "production") ? "df" : "FULFILMENT_RAW",
  CLIENT_BUSINESS_NAME: (process.env.NODE_ENV === "production") ? "bn" : "BUSINESS_NAME",
  APPLICATION_TYPE    : (process.env.NODE_ENV === 'production') ? "ct" : "applicationType",
  
  // Media Related --------------------
  // MEDIA_IMAGE_RAW_DATA   : (process.env.NODE_ENV === 'production') ? "q": "Img_RAW_Data",
  MEDIA_IMAGE_Parsed_DATA: (process.env.NODE_ENV === 'production') ? "dm": "Img_Parsed_Data",
});
export default APPLICATION_CONSTANT;


export const SCHEDULE_STATUS = Object.freeze({
  BLANK     : 1,
  INCOMPLETE: 2,
  COMPLETE  : 3,
});

export const EVENT_VERSION_VALIDATION_STATUS = Object.freeze({
  PENDING : 1,
  APPROVED: 2,
  REVOKED : 3,
  DELETED : 4,
})

export const SCREEN_TRIGGER_TYPE = Object.freeze({
  FIXED_RANGE: 1,
  SLIDESHOW  : 2,
});

export const RESET_PASSWORD_MODE = Object.freeze({
  RESET : 0,
  CHANGE: 1
});

export const SITE_MAP_URL = Object.freeze({
  ANY     : "/*",
  NOTFOUND: "/404",

  HOMEPAGE         : "/",
  LOGIN            : "/",
  LOGOUT           : "/logout",
  FORGET_PASSWORD  : "/reset_password", 
  RESET_PASSWORD   : "/reset_password",
  PRELOAD          : "/preload",
  EVENT            : "/event",
  EVENT_MANAGEMENT : "/management",
  DOWNLOAD_SCHEDULE: "/download",
  LOGO_LIBRARY     : "/library",
  USER_GUIDE       : "/help",

  // Azure Health check
  AZURE_HEALTH_CHECK: "/vVLFOXIfdB",

  // params
  PARAM_EVENT_ID : "/:eventID",
  PARAM_ASSET_ID : "/:assetID",
  PARAM_DRIVER_ID: "/:driverID"
});

export const SORTING_ORDER = Object.freeze({
  ASC : 1,
  DESC: 2,
});

export const IMAGE_ROTATION_TYPES = Object.freeze({
  RotateNone        : 0,
  Rotate180FlipNone : 1,  // Rotate180FlipNone : 2  // Specifies a 180-degree clockwise rotation without flipping.
  Rotate90FlipNone  : 2,  // Rotate90FlipNone  : 1  // Specifies a  90-degree clockwise rotation without flipping.
  Rotate270FlipNone : 3,  // Rotate270FlipNone : 3  // Specifies a 270-degree clockwise rotation without flipping.
  // Additionally,
  Rotate180FlipXY   : 4,  // Rotate180FlipXY   : 0  // Specifies a 180-degree clockwise rotation followed by a horizontal and vertical flip.
  RotateNoneFlipNone: 5,  // RotateNoneFlipNone: 0  // Specifies no           clockwise rotation and no flipping.
  Rotate270FlipXY   : 6,  // Rotate270FlipXY   : 1  // Specifies a 270-degree clockwise rotation followed by a horizontal and vertical flip.
  RotateNoneFlipXY  : 7,  // RotateNoneFlipXY  : 2  // Specifies no           clockwise rotation followed by a horizontal and vertical flip.
  Rotate90FlipXY    : 8,  // Rotate90FlipXY    : 3  // Specifies a  90-degree clockwise rotation followed by a horizontal and vertical flip.
  Rotate180FlipY    : 9,  // Rotate180FlipY    : 4  // Specifies a 180-degree clockwise rotation followed by a vertical flip.
  RotateNoneFlipX   : 10, // RotateNoneFlipX   : 4  // Specifies no           clockwise rotation followed by a horizontal flip.
  Rotate270FlipY    : 11, // Rotate270FlipY    : 5  // Specifies a 270-degree clockwise rotation followed by a vertical flip.
  Rotate90FlipX     : 12, // Rotate90FlipX     : 5  // Specifies a  90-degree clockwise rotation followed by a horizontal flip.
  Rotate180FlipX    : 13, // Rotate180FlipX    : 6  // Specifies a 180-degree clockwise rotation followed by a horizontal flip.
  RotateNoneFlipY   : 14, // RotateNoneFlipY   : 6  // Specifies no           clockwise rotation followed by a vertical flip.
  Rotate270FlipX    : 15, // Rotate270FlipX    : 7  // Specifies a 270-degree clockwise rotation followed by a horizontal flip.
  Rotate90FlipY     : 16, // Rotate90FlipY     : 7  // Specifies a  90-degree clockwise rotation followed by a vertical flip.
});