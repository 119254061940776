import { createContext, useReducer } from 'react';
import _ from 'lodash';



// Event Management Status 
const EVENT_MANAGEMENT_ISSUE = Object.freeze({
  INVALID_RESOURCE_ID: 901,
  INVALID_SCREEN_ID  : 902,
});

const EVENT_MANAGEMENT_DELEGATOR_NOTIFICATION_TYPE = Object.freeze({
  ERROR_FOUND: "err_found",
  ERROR_GONE : "err_gone",
});


// Initial / Empty State
const initialState = Object.freeze({
  issueListCtx: [],
});


const eventDelegatorReducer = (state, action) => {

  const { type, payload } = action;
  const payloadArr = _.compact( _.castArray(payload) );

  switch(type) {

    case EVENT_MANAGEMENT_DELEGATOR_NOTIFICATION_TYPE.ERROR_FOUND:
      return { issueListCtx: _.union(state.issueListCtx, payloadArr) }

    case EVENT_MANAGEMENT_DELEGATOR_NOTIFICATION_TYPE.ERROR_GONE:
      return { issueListCtx: _.difference(state.issueListCtx, payloadArr) }

    default: 
      return state;
  }

}

const EventDelegatorContext = createContext();

const EventDelegatorContextProvider = ({ children }) => {
  const [eventDelegatorState, eventDelegatorDispatch] = useReducer(eventDelegatorReducer, initialState);

  return (
    <EventDelegatorContext.Provider value={{ eventDelegatorState, eventDelegatorDispatch }}>
      {children}
    </EventDelegatorContext.Provider>
  )
}

export { 
  EventDelegatorContext, 
  EventDelegatorContextProvider, 
  EVENT_MANAGEMENT_DELEGATOR_NOTIFICATION_TYPE, 
  EVENT_MANAGEMENT_ISSUE 
};