import { useState, useEffect, useMemo, useContext } from 'react';
// Library
import _ from 'lodash';
import { Container as DnDContainer, Draggable } from 'react-smooth-dnd';
import { v4 as uuidv4 } from 'uuid';
// Components
import { ScheduleContext, SCHEDULE_CONTEXT_ACTION_TYPE } from '../../../../../context/ScheduleContext';
import { slideshowScenarioValidator } from '../../../../../parser/ScenarioParser';
import ScenarioFrame, { EnumScenarioStatus } from './ScenarioFrame';
import SecondWithSequenceCard from '../Cards/SecondWithSequenceCard';
import AddImageCard from '../Cards/AddImageCard';
import { SCHEDULE_STATUS } from '../../../../../constant';


const SlideShowScenario = (props) => {

  const { triggerDic, containerWidth, isReadOnly } = props;

  // Context ----------------
  const { scheduleState: { screenTriggersCtx }, scheduleDispatch } = useContext(ScheduleContext);

  const updateScheduleCtx = (flattenedList) => {

    const p = {};
    p[triggerDic.triggerId] = { id: triggerDic.triggerId, multimedia: flattenedList };

    scheduleDispatch({
      type: SCHEDULE_CONTEXT_ACTION_TYPE.UPDATE_TRIGGER,
      payload: p
    });
  }

  // useState / useMemo -----
  const [scenarioError, setScenarioError] = useState();
  const itemsPerRow = useMemo(() => {
    const cnt = Math.floor( (containerWidth - (16 * 2) - 30) / 170);
    // console.log("useMemo-itemsPerRow", containerWidth, cnt);
    return isNaN(cnt) ? 1 : Math.max(1, cnt);
  }, [containerWidth]);
  

  const [imgGroup, setImgGroup] = useState([[]]);
/*   const imgGroup = useMemo(() => {
      const l = screenTriggersCtx[triggerDic.triggerId].multimedia;
      return (_.size(l) > 0) ? _.chunk(l, itemsPerRow) : [[]];
    }, 
    [itemsPerRow, screenTriggersCtx, triggerDic.triggerId]
  );
 */
  const imgGroupAfterDrag = [];


  // Drag and Drop --------------------
  const onDrop = (idx, e) => {

    process.env.NODE_ENV === "development" && console.log("onDrop", idx, e);

    const { removedIndex, addedIndex } = e;

    imgGroupAfterDrag[idx] = _.cloneDeep(imgGroup[idx]);
    
    // if Nothing changed (both add & remove === null), keep it
    // else, Perform following changes
    if (removedIndex != null || addedIndex != null) {

      // Add ONLY 
      if (removedIndex === null) {
        imgGroupAfterDrag[idx].splice(addedIndex, 0, e.payload);
      }
      // Remove ONLY
      else if (addedIndex === null) {
        imgGroupAfterDrag[idx].splice(removedIndex, 1);
      }
      // Move in the same Row
      else {

        // Move Forward
        if (removedIndex > addedIndex) {
          imgGroupAfterDrag[idx].splice(removedIndex, 1);
          imgGroupAfterDrag[idx].splice(addedIndex, 0, e.payload);
        }
        // Move Backward
        else if (removedIndex < addedIndex) {
          imgGroupAfterDrag[idx].splice(addedIndex+1, 0, e.payload);
          imgGroupAfterDrag[idx].splice(removedIndex, 1);
        }
        // Move to SAME position, IGNORE IT
        // it means, addedIndex === removedIndex

      }
    }
    

    // Last drop event (single drop action will trigger multi drop event)
    // So, save it on the last event
    if (idx === imgGroup.length - 1) {
      // Reorder Image Seq, and
      // Save (update) new data
      const finalAry = reorderImgSeq( imgGroupAfterDrag );
      setImgGroup( finalAry );
      updateScheduleCtx( _.flatten(finalAry) );

      // Clean up, empty the imgGroupAfterDrag
      imgGroupAfterDrag.splice(0, imgGroupAfterDrag.length);
    }

  } // onDrop End
  
  const reorderImgSeq = (imgG) => {

    const isChunked = (_.isArray(imgG) && _.isArray(imgG[0]) );

    let fullG = isChunked ? _.flatten(imgG) : _.clone(imgG);

    fullG = fullG.map((g,i) => { 
      g.slots[0].end = g.slots[0].start = (i + 1); 
      return g; 
    });

    process.env.NODE_ENV === "development" && console.log("reorderImgSeq", fullG);

    return isChunked ? _.chunk(fullG, itemsPerRow) : fullG;
  }

  // Data Handling
  const onValueChanged = (idx, val) => {
    
    let flattened = _.flatten(imgGroup);

    flattened[idx-1].slots[0].duration = parseInt(val);
    
    setImgGroup( _.chunk(flattened, itemsPerRow) );
    updateScheduleCtx( flattened );

    completeDataValidation();
  }

  const performAdd = (imgObj) => { 
    // console.log(imgObj) 
    let target = _.flatten(imgGroup);
    imgObj.forEach(o => target.push({ 
      'id': o.id, 
      'url': o.url, 
      'uuid': uuidv4(),
      slots: [
        {
          start: 0,
          end: 0,
          duration: triggerDic.seconds.minimum,
        }
      ]
    }));

    target = reorderImgSeq(target);

    setImgGroup( _.chunk(target, itemsPerRow) );
    updateScheduleCtx(target);
  }

  const performDel = (uuid) => {

    let flattened = _.flatten(imgGroup);
    // const newList = _.reject(flattened, {id: imgID});
    // _.pullAt(flattened, idx-1);
    
    //console.warn('performDel', imgGroup, flattened);
    flattened = _.reject(flattened, { uuid: uuid });

    setImgGroup( _.chunk(flattened, itemsPerRow) );
    updateScheduleCtx(flattened);
  }

  const getAvailableSlot = () => (triggerDic.instances.maximum - getNumberOfImages());

  const hasAvailableSlot = () => (getAvailableSlot() > 0);


  // Validation  
  const completeDataValidation = () => setScenarioError(
    (slideshowScenarioValidator( _.flatten(imgGroup), triggerDic ) === SCHEDULE_STATUS.COMPLETE) 
      ? EnumScenarioStatus.Neutral 
      : EnumScenarioStatus.Error
  )

  const getNumberOfImages = () => _.flatten(imgGroup).length;

  const totalSecondVal = useMemo(() => {

    const flattened = _.flatten(imgGroup);

    // Guard
    if (flattened.length === 0) return 0;
    
    const val = new Intl.NumberFormat('en-US').format(
      _.sumBy(
        flattened, 
        o => o.slots[0].duration ?? 0
      )
    );
    // console.log("total second", val);

    return val;

  }, [imgGroup]);


  useEffect(() => {
    completeDataValidation();
    // console.log('useEffect', )
  });


  // Chunk again if container resized
  useEffect(() => {
    // console.log("useEffect(itemsPerRow)", itemsPerRow);

    setImgGroup( 
      (getNumberOfImages() === 0) ? [[]] 
                                  : _.chunk( _.flatten(imgGroup), itemsPerRow)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerRow]);
 

  useEffect(() => {
    const mmList = screenTriggersCtx[triggerDic.triggerId].multimedia;

    setImgGroup(
      (mmList.length === 0)
        ? [[]]
        : _.chunk( mmList, itemsPerRow )
    )

    // console.log("useEffect[imgGroup]", imgGroup, itemsPerRow);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenTriggersCtx, triggerDic.triggerId])


  return (
    <ScenarioFrame 
      isReadOnly={isReadOnly}
      triggerDic={triggerDic}
      scenarioStatus={scenarioError}
      // statusMessage={null}
      numberOfImages={getNumberOfImages()}
      totalNumberOfSecond={totalSecondVal}
      performDelScenario={props.performDelScenario}
    >

      {imgGroup.map((row,i) => <DnDContainer 
          key={`c${i}`} 
          groupName={triggerDic.id}
          orientation="horizontal"
          dropPlaceholder
          getChildPayload={k => row[k]}
          onDrop={e => onDrop(i, e)}
          style={{ marginBottom: '12px', }}
        >
          {row.map((logo,j) => <Draggable
              // key={`${logo.id}${(i*itemsPerRow)+(j+1)}`}
              key={logo.uuid}
              style={{ padding: "0 10px", cursor: 'grab', }}
            >
              <SecondWithSequenceCard 
                isReadOnly={isReadOnly}
                triggerDic={triggerDic}
                logo={logo} 
                idx={(i*itemsPerRow)+(j+1)} 
                onValueChanged={onValueChanged}
                performDel={performDel}
              />
            </Draggable>
          )}

          {// (i === imgGroup.length-1) && hasAvailableSlot() && (imgGroup[i].length < itemsPerRow) &&
          (!isReadOnly && hasAvailableSlot() && row.length < itemsPerRow && i === imgGroup.length-1) &&
          <AddImageCard 
            selectionQuota={getAvailableSlot()} 
            performAdd={performAdd} 
          />}
        </DnDContainer>
      )}

      {!isReadOnly && hasAvailableSlot() && (_.last(imgGroup)?.length === itemsPerRow) &&
      <AddImageCard 
        selectionQuota={getAvailableSlot()} 
        performAdd={performAdd} 
      />}

    </ScenarioFrame>
  );
}
export default SlideShowScenario;