import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// Components
import { LogoPropTypes, LogoPropTypesDefault } from '../../AppPropTypes';
// 3rd parties
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Stack, Chip, Typography, IconButton, Card, CardMedia, CardContent, Fade, Grid } from '@mui/material';

import './logoCard.scss';


const LogoCard = (props) => {

  // Context, Hooks, Const
  const {t} = useTranslation();

  // Runtime
  const [iAmSelected, setIAmSelected] = useState(props.isSelected);
  const [isHover, setIsHover]         = useState(false);
  
  
  // Logo title and tags --------------
  const logoSizeEle                       = useMemo(() => (
    <Typography variant='subbody' className="logoSizeTxt">
      {/* {props.logoObj.width} <span className="split">x</span> {props.logoObj.height}{t(`global.unit.${props.logoObj.dimensionsUnit}`)} */}
      {props.logoObj.width}<span className="split">x</span>{props.logoObj.height}{t(`global.unit.3`)}
    </Typography>
  ), [props.logoObj.width, props.logoObj.height, /* props.logoObj.dimensionsUnit, */ t]);

  const logoTitleEle                      = useMemo(() => 
    <Typography className='logoTitle'>{props.logoObj.title}</Typography>
  , [props.logoObj.title]);

  const logoTagsEle                       = useMemo(() => (

    ("tags" in props.logoObj) 
      && _.isArray(props.logoObj.tags)
      && <Stack direction='row' spacing={0.5} sx={{ flexWrap: 'wrap' }}>
        {props.logoObj.tags.map((t,i) => 
          <Chip label={_.trim(t)} size="small" color="primary" variant="outlined" className='logoTag' key={`tag-${props.logoObj.tags_id}-${i}`} />
        )}
      </Stack>      

  ), [props.logoObj]);


  // Click interaction ----------------
  const _onSelect = (e) => {
    e.stopPropagation();

    if (!props.selectable) return;
   
    if (iAmSelected || (!props.selectionLimitationReached && !iAmSelected)) {
      setIAmSelected( !iAmSelected ); // UI Toggle
      props.onSelect                  // Data Toggle, !iAmSelected because the flag has NOT been updated at this moment
        && props.onSelect( !iAmSelected, props.logoObj );
    }

  }

  const _onDel = (e) => { e.stopPropagation(); props.onDel && props.onDel(props.logoObj); }


  return (
    <Grid item xs={1}>
      <Card 
        onClick={_onSelect}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        sx={{ boxShadow: isHover ? 5 : 1 }}
        className={[
          'logoCard',
          (props.selectable && props.selectionLimitationReached) ? 'selectable selectionFull' : '',
          (props.selectable && iAmSelected) ? 'selectable selected' : '',
        ].join(' ')}
      >
        <Box className="logoWrapper">
          <CardMedia
            component='img' 
            src={props.logoObj.url} 
            alt={props.logoObj.title} 
            loading='lazy'
            draggable="false"
            className='logo'
            sx={{ height: '180px' }}
          />

          { // Selection UI effect
          props.selectable 
            && iAmSelected 
            && 
            <Box className='isSelected'>
              <CheckCircleIcon color="success" className='icon' />
            </Box>
          }
        </Box>

        <CardContent sx={{ px: 1 }}>
          {logoTitleEle}
          {props.showImgSize && logoSizeEle}
          {logoTagsEle}
        </CardContent>

        {/* Delete image */}
        {props.delable 
          && !iAmSelected 
          && isHover
          &&
          <Fade in={isHover}>
            <IconButton aria-label={`delete ${props.logoObj.title} logo`}
              size='small'
              onClick={_onDel}
              className='delBtn'
            >
              <CancelIcon color='error' fontSize='large' />
            </IconButton>
          </Fade>
        }
      </Card>
    </Grid>
  )

}


// Props Definition -------------------
LogoCard.propTypes = {
  logoObj: LogoPropTypes,

  // Behaviour switch
  showImgSize: PropTypes.bool,
  selectable: PropTypes.bool,
  isSelected: PropTypes.bool,
  // editable  : PropTypes.bool,  // for future
  delable   : PropTypes.bool,

  // selectable related
  selectionLimitationReached: PropTypes.bool,   // Decided by parent, because of limitation of selected reached, no more selection can be perform
  toggleLogoSelection : PropTypes.func,   // Logo toggle/selection feedback consumer fn
  

  onDel: PropTypes.func,
}
LogoCard.defaultProps = {
  ...LogoPropTypesDefault,

  showImgSize: false,
  selectable : false,
  isSelected : false,
  // editable   : false,
  delable    : false,

  // selectable related
  selectionLimitationReached: false,
  toggleLogoSelection       : undefined,  // Parent feedback


  onDel: undefined,
}
export default LogoCard;