import { useState, useContext, useMemo, useEffect } from 'react';
// Components
import ActionPanel from './ActionPanel';
import LoadingSkeleton from '../../../../components/LoadingSkeleton';
import { SomethingWrong } from '../../../../components/SomethingWrong';
import ScenarioSelection from './ScenarioSelection';
// Services
import { getHardwareScreensConfig } from '../../../../services/application.service';
// Context
import { ScheduleContext } from '../../../../context/ScheduleContext';
import { EventDelegatorContext } from '../../../../context/EventDelegatorContext';
// Library
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
// mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
// icon
import ExtensionIcon from '@mui/icons-material/Extension';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import CloneFulfilmentDialog from '../../../../components/CloneFulfilmentDialog';


const ScenarioContainerFrame = ({
  loadingFlag, 
  isReadOnly,
  errorMsg, 
  currentSelection,
  performAddScenario,
  children 
}) => {
  
  const { scheduleState: { hasChanges, assetIDCtx, screenTriggersCtx/*, eventIDCtx*/ } } = useContext(ScheduleContext);

  const { eventDelegatorState: { issueListCtx} } = useContext(EventDelegatorContext);
  const goodToProcess                            = useMemo(() => (issueListCtx.length === 0), [issueListCtx]);

  const [scenarioSelection, setScenarioSelection]   = useState(false);
  const [totalScenarioCount, setTotalScenarioCount] = useState(0);
  // const [openClonePanel, setOpenClonePanel]         = useState(false);
  
  const fulfilmentIsBlank = useMemo(() => _.size(screenTriggersCtx) === 0, [screenTriggersCtx]);
  const { t }        = useTranslation();

  // Behavior
  const closeScenarioSelection = () => setScenarioSelection(false);

  useEffect(() => {
    if (goodToProcess) {
      const targetScreen = getHardwareScreensConfig(assetIDCtx);
      setTotalScenarioCount(targetScreen[0]?.triggerGroup?.length ?? 0);
    }
  }, [assetIDCtx, goodToProcess]);

  if (!goodToProcess) return <></>;


  return <Box sx={{ mt: 3, width: "100%", overflow: "auto", flexGrow: 1 }}>
    
      <LoadingSkeleton enable={loadingFlag} />

      <SomethingWrong enable={errorMsg} title={errorMsg} showLogout center sx={{ mt: 4 }}></SomethingWrong>

      {fulfilmentIsBlank && !loadingFlag && !errorMsg &&
      <Alert severity='info'>{t('scenario.noScenario')}</Alert>}

      {children}

      {/* Add Scenario */}
      {!isReadOnly && !loadingFlag && !errorMsg && currentSelection.length < totalScenarioCount 
        && <Stack direction={"row"} alignItems="center" justifyContent="center" gap={4} mt={4}>
        
        {/* Clone Scenario
        <Button 
          variant='outlined'
          onClick={() => setOpenClonePanel(true)}
          size="large"
          startIcon={<ContentCopyIcon fontSize="2rem" />}
        >
          {t("cloneFulfilment.dialogTitle")}
        </Button>
        {openClonePanel &&
          <CloneFulfilmentDialog
            open={true}
            cloneMode="resourceClone"
            destinationEventID={eventIDCtx}
            shouldReloadData={() => (assetIDCtx) && window.location.reload()}
            dialogOption={{
              disableActionBar: true,
              closeAction: () => setOpenClonePanel(false)
            }}
          />
        } */}

        
        {/* Add Scenario */}
        <Button 
          variant="outlined"
          onClick={() => setScenarioSelection(true)}
          color="success" 
          size="large"
          startIcon={<ExtensionIcon fontSize="2rem" />}
        >
          {t("schedulePage.addScenarioBtn")}
        </Button>
        {scenarioSelection && <ScenarioSelection
          open={true} 
          dialogOption={{
            title: `${t("schedulePage.scenarioSelectionDialogTitle")}`,
            okAction: performAddScenario,
            closeAction: closeScenarioSelection,
            maxWidth: 'md',
          }}
          assetId={assetIDCtx}
          currentSelection={currentSelection}
        />}
      </Stack>}

      {/* Spacing reserve for Action Panel */}
      <Box sx={{ minHeight: '40px' }} />


      {!isReadOnly && hasChanges && <ActionPanel />}

    </Box>
  
};
export default ScenarioContainerFrame;