import coreTheme from "../masterTheme";
import { createTheme } from "@mui/material/styles";
import deepmerge from "deepmerge";

const branding = {
  highlight   : "#ccc",
  light       : "#d3d3d3",
  main        : "#c0c0c0",
  dark        : "#808080",
  contrastText: "#333",
};

const SDTheme = createTheme( deepmerge(coreTheme, {
  palette: { branding },
  
  components: {
  
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderBottomColor: branding.main,
        }
      }
    },  // MuiAppBar

    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: branding.main,
            color: branding.contrastText,
          },
          "&:hover, &.Mui-selected:hover": {
            backgroundColor: branding.light,
            color: branding.contrastText,
          }
        }
      }
    },  // MuiListItemButton

    MuiListItem: {
      styleOverrides: {
        root: {
          "&:hover:not(.isPast):not(.isUnselectable)": {
            backgroundColor: branding.highlight,
          },
          "&.isSelected": {
            backgroundColor: branding.highlight,
          }
        }
      }
    },  // MuiListItem

  },  // components

}));
export default SDTheme;