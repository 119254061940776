import { useState, useMemo, useEffect } from 'react';
import LoginPwdInput from './component/LoginPwdInput';
import { forgetPwd, performLogout, resetPwd } from '../../services/user.service';
// Library
import { useTranslation } from "react-i18next";
import * as Sentry from '@sentry/react';
// mui
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';

// icon
import LockResetIcon from '@mui/icons-material/LockReset';
import { Typography } from '@mui/material';
import _ from 'lodash';


export const ResetPasswordPanel = (props) => {

  const { usageMode, params } = props;

  const [pwdTxt,  setPwdTxt]      = useState("");
  const [pwd2Txt, setPwd2Txt]     = useState("");
  const [pwdValid,  setPwdValid]  = useState(false);
  const [pwd2Valid, setPwd2Valid] = useState(false);
  const [pwdCleanFlag, setPwdCleanFlag] = useState(false);

  const [submitSuccess, setSubmitSuccess]   = useState();
  const [submitErrorMsg, setSubmitErrorMsg] = useState();
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [keepBtnDisable, setKeepBtnDisable] = useState(false);

  const formValidationFlag = useMemo(() => (pwdValid && pwd2Valid), [pwdValid, pwd2Valid]);

  const { t } = useTranslation();
  

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formValidationFlag) return false;

    setSubmitSuccess(null);
    setSubmitErrorMsg(null);
    setLoadingFlag(true);

    const submitSuccess = (r) => { 
      setLoadingFlag(false); 
      setSubmitSuccess(t("loginPage.feedback.resetCompleted")); 
      setSubmitErrorMsg(null); 
      setPwdCleanFlag(true); 
      setKeepBtnDisable(true);
    }
    const submitFail    = (e) => { 

      // Error classification
      const eAry = _.castArray(e);

      // Checking the error code, server may return 40301 or 1000000 on the same situation
      if (_.filter(eAry, { "code": "1000000" }).length) {   // Invalid reset pwd token

        forgetPwd(params.email)
          .then(
            r => {
              setSubmitErrorMsg( t(`loginPage.error.1000000`) + " " + t("loginPage.feedback.success") );
              setKeepBtnDisable(true)
            },
            e => setSubmitErrorMsg( t("loginPage.feedback.error") )
          );
      } else if (_.filter(eAry, { "code": "40301" }).length) {

        setSubmitErrorMsg( t(`loginPage.error.40301`) );
        Sentry.captureException(new Error("Reset Password 40301"));

      } else {
        setSubmitErrorMsg( t("loginPage.feedback.error") ); 
        Sentry.captureException(e);
      }

      // Cleanup
      setLoadingFlag(false); 
      setSubmitSuccess(null); 
    }

    resetPwd(usageMode, { password: pwdTxt, confirmPassword: pwdTxt, ...params})
      .then(
        r => submitSuccess(r),
        e => submitFail(e)
      );
  }

  const onPwdChangeValidation = ([validFlag, pwdTxt]) => {
    setPwdTxt(pwdTxt);
    setPwdValid(validFlag);
  }
  const onPwd2ChangeValidation = ([validFlag, pwdTxt]) => {
    setPwd2Txt(pwdTxt);
    setPwd2Valid(validFlag);
  }

  // Automatically turn off the flag after reset completed
  useEffect(() => {
    (pwdCleanFlag) && setPwdCleanFlag(false);
  }, [pwdCleanFlag]);

  // One-off reset
  useEffect(() => {
    performLogout()
  })

  return <>
    <Box>
      <form
        noValidate
        onSubmit={handleSubmit}
      >
        <LoginPwdInput 
          key={"pwd1"} 
          onChangeValidation={onPwdChangeValidation} 
          toMatch={pwd2Txt}
          exam={true} 
          labelTxt={t("loginPage.newPwdLbl")}
          resetInputFlag={pwdCleanFlag}
        />
        <Typography variant='body2' sx={{ mt: 1, mb: "2px", pl: 1.5 }}>{t("loginPage.resetPwd.rulesTitle")}</Typography>
        <ul style={{ fontSize: '0.8rem', paddingInlineStart: '28px', marginBlockStart: 0 }}>
          <li>{t("loginPage.resetPwd.rule-1")}</li>
          <li>{t("loginPage.resetPwd.rule-2")}</li>
          <li>{t("loginPage.resetPwd.rule-3")}</li>
          <li>{t("loginPage.resetPwd.rule-4")}</li>
        </ul>

        <LoginPwdInput 
          key={"pwd2"} 
          onChangeValidation={onPwd2ChangeValidation} 
          toMatch={pwdTxt} 
          labelTxt={t("loginPage.pwdAgainLbl")}
          resetInputFlag={pwdCleanFlag}
        />
        <LoadingButton
          type="submit"
          loading={loadingFlag}
          loadingPosition="start"
          startIcon={<LockResetIcon />}
          fullWidth
          variant='contained'
          sx={{ mt: 3, mb: 2}}
          disabled={(loadingFlag || !formValidationFlag || keepBtnDisable)}
        >
          {t("loginPage.resetPwdBtn")}
        </LoadingButton>

      </form>
    </Box>

    <Box sx={{ mt: 1, width: '100%' }}>
      {submitErrorMsg && <Alert severity="error" sx={{ mt: 2 }}>
        <AlertTitle>{t('commonError.ErrorTitle')}</AlertTitle>
        {submitErrorMsg}
      </Alert>}

      {submitSuccess && <Alert severity="success" sx={{ mt: 2 }}>
        {submitSuccess} <a href="/">{t("loginPage.Login")}</a>
      </Alert>}
    </Box>
  </>;
}