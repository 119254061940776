import { Fragment, useState, useRef, useEffect, useContext } from "react";
// Component
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EventMeta from "../../components/EventMeta";
import LoadingSkeleton from "../../components/LoadingSkeleton";
import { SCHEDULE_STATUS, SITE_MAP_URL } from "../../constant";
// Services
import { getFullEventList, removeCurrentEventID } from "../../services/application.service";
import { ScheduleContext, SCHEDULE_CONTEXT_ACTION_TYPE } from "../../context/ScheduleContext";
// MUI
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
// 3rd Library
import { navigate } from "@reach/router";
import moment from 'moment-timezone';
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
// Icon
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import EditIcon from '@mui/icons-material/Edit';
import { SomethingMissingIcon } from "../../components/SomethingWrong";
import { Typography } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloneFulfilmentDialog from "../../components/CloneFulfilmentDialog";


const EventSelectionItem = (props) => {

  const { scheduleState: { applicationIDCtx }, scheduleDispatch } = useContext(ScheduleContext);
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false);

  const { t } = useTranslation();
  const userTZ = moment.tz.guess();

  // Interaction
  const dialogClose = () => setOpenScheduleDialog(false);

  // Helper funtion
  const performOpenSchedule = () => {
    // console.log('Perform Open Schedule' + props.obj.id);

    dialogClose()
    scheduleDispatch({
      type: SCHEDULE_CONTEXT_ACTION_TYPE.UPDATE_EVENT_ID,
      payload: props.obj.id
    });

    navigate(`${SITE_MAP_URL.EVENT}${SITE_MAP_URL.EVENT_MANAGEMENT}/${props.obj.id}`);
  }

  const eventDisplayName = () => {
    let eventName = props.obj.description ?? props.obj.title;
    
    let nicer = eventName.trim().split(" ");
    nicer = (nicer.length >= 2) ? nicer.map(n => n[0].toUpperCase() + n.substring(1))
                                : nicer;
    eventName = nicer.join(" ");
    
    return eventName;
  }

  const eventStatusChip = () => {
    const statusID = props.obj.status.id;
    const lastModifiedOn = props.obj.lastModifiedOn.utc;
    
    if (lastModifiedOn) {
      const lud = moment.utc(lastModifiedOn);
      return `${t("global.lastUpdate")} ${lud.tz(userTZ).fromNow()}`;
    } else {
      if (statusID === SCHEDULE_STATUS.BLANK) {
        return t("schedulePage.eventStatus.blank");
      } else if (statusID === SCHEDULE_STATUS.INCOMPLETE) {
        return t("schedulePage.eventStatus.incomplete");
      } else if (statusID === SCHEDULE_STATUS.COMPLETE) {
        return t("schedulePage.eventStatus.completed");
      }

      // consider it is ERROR, should be empty
      return "";
    }
  }

  // Clone Event related
  const [openCloneDialog, setOpenCloneDialog] = useState(false)


  return (
    <ListItem 
      className={props.isPast && props.obj.status.id === SCHEDULE_STATUS.BLANK ? "isPast" : ""}
    >
      {/* Event Name, place, time */}
      <EventMeta eventMeta={props.obj} /* dimmer={props.isPast} */ />


      {/* Event Status */}
      {props.obj.status.id === SCHEDULE_STATUS.COMPLETE &&
        <Stack direction='row' alignItems='center' spacing={1} maxWidth={'150px'}>
          <CheckCircleIcon color="success" fontSize="medium" />
          <Typography variant='body2'>{eventStatusChip()}</Typography>
        </Stack>
      }

      {props.obj.status.id === SCHEDULE_STATUS.INCOMPLETE &&
        <Stack direction='row' alignItems='center' spacing={1} maxWidth={'150px'}>
          <WarningAmberRoundedIcon color="warning" fontSize="medium" />
          <Typography variant='body2'>{eventStatusChip()}</Typography>
        </Stack>
      }

      {!props.isPast &&
        <Button
          variant="outlined"
          startIcon={<ContentCopyIcon fontSize="2rem" />}
          sx={{ ml: 1 }}
          onClick={() => { setOpenCloneDialog(true) }}
        >
          {t("cloneFulfilment.cloneBtn")}
        </Button>
      }
      {openCloneDialog && 
        <CloneFulfilmentDialog 
          open={true}
          applicationID={applicationIDCtx} 
          destinationEventID={props.obj.id} 
          shouldReloadData={() => { props.reloadDataFn && props.reloadDataFn(); }}

          dialogOption={{
            closeAction: () => { 
              setOpenCloneDialog(false);
            },
          }}
        />
      }
      

      {/* Completed Schedule */}
      {(!props.isPast || props.obj.status.id !== SCHEDULE_STATUS.BLANK)
        && <Button
        variant="outlined"
        // onClick={openSchedule}
        onClick={performOpenSchedule}
        aria-label={t("schedulePage.aria.openPastSchedule")}
        {...!props.isPast && {
          startIcon: <EditIcon fontSize="2rem" />
        }}
        sx={{ ml: 1 }}
      >
        {/* <CheckCircleIcon color={props.isPast ? "#777" : "success"} fontSize="large" /> */}
        {/* <EditIcon color={grey[500]} fontSize="2rem" /> */}
        {t(props.isPast ? "global.view" : "global.edit")}
      </Button>}

      
      <ConfirmationDialog 
        open={openScheduleDialog}
        dialogOption={{ 
          title: t("global.confirmation"), 
          desc: `${t("schedulePage.alert.confirmEditSchedule")} '${eventDisplayName()}'`, 
          okText: t("global.manage"), 
          okAction: performOpenSchedule, 
          closeAction: dialogClose,
          maxWidth: 'sm',
        }}
      />

    </ListItem>
  )

};

const IconNotation = () => {

  const { t } = useTranslation();
  const theme = useTheme();

  return <Grid container spacing={1}
    maxWidth="sm"
    alignItems="center"
    sx={{ mt: 4, pb: 0.5, border: 'solid 1px #ddd' }}
  >
    <Grid item xs={1} sx={{ textAlign: 'center' }}>
      <EditIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />
    </Grid>
    <Grid item xs={3}>{t("scenario.note-createSchedule")}</Grid>

    <Grid item xs={1} sx={{ textAlign: 'center' }}>
      <CheckCircleIcon color="success" fontSize="large" />
    </Grid>
    <Grid item xs={3}>{t("scenario.note-complete")}</Grid>

    <Grid item xs={1} sx={{ textAlign: 'center' }}>
      <WarningAmberRoundedIcon color="warning" fontSize="large" />
    </Grid>
    <Grid item xs={3}>{t("scenario.note-incomplete")}</Grid>
  </Grid>
}

const EventSelectionList = (props) => {

  const todayRef = useRef(null);
  const [shouldReload, setShouldReload] = useState(true);
  const [eventData, setEventData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const theme = useTheme();
  
  const today = moment.utc();
  let todayShow = false;
  // ----------------------------------
  // force moment to update the latest
  // It is USELESS now, but it will be call again under <EventSelectionItem />
  moment.tz.guess(true);
  // ----------------------------------

  const dividerProps = { variant: "middle" };
  const todayProps = {
    variant: "fullWidth",
    textAlign: "left",
    sx: {
      ":before, :after": { 
        borderTopColor: theme.palette.branding.main,
        borderTopWidth: '3px' 
      },
      color: theme.palette.branding.main,
      fontWeight: 'bold'
    },
  };

  // Fetch Event List from server
  useEffect(() => {

    if (shouldReload) {
      
      setIsLoading(true);
  
      getFullEventList(true)
        .then(d => setEventData(d))
        .catch(e => setEventData([]))
        .finally(() => {
          setIsLoading(false);
          setShouldReload(false);
        });

    }

  }, [shouldReload]);
  

  // Scroll to today
  useEffect(() => {

    // Scroll to
    (eventData.length > 0 && todayRef.current !== null) && setTimeout(() => {
      if (todayRef.current) {
        todayRef.current.scrollIntoView({behavior: "smooth", block: 'center'})
      }
    }, 250);

  }, [eventData])

  return (<>
    <LoadingSkeleton enable={isLoading} center />
    
    {!isLoading
      && eventData.length > 0
      && <>
        <Box sx={{ width: "90%", mb: 2 }} alignItems="flex-start">
          <Typography variant='subtitle1'>{t("schedulePage.pageSubTitle")}</Typography>
        </Box>

        <Paper
          elevation={1}
          sx={{
            // width: "100%",
            // maxWidth: '92%', //maxHeight: '90%',
            minWidth: '80%', //minHeight: '70%',
          }}
        >
          <List
            disablePadding
            sx={{
              height: `calc(100vh - ${120 + 138 + 50}px)`,
              overflow: 'auto',
              marginTop: 0, marginBottom: 0,
              paddingTop: 0, paddingBottom: 0,
            }}
          >

            {eventData.map((obj, i) => {

              const isPast = today.isAfter(obj.endBaseDate.utc);

              const todayFlag = (!todayShow && !isPast);
              if (todayFlag) todayShow = true;  // Once only, turn it off

              return <Fragment key={"f_selectItem" + i}>
                  {i > 0 &&
                    <Divider 
                      component="li" 
                      { ...(todayFlag ? todayProps : dividerProps) }
                      { ...(todayFlag ? {ref: todayRef} : {}) } // Scroll to 
                    >
                      {todayFlag ? t("global.nowEventSplit") : ""}
                    </Divider>
                  }

                  <EventSelectionItem 
                    key={"selectItem" + i} 
                    obj={obj} 
                    isPast={isPast} 
                    reloadDataFn={() => setShouldReload(true)}
                  />

                </Fragment>

            })}   {/* End of props.data.map */}

          </List>
        </Paper>
        <IconNotation />
      </>
    }

    {!isLoading 
      && eventData.length === 0
      && 
      <>
        <SomethingMissingIcon />
        <Typography variant="h6" sx={{ fontSize: "2rem", mt: 2 }}>
          {t("commonError.errorOccupied")}
        </Typography>
        <Typography>
          {t("commonError.errorOccupiedRetry")}
        </Typography>
        <Button variant='contained' onClick={() => navigate(SITE_MAP_URL.LOGOUT)}>{t("loginPage.Logout")}</Button>
      </>
    }
    </>
  );

};

const Selection = () => {

  const { scheduleDispatch } = useContext(ScheduleContext);
  const { t } = useTranslation();


  // Reset previous selection
  useEffect(() => {
    removeCurrentEventID();
    scheduleDispatch({
      type: SCHEDULE_CONTEXT_ACTION_TYPE.UPDATE_EVENT_ID,
      payload: "",
    })
  }, [scheduleDispatch]);

  return (
    <Container
      maxWidth="xl"
      component="main"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        pt: 3,
        width: "94%"
      }}
    >
      <Helmet>
        <title>{t("sitemap.select_event")} | {t("global.productNameShort")}</title>
      </Helmet>
      <EventSelectionList />
    </Container>
  );
};
export default Selection;