import { useState, useContext, useMemo } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import { postImage } from '../../services/media.service';
// 3rd parties
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Stack, TextField, Chip, Button, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Save as SaveIcon } from '@mui/icons-material';


const LogoCardInputArea = ({ imgObj, releaseLogo }) => {

  const { scheduleState: { accountIDCtx }} = useContext(ScheduleContext);
  const { t } = useTranslation();

  
  const [loadingFlag, setLoadingFlag]       = useState(false);
  const [submitErrorMsg, setSubmitErrorMsg] = useState("");
  
  const [titleTxt, setTitleTxt] = useState( imgObj.title?.trim() ?? "" );
  const [tagTxt, setTagTxt]     = useState("");
  const [chips, setChips]       = useState([]);
  
  const formValidate            = useMemo(() => titleTxt.length >= 3, [titleTxt]);


  const _addTagChip = () => {
    (tagTxt.trim().length > 0)
      && setChips( _.union(chips, [tagTxt.trim()]) )
    setTagTxt("");
  }

  const _onTagDelete = (idx) => {
    const newList = chips.filter(c => c !== chips[idx]);
    setChips(newList);
  }
  
  // API Related ---------
  const createLogoJSON = (logoObjAlong = true) => {
    const title = titleTxt; 
    let newImgObj = {...imgObj, title };

    if (chips.length > 0) {
      const attributes = [{ type: 12, "values": JSON.stringify(chips), "attribute": chips.join(" ") }];
      newImgObj = {...newImgObj, attributes};
    }

    return (logoObjAlong)
      ? newImgObj
      : {
        "accountId":  accountIDCtx,
        "multimedia": [newImgObj],
      };
  }
  
  const performSubmit = async (e) => {
    setLoadingFlag(true);
    
    // Prepare data
    const dataPackage = createLogoJSON(false);
    process.env.NODE_ENV === "development" 
      && console.log("package", dataPackage);

    postImage(dataPackage)
      .then(
        r => releaseLogo && releaseLogo(imgObj),
        e => {
          setLoadingFlag(false);
          process.env.NODE_ENV === "development" 
            && console.error("upload img error", e);

          //const formatErr = e.response.data.errors.map(e => `[${e.Code}] ${e.Description}`);
          setSubmitErrorMsg( t('commonError.logo.uploadError.msg') );
        }
      )
    
  }
  
  
  return <Stack spacing={1.5} sx={{ pt: 2, px: 0 }}>
    
    {/* Image Title */}
    <TextField 
      label={t("imageLib.uploadInputLabel")} 
      size="small" 
      required 
      
      value={titleTxt} 
      onChange={e => setTitleTxt(e.target.value)}
      error={!formValidate}
      helperText={formValidate ? null : t('commonError.logo.titleLen')}
    />


    {/* Image Tags Input */}
    {false && <TextField
      label={t("schedulePage.inputEle.tag")}
      helperText={t("schedulePage.inputEle.tagDesc")}
      size="small" 

      value={tagTxt}
      onKeyUp={e => (e.key === "Enter") && _addTagChip()} // on Enter
      onBlur={_addTagChip}  // on Tab
      onChange={e => setTagTxt(e.target.value)} // on Typing
    />}


    {/* Image Tags Display */}
    {chips.length > 0 &&
    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexWrap: 'wrap', gap: '4px', overflowX: 'auto' }}>
      {chips.map((c,i) => 
        <Chip 
          label={_.trim(c)} 
          size="small" variant="contained" 
          key={`${i}`} 
          onDelete={() => _onTagDelete(i)} 
          sx={{ " span": { pl: 1.5 } }}
        />
      )}
    </Box>}


    {/* Upload button */}
    <Stack spacing={1} direction="row" justifyContent="space-between" sx={{ pt: 1 }}>
      <Button aria-label="cancel" size="small" variant='outlined' color="warning"
        onClick={releaseLogo}
      >
        {t("global.cancel")}
      </Button>

      <LoadingButton aria-label="submit" size="small" variant="contained" color="success" 
          startIcon={<SaveIcon />}
          loading={loadingFlag}
          disabled={!formValidate || loadingFlag}
          onClick={performSubmit}
          sx={{ ":disabled": { cursor: 'not-allowed !important'} }}
      >
        {t("global.upload")}
      </LoadingButton>

    </Stack>


    {/* Error Message */}
    {submitErrorMsg.length > 0 && <Alert variant="filled" severity="error">{submitErrorMsg}</Alert>}

  </Stack>
}

export default LogoCardInputArea;