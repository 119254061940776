// mui
import Box from '@mui/material/Box';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from '@mui/material/ListItemText';
// Library
import { formatEventDateRange } from "../common/utils";


const EventMeta = ({ eventMeta, dimmer }) => {

  let secondaryText = formatEventDateRange(eventMeta.startBaseDate.utc, eventMeta.endBaseDate.utc);
  secondaryText += ` @${eventMeta.location.country}`;

  const primaryText = (eventMeta.description === eventMeta.title) 
                        ? eventMeta.title
                        : `${eventMeta.title} | ${eventMeta.description}`;


  return (
    <>
      <ListItemAvatar>
        <Box 
          component="img"
          alt="" 
          src={ eventMeta.location.flag }
          sx={{ 
            width: '50px',
            mr: 2,
            boxShadow: 3, 
            // filter: dimmer ? "grayscale(100%)" : "none" 
          }} 
        />
      </ListItemAvatar>

      <ListItemText
        primary={primaryText}
        secondary={secondaryText}
        sx={{
          color: dimmer ? '#666' : '#000',
          textTransform: "capitalize",
        }}
      />
    </>
  );

}
export default EventMeta;