import { useEffect, useMemo, useState } from 'react';
import { asyncFileToBase64 } from '../common/fileUtilities';
import LogoCardInputArea from './Logo/logoCardInputArea';
// mui
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
// icon & style
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { red, grey, green, yellow, orange } from '@mui/material/colors';
// Library
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';


const dropzoneStyle = {
  display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
  padding: '20px 20px',
  borderColor: grey[600],
  borderWidth: '5px',
  borderStyle: 'dashed',
  borderRadius: 12,
  backgroundColor: grey[200],
  outline: 'none',
  transition: 'border 250 ease-in-out',
};
const focusedStyle = { borderColor: orange[400], backgroundColor: yellow[100] }
const acceptStyle = { borderColor: green[800], backgroundColor: green[200] }
const rejectStyle = { borderColor: red[300], backgroundColor: red[100] }


const PendingUploadCard = ({ imgObj, releaseItem, collapseSpeed }) => {

  const [fadeOutFlag, setFadeOutFlag] = useState(false);

  return (
    <ImageListItem sx={{ boxShadow: 1, p: 1, borderRadius: '3px' }}>
      <Collapse in={!fadeOutFlag} timeout={collapseSpeed}
        addEndListener={() => 
          setTimeout(() => { releaseItem(imgObj); }
        , collapseSpeed)}
      >
        <Box
          component="img"
          src={"data:image/" + ((imgObj.mimeType === 1) ? "png" : "jpeg") + ";base64," + imgObj.content}
          sx={{
            objectFit: "contain",
            // height: '120px',
            // width: '180px',
            // filter: "grayscale(100%)",
            maxWidth: 'calc(100% - 8px)',
          }}
        />

        <LogoCardInputArea
          imgObj={imgObj}
          releaseLogo={() => setFadeOutFlag(true)} />

      </Collapse>
    </ImageListItem>

  )
}


const FileDragZone = ({ addPendingUploadFile, ...props }) => {
  const { t } = useTranslation();
  
  // Dropzone...
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: { 'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png'] },
    onDrop: /* useCallback( */acceptedFiles => {
      // setUploadMode(true);
      asyncFileToBase64(acceptedFiles)
        .then(
          r => {
            addPendingUploadFile(r)
            // setUploadingFiles([...uploadingFiles, ...r]);
            // setTimeout(() => setCollapseOn(true), 500);
          },
          e => {
            // setCollapseOn(false);
            // setUploadMode(false);
            process.env.NODE_ENV === "development" && console.error(e);
          }
        );
    }/* ) */,  // useCallback
  });
  
  const style = useMemo(() => ({
    ...dropzoneStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused, isDragAccept, isDragReject
  ]);


  return <Box component="section" sx={{ ...props.sx ?? {} }}>
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <UploadFileOutlinedIcon
        sx={{
          fontSize: '3rem',
          color: grey[600],
          mr: 1,
          ...(isDragAccept ? { color: green[800] } : {}),
          ...(isDragReject ? { color: red[800] } : {}),
        }}
      />
      <Typography variant="h5"
        sx={{
          fontWeight: 700,
          color: grey[600],
          ...(isDragAccept ? { color: green[800] } : {}),
          ...(isDragReject ? { color: red[800] } : {}),
        }}
      >
        {t('imageLib.drag.zoneDesc')}
      </Typography>
    </div>
  </Box>
}


const UploadDropzone = ({ setUploadMode, collapseSpeed, ...props }) => {

  const [pendingUploadFile, setPendingUploadFile] = useState([]);
  const collapseOn = useMemo(() => pendingUploadFile.length > 0, [pendingUploadFile.length]);
  let releasedIDs = [];  // Avoid useState delay update causing missing item(s)


  const releaseItem = (imgObj) => {
    // guard
    if (_.isNil(imgObj)) return;

    releasedIDs.push(imgObj.tempID);
    const clearup = _.reject(pendingUploadFile, f => releasedIDs.includes(f.tempID))

    setPendingUploadFile( clearup );
    if (clearup.length === 0) {
      releasedIDs = [];
    }
  }

  const addPendingF = (pendingFiles) => setPendingUploadFile([...pendingUploadFile, ...pendingFiles]);

  
  // Info parent component
  useEffect(() => setUploadMode(pendingUploadFile.length > 0), [pendingUploadFile.length, setUploadMode]);


  return (<>
    <FileDragZone sx={props.sx} addPendingUploadFile={addPendingF} />

    <Collapse in={collapseOn} easing="ease-in-out" timeout={collapseSpeed}>
      <Box component="section" sx={{ width: '100%' }}>
        {/* <PendingUploadContainer files={uploadingFiles} collapseSpeed={collapseSpeed} releaseItem={releaseItem} /> */}
        <ImageList cols={5} gap={12}
          sx={{ marginTop: 0.5, marginBottom: 2, pt: 1.5, pb: '50px', px: '2px' /* , borderBottom: `solid 1px ${grey[400]}` */ }}
        >

          {pendingUploadFile.map((imgObj) =>
            (releasedIDs.includes(imgObj.tempID)) ? null : (
            <PendingUploadCard
              key={imgObj.tempID}
              imgObj={imgObj}
              releaseItem={releaseItem}
              collapseSpeed={collapseSpeed} />
            )
          )}

        </ImageList>
      </Box>
    </Collapse>

  </>)
}
export default UploadDropzone;