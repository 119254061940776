import { Footer, Header } from "../site-template/SiteFrame";
// mui
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
// library
import { useTranslation } from "react-i18next";



const NotFound = (props) => {

  const { t } = useTranslation();

  return <>
    <Header preparing />
    <Container component="main">
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Box sx={{ width: "200px", height: "200px", mt: 3 }} display="flex" justifyContent="center" alignItems="center">
          <WarningAmberRoundedIcon color="warning" sx={{ fontSize: "160px" }} />
        </Box>
        <Typography variant="h4" sx={{ mt: 2 }}>
          {t("commonError.page404")}
        </Typography>
      </Stack>
    </Container>
    <Footer />
  </>
}
export default NotFound;