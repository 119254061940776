import api, { API_URL } from './api';
import APPLICATION_CONSTANT from '../constant';
import { getStoredValue, removeStorage, setStoreValue } from '../common/utils';
import _ from 'lodash';
import * as Sentry from '@sentry/react';
import { getAccountID, getHardwareScreensConfig } from './application.service';
import { LogoGroupingOptions, LogoSortingOptions } from '../components/Logo/logoLibrary';
import { getImageSize } from 'react-image-size';


export const IMAGE_ATTRIBUTE_TYPE = Object.freeze({
  NONE: 0,
  SIZE: 1,
});


const downloadAllImageList = async (accountID = null) => {

  const _accountID = accountID ?? getAccountID();
  if (_accountID === null || _accountID === "") return Promise.reject("Account ID is missing");

  const reply = await api.get(API_URL.GET_UPLOAD_MULTIMEDIA + _accountID);

  if (reply.status !== 200) return Promise.reject(reply);

  const { succeeded, errors, data } = reply.data;

  if (succeeded) {

    const simplified = data.multimedia.filter(d => d.enabled).map(d => {

      // Core meta
      let sData = { 
        "id"            : d.id,
        "title"         : d.title,
        "url"           : d.url,
        "mime"          : d.mime.title,
        "width"         : d.dimensions.width,
        "height"        : d.dimensions.height,
        "dimensionsUnit": d.dimensions.unit.id
      }

      // Tags
      const tags = _.filter(d.attributes, o => ('type' in o && 'value' in o && o.type.id === 12));
      if (tags.length > 0) {
        sData["tags"]    = JSON.parse(tags[0].value);
        sData["tags_id"] = tags[0].id;
      }

      return sData;
    });

    // console.log("parsed img list", simplified);

    return Promise.resolve(simplified);

  } else {
    const codes = errors.map(e => e.code);
    return Promise.reject(codes);
  }

}


export const getAllImages = async (accountID, returnCached = false) => {

  return downloadAllImageList(accountID)
    .then(
      r => {
        setImageList(r);
        // console.log("downloaded", r);
        return Promise.resolve(r);
      },
      e => {
        // console.error("Try return from image cache", e, storedImgList);
        // const storedImgList = getImageList();
        // return storedImgList.length > 0 ? Promise.resolve(storedImgList) : Promise.reject(e);
        process.env.NODE_ENV === "development" 
          && console.error('getAllImages', e);

        return returnCached
          ? Promise.resolve( getImageList() )
          : Promise.resolve([]);

      }
    )

}


export const getLogosByConditions = async (accountID, conditions = {}) => {

  let logos = await getAllImages(accountID)
    .catch(err => { return Promise.reject(err) });

  // let logos = rawLogoList;  // Clone

  
  if ('filters' in conditions) {
    
    const { rotatableDimension, ...f } = conditions.filters;

    if ( _.size(rotatableDimension) ) {
      logos = _.filter(logos, l => (
        (l.width === rotatableDimension.width && l.height === rotatableDimension.height)
        ||
        (l.height === rotatableDimension.width && l.width === rotatableDimension.height)
      ))
    }

    if ( _.size(f) ) {
      logos = _.filter(logos, f);
    }
  }

  if ("keyword" in conditions) {
    logos = _.filter(logos, (l) =>
      l.title.toLowerCase().includes(conditions.keyword.toLowerCase())
    );
  }

  if ('sort' in conditions) {

    switch (conditions.sort) {
      case 0: // skip NONE
        break;
      case LogoSortingOptions.TITLE_ASC:
        logos = _.orderBy(logos, ['title'], ['asc']);
        break;
      case LogoSortingOptions.TITLE_DESC:
        logos = _.orderBy(logos, ['title'], ['desc']);
        break;
      case LogoSortingOptions.CREATE_DATE_ASC:
        logos = _.orderBy(logos, [''], ['asc']);
        break;
      case LogoSortingOptions.CREATE_DATE_DESC:
        logos = _.orderBy(logos, [''], ['desc']);
        break;
      default:
        console.error("Unknown sorting option", conditions.sort);
        break;
    }

  }

  // leave it to be the LAST step
  if ('group' in conditions) {

    switch (conditions.group) {
      case 0: // skip NONE
        break;
      case LogoGroupingOptions.SIZE:
        logos = _.groupBy(logos, (i) => `${i.width}x${i.height}`);
        break;
      default:
        console.error("Unknown grouping option", conditions.group);
        break;
    }
  }

  return Promise.resolve(logos);
}

/**
 * Group all images by attributes
 * @param {string} accountID - Context of Account ID
 * @param {IMAGE_ATTRIBUTE_TYPE} groupBy - Group by size, date, tags...etc. Default by size (width & height)
 * 
 * @returns {object<AttributesValue: Array<ImageObject>>} Object with multiple array of images and key by attribute. For example:
 * ```{
 *  "120x80" : [{id: ...}, {id: ...}, ... ],
 *  "200x120": [{id: ...}, {id: ...}, ... ],
 *  "150x75" : [{id: ...}, {id: ...}, ... ],
 * }```
 */
/*
export const getImagesByGroup = async (accountID, groupBy = IMAGE_ATTRIBUTE_TYPE.SIZE) => {

  const imgList = await getAllImages(accountID)
    .catch(err => { return Promise.reject(err) });

  // process.env.NODE_ENV === "development"
  //   && console.log("getImagesByGroup", imgList);


  if (groupBy === IMAGE_ATTRIBUTE_TYPE.SIZE) {
    return Promise.resolve( _.groupBy(imgList, (i) => `${i.width}x${i.height}`) );
  }
}
*/

/**
 * Extract image object only if the size of image exact match the requested size.
 * @param {string} accountID - Account ID from Context
 * @param {object<width: int, height: int>} size - Target image size, width & height is optional. If all is missing, all images will be return
 * 
 * @returns {Array<imageObject>}
 */
/* 
export const getImagesBySize = async (accountID, size) => {

  const imgList = await getAllImages(accountID, false)
    .catch(err => { return Promise.reject(err) });

  let filterImgs = imgList.slice(0);  // Clone
 
  filterImgs = ("width" in size)  ? filterImgs.filter(i => i.width === size.width) : filterImgs;
  filterImgs = ("height" in size) ? filterImgs = filterImgs.filter(i => i.height === size.height) : filterImgs;

  // process.env.NODE_ENV === "development"
  //   && console.log("getImagesBySize", size, filterImgs, imgList);

  return Promise.resolve(filterImgs);
}
*/

// - Shorthand ------------------------
export const setImageList = (imgList) => setStoreValue(
  APPLICATION_CONSTANT.MEDIA_IMAGE_Parsed_DATA,
  JSON.stringify(imgList),
  false
);
export const getImageList = (defVal = []) => getStoredValue(APPLICATION_CONSTANT.MEDIA_IMAGE_Parsed_DATA, defVal, false, false);
export const resetImageList = () => removeStorage(APPLICATION_CONSTANT.MEDIA_IMAGE_Parsed_DATA);


export const postImage = async (mmData) => {

  // Guard 
  if (mmData === null || _.isEqual(mmData, {})) return Promise.reject("Image object is missing");


  return api.post(API_URL.POST_NEW_IMAGE, mmData)
    .then(
      r => {
        process.env.NODE_ENV === "development" && console.log("media.service postImage", r.data);

        const { succeeded, data, errors } = r.data;
        if (succeeded) {
          return Promise.resolve(data);
        }

        return Promise.reject(errors);
      },
      e => {
        console.error("media.service then error", e);
        return Promise.reject(e);
      }
    )

}


export const delImage = async (imgId, accountId) => {

  if (imgId === null || imgId === "") return Promise.reject("Missing Image ID");

  if (accountId === null) return Promise.reject("Missing Account ID");

  const dataPackage = {
    data: {
      accountId,
      "multimedia": [{ "id": imgId }]
    }
  };

  // console.log('delImage', dataPackage);

  return api.delete(API_URL.DELETE_IMAGE, dataPackage);

}

/**
 * Check image portrait/landscape orientation for screen.
 * 
 * @param {string} logoID - Logo ID from Multimedia Service
 * @param {string} assetID - AssetID to be match with the target image
 * 
 * @returns Suggested image rotation in 'IMAGE_ROTATION_TYPES' value.
 */
export const getSuggestedImageOrientationRotationForScreen = async (logoID, assetID, imgUrl = null) => {

  // Extract image data
  const imgListRAW = getImageList();
  const imgObjTobeCheck = _.head(_.filter(imgListRAW, { id: logoID }));

  // get screen detail
  const screenSpec = _.head(getHardwareScreensConfig(assetID));

  /*
    if (process.env.NODE_ENV === "development") {
      console.group('Image Orientation')
      console.log('Screen', screenSpec.width, screenSpec.height, screenSpec.rotation, screenSpec.id)
      console.log('Image', imgObjTobeCheck.width, imgObjTobeCheck.height, imgObjTobeCheck.id)
      console.groupEnd();
    }
   */

  let imgW = 0, imgH = 0;

  if (_.hasIn(imgObjTobeCheck, 'width') && _.hasIn(imgObjTobeCheck, 'height')) {
    // Method one - If we found the image dimension from Logo Library
    imgW = imgObjTobeCheck.width;
    imgH = imgObjTobeCheck.height;
  } else if (imgUrl) {
    const imgDi = await getImageSize(imgUrl);
    imgW = imgDi.width;
    imgH = imgDi.height;
  }

  if (imgW === 0 || imgH === 0) {
    const e = new Error(`Fail detect image dimension. Logo ID: ${logoID}; Logo URL: ${imgUrl}`);
    Sentry.captureException(e);
    throw e;
  } else if (imgW === screenSpec.height && imgH === screenSpec.width) {
    return screenSpec.orientation;
  }

  // All other case
  return 0;
}