import _ from 'lodash';
import { FixedRange_hasOverlapSlot, FixedRange_allValueValid } from './ScenarioHelper'; 
import { SCREEN_TRIGGER_TYPE, SCHEDULE_STATUS } from '../constant';


/**
 * Fixed Scenario Validator
 * @param {Array<multimedia>} list - Array of `Multimedia`
 * @param {condition: condition} condition - Condition Object
 * @returns SCHEDULE_STATUS {.BLANK, .INCOMPLETE, .COMPLETE}
 */
export const fixedScenarioValidator = (list, condition) => {

  // Empty List
  if (list.length === 0) return SCHEDULE_STATUS.BLANK;

  // Missing Value or not in range
  const allValueValidFlag = FixedRange_allValueValid(list, condition);
  if ( ! allValueValidFlag ) return SCHEDULE_STATUS.INCOMPLETE;

  // Nothing overlap
  const errOverlap = FixedRange_hasOverlapSlot(list, condition.instances.maximum);
  if (errOverlap) return SCHEDULE_STATUS.INCOMPLETE;

  // Nothing Start > End
  const EndGTStart = _.some(list, i => i.slots[0].start > i.slots[0].end);
  if (EndGTStart) return SCHEDULE_STATUS.INCOMPLETE;

  // All Good
  return SCHEDULE_STATUS.COMPLETE;
}

/**
 * Slideshow Scenario Validator
 * @param {Array<multimedia>} list - Array of `Multimedia`
 * @param {condition: condition} condition - Condition Object
 * @returns SCHEDULE_STATUS {.BLANK, .INCOMPLETE, .COMPLETE}
 */
export const slideshowScenarioValidator = (list, condition) => {
   
  // Empty List
  if (list.length === 0) return SCHEDULE_STATUS.BLANK;

  // Prepare
  const allSecond = list.map(l => l.slots[0].duration/* .interval */);

  const minItem = condition.instances.minimum, 
        maxItem = condition.instances.maximum, 
        minSec  = condition.seconds.minimum, 
        maxSec  = condition.seconds.maximum;

  try {
    const allInRange = _.every(allSecond, s => _.inRange(s, minSec, maxSec + 1));
    const noExtra = allSecond.length <= maxItem && allSecond.length >= minItem;

    return (allInRange && noExtra) ? SCHEDULE_STATUS.COMPLETE : SCHEDULE_STATUS.INCOMPLETE;
  } catch (e) {
    console.error(e);
    return SCHEDULE_STATUS.INCOMPLETE;
  }

}

/**
 * Verify all trigger multimedia
 * @param {Arry<Trigger> | Triggers:{ condition:<condition>, multimedia:Array<multimedia>, schemaType: SCREEN_TRIGGER_TYPE }} screenDataList - Triggers object or Array of Trigger.
 * @returns Array of SCHEDULE_STATUS {.BLANK, .INCOMPLETE, .COMPLETE}
 */
export const masterScenarioStatus = (screenDataList) => {

  const masterList = _.isArray(screenDataList) ? screenDataList : Object.values(screenDataList);

  // Guard
  if ( ! _.every(masterList, m => ("schemaType" in m) && ("condition" in m) && ("multimedia" in m) && _.isArray(m.multimedia) ) )
    throw new Error("[VD-MG] Screnario Data List Error");


  return masterList.map(m => 

    (m.schemaType === SCREEN_TRIGGER_TYPE.SLIDESHOW) 
      ? slideshowScenarioValidator(m.multimedia, m.condition)
      : fixedScenarioValidator(m.multimedia, m.condition)
    
  );
  // console.log(masterList.map(m => m.id), result, result.every(r => r));

}

/**
 * Summary a single Schedule Status after verified all sub-trigger scenario. 
 * 1. All scenario can be `BLANK`.
 * 2. Any single scenario containing Error, the whole Schedule will be consider as `INCOMPLETED`
 * 
 * p.s. Validation logic based on `masterScenarioStatus`
 * 
 * @param {*} screenDataList 
 * @returns SCHEDULE_STATUS {.BLANK, .INCOMPLETE, .COMPLETE}
 */
export const masterScheduleValidation = (screenDataList) => {

  const result = _.compact( masterScenarioStatus(screenDataList) );

  //return result.every(r => r !== SCHEDULE_STATUS.INCOMPLETE);

  if ( result.includes( SCHEDULE_STATUS.INCOMPLETE ) ) return SCHEDULE_STATUS.INCOMPLETE;

  else if ( result.includes(SCHEDULE_STATUS.COMPLETE) ) return SCHEDULE_STATUS.COMPLETE;

  else return SCHEDULE_STATUS.BLANK;

}