import { Fragment, useEffect, useState } from 'react';
import DialogComponent from '../../../../components/DialogComponent';
import { getHardwareScreensConfig } from '../../../../services/application.service';
// mui
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
// Library
import { useTranslation } from "react-i18next";
import _ from 'lodash';


const ScenarioSelection = (props) => {

  // State & variables
  const [scenarioMaster, setScenarioMaster] = useState([]);
  const [selection, setSelection]           = useState([]);
  const { t/* , i18n */ } = useTranslation();  


  const transformedSelection = () => {
    let sel = _.cloneDeep(selection);

    sel = sel.map(s => ({
      ...s,
      multimedia: [],
    }));

    return _.keyBy(sel, 'id');
  }

  // Actions
  const okAction = () => { 
    props.dialogOption.okAction 
      && props.dialogOption.okAction(transformedSelection()); 
    
    props.dialogOption.closeAction 
      && props.dialogOption.closeAction() 
  }
  
  const containSelection = (s) => (selection.find(o => o.id === s.id) !== undefined);
  const alreadyExist     = (s) => _.includes(props.currentSelection, s.id);

  const toggleSelection = (s) => {

    setSelection( containSelection(s) 
                    ? selection.filter(o => o.id !== s.id) // Del
                    : [...selection, s] // Add
                );

  }


  useEffect(() => {

    // Get Available Trigger for current screen
    const targetScreen = getHardwareScreensConfig(props.assetId);
    setScenarioMaster(targetScreen[0]?.triggerGroup ?? []);
    // console.log("ScenarioSelect", targetScreen);

  }, [props.assetId])

  useEffect(() => {
    // Cleanup before go
    !props.open && setSelection([]);
    // props.open && console.log("Scenario Select Open", selection, props.currentSelection);
  }, [props.open]);


  return (
    <DialogComponent 
      open={props.open}
      dialogOption={{...props.dialogOption, ...{okAction: okAction} }}
    >
      <Typography variant='body2' sx={{ mb: 1.5 }}>
        {t('scenario.selectionDialogHelpText')}
      </Typography>

      <Stack spacing={2} sx={{}}>
        <Grid container direction='row' columnSpacing={2} rowSpacing={{xs: 1, sm: 1}} alignItems="center" justifyContent="flex-start">
        {scenarioMaster.map(s => 
          <Fragment key={s.id}>
            <Grid item xs={5} sm={4} /* key={`btn-${s.id}`} */>
              <Button
                fullWidth
                color={containSelection(s) ? 'success' : 'primary'}
                variant={containSelection(s) || alreadyExist(s) ? 'contained' : 'outlined'}
                size="large"
                disabled={alreadyExist(s)}
                onClick={() => toggleSelection(s)}
              >
                {t(`scenario.${s.element}-title`)}
              </Button>
            </Grid>
            <Grid item xs={7} sm={8} /* key={`txt-${s.id}`} */>
              <Typography>
                {t(`scenario.${s.element}-desc`)}
              </Typography>
            </Grid>
          </Fragment>
        )}
        </Grid>
      </Stack>
    </DialogComponent>
  );
}
export default ScenarioSelection;