import { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ScheduleContext } from '../../context/ScheduleContext';
import DialogComponent from '../DialogComponent';
import { getScreenDefinitionByField } from '../../services/schedule.service';
import LogoLibrary from './logoLibrary';


const ImageSelectionDialog = (props) => {

  const { dialogOption } = props;

  const { scheduleState: { assetIDCtx }} = useContext(ScheduleContext);
  const { t } = useTranslation();
  const collapseSpeed = 600;

  const [isUploading, setIsUploading] = useState(false);
  const [selectedImg, setSelectedImg] = useState([]);

  const currentAssetVSize             = useMemo(() => {
    if (props.shouldMatchAssetSize) {
      const { dimensions: { total } } = getScreenDefinitionByField("assetId", assetIDCtx);
      return total;
    } 
    return {};
  }, [assetIDCtx, props.shouldMatchAssetSize]);

  // Dialog
  const closeAction = () => { setSelectedImg([]); props.closeAction && props.closeAction(); }
  const okAction = () => { props.okAction && props.okAction(selectedImg); closeAction(); }


  return (
    <DialogComponent
      open={props.open}
      dialogOption={{
        maxWidth: "lg",
        title: t('imageLib.dialogTitle'),
        okAction: okAction,
        closeAction: closeAction,
        disableActionBar: isUploading,
        hideCloseBtn: isUploading
      }}
      disableEscapeKeyDown
      onClose={(_, reason) => {
        (reason !== "backdropClick") && closeAction();
      }}
    >
      <LogoLibrary  
        selectable={true}
        selectionQuota={dialogOption.selectionQuota}
        selectedList={selectedImg}
        setSelectedList={setSelectedImg}

        uploadable={true}
        listenToUploadMode={setIsUploading}

        delable={true}
        //editable={false}

        targetLogoSizeFilter={{
          width: currentAssetVSize.width,
          height: currentAssetVSize.height,
          unitId: 3,
        }}

        collapseSpeed={collapseSpeed}
        maxItemsPerRow={5}
      />
    </DialogComponent>
  );

}

// Prop Types -------------------------
ImageSelectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  
  shouldMatchAssetSize: PropTypes.bool,   // Image size (should) match to selected screen
  // Action
  dialogOption: PropTypes.object,
  closeAction : PropTypes.func,
  okAction    : PropTypes.func,
};
ImageSelectionDialog.defaultProps = {
  shouldMatchAssetSize: true
};

export default ImageSelectionDialog;