import _ from 'lodash';

// const fileToBase64 = file => {

//   const reader = new FileReader();

//   reader.onloadend = () => {
//     const base64String = reader.result.replace('data:', '')
//                                       .replace(/^.+,/, '');

//   }

// }

// export const base64String = b64 => {
//   return b64.replace('data:', '')
//             .replace(/^.+,/, '');
// }

export const loadImage = (imgSrc, imgEle) => {
  return new Promise((resolve, reject) => {
    imgEle.onload = () => resolve(imgEle);
    imgEle.onerror = reject;

    // Load it
    imgEle.src = imgSrc;
  });
}



export const fileToImgObject = async (file, toString = false) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {

      const imgResult = reader.result;

      // Dimension
      let width = 0, height = 0;
      let img = new Image();
      // img.src = imgResult;

      loadImage(imgResult, img)
        .then(
          img => {
            
            width = img.naturalWidth;
            height = img.naturalHeight;
      
            // process.env.NODE_ENV === "development" && console.log("Dimension", { width, height }, imgResult);
      
            // Image Type
            const fh  = imgResult.split(';');
            const ft = fh[0].split('/');
            const b64 = _.startsWith(fh[1], "base64,") ? _.replace(fh[1], "base64,", "") : fh[1];
      
            resolve({ 
              content: b64,
              title: file.name.replace(".png","").replace(".jpg","").replace("jpeg","").replaceAll('-', ' ').replaceAll('_', ' ').replaceAll('.', ' ').replaceAll('  ', ' '), 
              tempID: _.uniqueId("tmp_"),
              mimeType: ((ft[1] === "png") ? 1 : 2),
              dimensions: { 
                width, 
                height, 
                unit: 1 
              } 
            });

          },
          e => reject()
        )


    }
    reader.onerror = error => reject(error);
  });
}

// async function syncFileToBase64(file, toString = false) {
  
//   const b64 = (await fileToBase64(file, toString));
//   return b64;
// }

export const asyncFileToBase64 = async (file, toString = false) => {

  const files = _.castArray(file);

  const filesPromises = [];
  files.forEach(file => {
    filesPromises.push( fileToImgObject(file) );
  });

  const fileObjs = await Promise.all(filesPromises);
  const mappedFiles = fileObjs.map(f => f);

  return mappedFiles;
}
