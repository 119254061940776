import { useState } from 'react';
import { forgetPwd } from '../../services/user.service';
// Library
import { useTranslation } from 'react-i18next';
// mui
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import LoginEmailInput from './component/LoginEmailInput';


const ForgetPwdDialog = ({ open, emailAddrTxt, onClose }) => {

  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [loadingFlag, setLoadingFlag]     = useState(false);
  const [resetResult, setResetResult]     = useState("");
  const [inputEmailTxt, setInputEmailTxt] = useState(emailAddrTxt);
  const { t } = useTranslation();

  const performClose = () => {
    // Reset
    setResetResult("");
    setLoadingFlag(false); 
    setReadyToSubmit(false);
    // Bye
    onClose && onClose();
  }

  const performSubmit = () => {
    setLoadingFlag(true);

    forgetPwd(inputEmailTxt)
      .then(
        r => {
          setLoadingFlag(false);
          setResetResult("success");
        },
        e => {
          setLoadingFlag(false);
          setResetResult("error");
        }
      )
  }

  const handleEmailStatus = ([validFlag, inputEmailTxt]) => {
    setReadyToSubmit(validFlag);
    setInputEmailTxt((validFlag) ? inputEmailTxt : "");
  }


  return (
    <Dialog
      open={open}
      onClose={performClose}

      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle>{t('loginPage.ForgetPwdTitle')}</DialogTitle>
      
      <DialogContent>
        <Stack spacing={3}>
          <LoginEmailInput defaultValTxt={emailAddrTxt} onChangeValidation={handleEmailStatus} />

          {resetResult && <Alert severity={resetResult}>{t(`loginPage.feedback.${resetResult}`)}</Alert>}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Stack flexGrow={1} direction={(resetResult === "") ? "row" : "row-reverse"} justifyContent="space-between" alignItems="center">

          <Button onClick={performClose}>
            {(resetResult === "") ? t("global.cancel") : t("global.close")}
          </Button>
          
          {(resetResult === "") && <LoadingButton 
            loading={loadingFlag}
            disabled={!readyToSubmit || loadingFlag} 
            onClick={performSubmit}
            variant="contained"
          >
            {t("loginPage.forgetPwdBtn")}
          </LoadingButton>}

        </Stack>
      </DialogActions>
    </Dialog>
  );

};
export default ForgetPwdDialog;