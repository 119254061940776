import PropTypes from 'prop-types';


/**
 * Logo (a.k.a. image for F1 Screen) data object definition
 * Single logo image prop type
 */
export const LogoPropTypes = PropTypes.shape({
  id            : PropTypes.string,
  url           : PropTypes.string,
  title         : PropTypes.string,
  dimensionsUnit: PropTypes.number,
  width         : PropTypes.number,
  height        : PropTypes.number,
  mime          : PropTypes.string,   // e.g. 'image/png'
  attributes    : PropTypes.array,
});
export const LogoPropTypesDefault = {};