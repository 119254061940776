// Components
import { Header, Footer } from "../site-template/SiteFrame";
import { SomethingMissingIcon } from "./SomethingWrong";
// library
import { useTranslation } from "react-i18next";
// mui
import { Stack, Typography } from "@mui/material";


const MobileDeviceNotAvailableYet = () => { 
  const { t } = useTranslation();
  
  return <> 
    <Header plainMode={true} />

    <Stack direction="column" alignItems="center" justifyContent="flex-start" spacing={2} sx={{ py: 2, height: "calc(100vh - 50px - 41px)", overflowY: 'auto' }}>
      <SomethingMissingIcon w="140px" h="140px" />
      <Typography variant="h5" sx={{ fontWeight: 600, textAlign: "center" }}>
        {t("commonError.mobileNotReadyTitle")}
      </Typography>
      <Typography variant="body1" sx={{ width: "80%", textAlign: "center" }}>
        {t("commonError.mobileNotReadyDesc")}
      </Typography>
      <Typography variant="body2" sx={{ width: "80%", textAlign: "center", fontSize: "0.875rem" }}>
        {t("commonError.mobileNotReadySubDesc")}
      </Typography>
    </Stack>

    <Footer />
  </>
};
export default MobileDeviceNotAvailableYet;