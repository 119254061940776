import coreTheme from "../masterTheme";
import { createTheme } from "@mui/material/styles";
import deepmerge from "deepmerge";

const branding = {
  highlight   : "#cdd9fe",
  light       : "#517cfd",
  main        : "#0744fd",
  dark        : "#012db4",
  contrastText: "#fff",
};

const alpineTheme = createTheme( deepmerge(coreTheme, {
  palette: { branding },

  components: {
    
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderBottomColor: branding.main,
        }
      }
    },  // MuiAppBar

    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: branding.main,
            color: branding.contrastText,
          },
          "&:hover, &.Mui-selected:hover": {
            backgroundColor: branding.light,
            color: branding.contrastText,
          }
        }
      }
    },  // MuiListItemButton

    MuiListItem: {
      styleOverrides: {
        root: {
          "&:hover:not(.isPast):not(.isUnselectable)": {
            backgroundColor: branding.highlight,
          },
          "&.isSelected": {
            backgroundColor: branding.highlight,
          }
        }
      }
    },  // MuiListItem
  
  },  // components 

}));
export default alpineTheme;