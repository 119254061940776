/**
 * Application data service. This service is response for:
 * 1. Request necessary data from API
 * 2. Store application related
 */

import api, { API_URL } from './api';
import { setStoreValue, removeStorage, getStoredValue } from '../common/utils';
import APPLICATION_CONSTANT, { SCHEDULE_STATUS, SCREEN_TRIGGER_TYPE } from '../constant';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getAllImages, getImageList } from './media.service';


// - Core Data ----------------------------------
export const fetchApplication = async (accountID = null) => {

  if (accountID === null) return Promise.reject("Missing Account ID");

  resetStorageForPreload();

  return api.get(API_URL.GET_APPLICATION + accountID)
    .then(
      r => {
        const { succeeded, data, errors } = r.data;

        if (succeeded) {

          // Simplify data->application->resources->assets
          const res = data.application.resources.map(r => r.assets.map(a => a.screenId));
          data.application.resources.forEach((r,i) => r.assets = res[i]);

          setStoreValue(APPLICATION_CONSTANT.APPLICATION_ID, data.application.id.toString(), false);
          setStoreValue(APPLICATION_CONSTANT.APPLICATION_RAW_DATA, JSON.stringify(data.application), false);
          return Promise.resolve(data.application);
        } 

        return Promise.reject(errors.map(e => e.code));
      },
      e => Promise.reject(e)
    );

}

export const fetchCoreApplicationData = async (accountID = null) => {

  return fetchApplication(accountID)
    .then(
      r => {
        const { id, type } = r;
        setAppType(type.id);

        return fetchEvent(id)
          .then(
            r => {

              // In case no logo data has been downloaded
              if (getImageList().length === 0) {
                getAllImages()
                  .then(
                    r => {},
                    e => {}
                  );
              }

              return Promise.resolve({ appID: id });
            },
            e => Promise.reject(e)
          )
      },
      e => Promise.reject(e)
    )

}

export const resetStorageForPreload = () => {
  removeStorage(APPLICATION_CONSTANT.EVENT_RAW_DATA);
  removeStorage(APPLICATION_CONSTANT.APPLICATION_RAW_DATA);
}

// Shorthand
export const getApplicationID = () => getStoredValue(APPLICATION_CONSTANT.APPLICATION_ID, null, false, false);
export const getApplicationData = () => getStoredValue(APPLICATION_CONSTANT.APPLICATION_RAW_DATA, {}, false, false);

// - Event Related ------------------------------
export const fetchEvent = async (appID = null) => {

  if (appID === null) return Promise.reject("Missing Application ID");

  return api.get(API_URL.GET_EVENT + appID)
    .then(
      r => {
        const { succeeded, data, errors } = r.data;

        if (succeeded) {
          const sortedData = _.sortBy(data.events, 'startBaseDate.utc');

          setStoreValue(APPLICATION_CONSTANT.EVENT_RAW_DATA, JSON.stringify(sortedData), false);

          return Promise.resolve(data.events);
        }

        return Promise.reject(errors.map(e => e.code));
      },
      e => Promise.reject(e)
    );

}


export const getFullEventList = async (forceUpdate = false, runCounter = 0) => {
  

  // console.log("getFullEventList", runCounter, eventList?.length);

  if (forceUpdate || runCounter === 0) {

    // Guard 
    const appID = getApplicationID();
    if (appID === null) Promise.reject("Application ID is missing");

    return fetchEvent(appID)
      .then(
        r => {
          const newList = getStoredValue(APPLICATION_CONSTANT.EVENT_RAW_DATA, null, false, false);
          return Promise.resolve( newList );
        },
        e => Promise.resolve([])
      )

  } else {

    const eventList = getStoredValue(APPLICATION_CONSTANT.EVENT_RAW_DATA, null, false, false);
    return Promise.resolve( _.castArray(eventList) );
    
  }
  
}

// - Shorthand ------------------------
export const getEventData = () => getStoredValue(APPLICATION_CONSTANT.EVENT_RAW_DATA, {}, false, false);
const getEventByStatus = async (statusFlag) => _.filter(
    await getFullEventList(),
    { status: { id: statusFlag } }
);
export const getEventWithCompletedSchedule = async () => await getEventByStatus(SCHEDULE_STATUS.COMPLETE);
export const getUpcomingEvent = async () => {
  let today = moment.utc();
  let list = await getFullEventList();
  return _.filter(list, e => today.isSameOrBefore(e.endBaseDate.utc))
}

export const getEventFromCacheByID = (eventID) => {

  const eList = getStoredValue(APPLICATION_CONSTANT.EVENT_RAW_DATA, null, false, false);

  const eventObj = _.filter(eList, {id: eventID});

  return eventObj.length > 0 ? eventObj[0] : null;
}

export const isPastEventByID = (eventID) => {
  if (eventID == null) {
    return true
  }

  const eventData = getEventFromCacheByID(eventID);

  if (eventData == null) {
    return true
  }

  const today = moment.utc();
  return today.isAfter(eventData.endBaseDate.utc);
}

// - Driver Related -----------------------------
export const getResourcesList = () => {
  const appRawData = getApplicationData();

  if ("resources" in appRawData) {
    return _.sortBy(appRawData.resources, "preference")
  }

  return [];
}


// - Screen Related, Hardware or Preview --------
export const getResourceScreenList = (resourceId) => {
  // Guard
  if (_.size(resourceId) === 0) return [];

  // Get it
  let ress = getApplicationData()?.resources;

  if (ress === null) return [];

  let s = ress.filter(r => r.id === resourceId);
  if (s.length === 1) {
    return s[0].assets;
  } else if (s.length > 1) {
    console.error(`More than one resource group with same ID: '${resourceId}'`);
  }
  return [];
}

export const getTriggerGroupByAssetID = (assetId = "*", triggerId) => {
  const a = getHardwareScreensConfig(assetId);
  const b = a[0]?.triggerGroup.filter(d => d.id === triggerId);

  return b.length ? b[0] : {};
}

export const getHardwareScreensConfig = (assetId = "*") => {
  const appRaw = getApplicationData();
  let screenList = appRaw.screens ?? [];

  if (assetId !== "*") {
    screenList = screenList.filter(s => s.assetId === assetId);
  }

  return screenList.map(s => ({
      "id"      : s.id,
      "assetId" : s.assetId,
      "title"   : s.title,
      "width"   : s.dimensions.visual.width,
      "height"  : s.dimensions.visual.height,
      "depth"   : s.depth,
      "orientation": s.orientation,
      "triggerGroup": transformScreenTriggers(s.triggerGroup),
    })
  );
}


const transformScreenTriggers = (triggerGrp) => {

  if (triggerGrp === null) return [];

  let slideshowTrigger = _.filter(triggerGrp, { "type": { "id": SCREEN_TRIGGER_TYPE.SLIDESHOW   }});
  let fixedTrigger     = _.filter(triggerGrp, { "type": { "id": SCREEN_TRIGGER_TYPE.FIXED_RANGE }});

  slideshowTrigger = (slideshowTrigger.length === 0) ? [] : slideshowTrigger[0].triggers?.map(t => ({
    "seq"       : t.sequence,
    "id"        : t.id,
    "title"     : t.title,
    "element"   : t.element,
    "schemaType": SCREEN_TRIGGER_TYPE.SLIDESHOW
  }));
  fixedTrigger = (fixedTrigger.length === 0) ? [] : fixedTrigger[0].triggers?.map(t => ({
    "seq"       : t.sequence,
    "id"        : t.id,
    "title"     : t.title,
    "element"   : t.element,
    "schemaType": SCREEN_TRIGGER_TYPE.FIXED_RANGE
  }));

  let combined = [...slideshowTrigger, ...fixedTrigger];
  combined = _.sortBy(combined, "seq");

  // console.log(combined)

  return combined;

}

export const getScreenConditions = (eventID) => {

  let targetEvent = getEventData();
  targetEvent = _.head( targetEvent.filter(e => e.id === eventID) );

  return (targetEvent) ? _.keyBy(targetEvent.conditions, "triggerId") : {};
}

// export const getScreenTriggerSimpleListByAssetId = (aId) => {
//   const sList = getHardwareScreensConfig();
//   const targetScreen = sList.filter(s => s.assetId === aId)
//   console.log("getScreenTriggerSimpleListByAssetId", targetScreen);

//   return targetScreen[0].triggerGroup;
// }



// Account ----
export const getAccountID    = ()      =>getStoredValue(APPLICATION_CONSTANT.ACCOUNT_ID, null, false, false);
export const setAccountID    = (accID) => setStoreValue(APPLICATION_CONSTANT.ACCOUNT_ID, accID, false);
export const removeAccountID = ()      => removeStorage(APPLICATION_CONSTANT.ACCOUNT_ID, false);
// Event ------
export const getCurrentEventID    = ()        =>getStoredValue(APPLICATION_CONSTANT.SELECTED_EVENT_ID, null, false, false);
export const setCurrentEventID    = (eventID) => setStoreValue(APPLICATION_CONSTANT.SELECTED_EVENT_ID, eventID, false);
export const removeCurrentEventID = ()        => removeStorage(APPLICATION_CONSTANT.SELECTED_EVENT_ID, false);
// Client Business Name
export const getClientBusinessName    = (inShort = false) => {
  let name = getStoredValue(APPLICATION_CONSTANT.CLIENT_BUSINESS_NAME, null, false, false);
  return (inShort) ? _.split(name, ' ', 1) : name;
}
export const setClientBusinessName    = (name) => setStoreValue(APPLICATION_CONSTANT.CLIENT_BUSINESS_NAME, name, false);
export const removeClientBusinessName = ()     => removeStorage(APPLICATION_CONSTANT.CLIENT_BUSINESS_NAME, false);

// Applciation Type
export const getAppType = ()      =>getStoredValue(APPLICATION_CONSTANT.APPLICATION_TYPE, 1, false, false);
export const setAppType = (aType) => setStoreValue(APPLICATION_CONSTANT.APPLICATION_TYPE, aType, false);
export const delAppType = ()      => removeStorage(APPLICATION_CONSTANT.APPLICATION_TYPE, false);